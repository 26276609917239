



















































































































































































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import CatalisadoresPecasGaleria from './CatalisadoresPecasGaleria.vue';
import { ItemPecaCatalogoLoja } from '@/utils/lojas-helper';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';

export default Vue.extend({
  components: { CatalisadoresPecasGaleria },
  name: 'CatalogoPecaDialogInfo',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    catalisadorPeca: {
      type: Object || null,
      default: null,
    },
    hidePedidoLoja: {
      type: Boolean,
      default: false,
    },
    hideCarrinho: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      fotosArquivos: false,
    },
    fotosArquivos: [],
    editQuantidade: false,
    form: {
      quantidade: 1,
    },
  }),
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
    catalisadorPecaId() {
      return this.catalisadorPeca?.id;
    },
    disabledMinus() {
      return this.form.quantidade <= 1;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.form.quantidade = 1;
      } else {
        this.$router.push('#info');
      }
    },
    '$route.hash'(newHash, oldHash) {
      if (oldHash === '#info') {
        this.$emit('input', false);
      }
    },
    catalisadorPecaId() {
      this.buscaFotosArquivos();
    },
  },
  mounted() {
    this.buscaFotosArquivos();
  },
  methods: {
    maximumFractionDigitsCurrency,
    onInput(event: boolean) {
      if (!event) {
        this.$router.back();
      }
      this.$emit('input', event);
    },
    adicionarItemPedidoLojaDebounce: debounce(function () {
      this.adicionarItemPedidoLoja();
    }, 200),
    adicionarItemPedidoLoja() {
      const itemPecaCatalisador: ItemPecaCatalogoLoja = {
        catalisadorPeca: this.catalisadorPeca,
        quantidade: this.form.quantidade,
      };
      this.$emit('adiciona-item-peca', itemPecaCatalisador);
      this.$root.$emit('toastSuccess', this.$t('comum.pecaAdicionadaNoPedido'));
      this.onInput(false);
    },
    adicionarItemCarrinhoDebounce: debounce(function () {
      this.adicionarItemCarrinho();
    }, 200),
    adicionarItemCarrinho() {
      this.$store.dispatch('adicionaItemCarrinho', {
        catalisadorPeca: this.catalisadorPeca,
        quantidade: this.form.quantidade,
      });
      this.$root.$emit(
        'toastSuccess',
        this.$t('comum.pecaAdicionadaNoCarrinho'),
      );
      this.onInput(false);
    },
    async buscaFotosArquivos() {
      this.fotosArquivos = [];
      this.loading.fotosArquivos = true;
      try {
        if (this.catalisadorPecaId) {
          this.fotosArquivos =
            await requester.catalisadoresPecas.buscaFotosArquivos(
              this.catalisadorPecaId,
            );
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fotosArquivos = false;
      }
    },
  },
});
