



























































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import PedidosInfoMeuCarrinho from '@/components/pedidos/PedidosInfoMeuCarrinho.vue';

export default Vue.extend({
  name: 'PedidosConfirmacao',
  components: {
    PedidosInfoMeuCarrinho,
  },
  data: () => ({
    loading: {
      fornecedor: true,
      submitForm: false,
    },
    fornecedor: null,
  }),
  computed: {
    carrinho() {
      return this.$store.getters?.carrinho || [];
    },
  },
  mounted() {
    this.buscaMeuFornecedor();
  },
  methods: {
    async buscaMeuFornecedor() {
      this.loading.fornecedor = true;
      try {
        this.fornecedor = await requester.usuarios.meuFornecedor();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fornecedor = false;
      }
    },
    submitFormDebounce: debounce(async function () {
      this.submitForm();
    }, 200),
    async submitForm() {
      this.loading.submitForm = true;
      try {
        const formData = {
          items: this.carrinho,
          observacoesParceiro:
            this.$refs?.pedidosInfoMeuCarrinho?.form?.observacoes?.trim() ||
            null,
        };
        await requester.pedidos.criaParceiro(formData);
        this.limparCarrinho();
        this.$root.$emit(
          'toastSuccess',
          this.$t('comum.pedidoCriadoComSucesso'),
        );
        this.$router.push({
          name: 'PedidosMeusPedidos',
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
    limparCarrinho() {
      this.$store.dispatch('limparCarrinho');
    },
  },
});
