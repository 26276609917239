import { KatalisTranslationComum } from '../translations.interface';

const messages: KatalisTranslationComum = {
  comum: {
    adicionar: 'Adicionar',
    agencia: 'Agência',
    aguardandoAprovacao: 'Aguardando aprovação',
    alterar: 'Alterar',
    analise: 'Análise',
    aplicar: 'Aplicar',
    aprovado: 'Aprovado',
    aprovadoComAlteracoes: 'Aprovado com alterações',
    aprovarPedido: 'Aprovar pedido',
    aviso: 'Aviso',
    bairro: 'Bairro',
    banco: 'Banco',
    busca: 'Busca',
    buscar: 'Buscar',
    buscarFornecedor: 'Buscar fornecedor',
    buscarMontadora: 'Buscar montadora',
    cadastrarFornecedor: 'Cadastrar fornecedor',
    calculadora: 'Calculadora',
    calculadoraPo: 'Calculadora - Pó',
    calculadoraPoCatalisadores: 'Calculadora - Pó de catalisadores',
    calcular: 'Calcular',
    cancelado: 'Cancelado',
    cancelar: 'Cancelar',
    cancelarPedido: 'Cancelar pedido',
    carrinho: 'Carrinho',
    catalisadoresPecas: 'Catalisadores - Peças',
    catalogo: 'Catálogo',
    catalogoPecas: 'Catálogo - Peças',
    categoria: 'Categoria',
    celular: 'Celular',
    cep: 'CEP',
    cepNaoEncontrado: 'CEP não encontrado',
    certezaAprovarPedido: 'Tem certeza que deseja aprovar este pedido?',
    certezaCancelarPedido: 'Tem certeza que deseja cancelar este pedido?',
    classe: 'Classe',
    cnpj: 'CNPJ',
    codigo: 'Código',
    codigoPeca: 'Código da peça',
    comecoAFimDeTotal: '%{comeco} a %{fim} de %{total}',
    complemento: 'Complemento',
    confirmacaoSenha: 'Confirmação de senha',
    confirmar: 'Confirmar',
    confirmarPedido: 'Confirmar pedido',
    conta: 'Conta',
    cpf: 'CPF',
    criadoEm: 'Criado em',
    criadoPor: 'Criado por',
    dadosBancarios: 'Dados bancários',
    dadosFornecedorNaoEncontrados: 'Dados de fornecedor não encontrados',
    dadosGerais: 'Dados gerais',
    data: 'Data',
    descricaoCatalogo: 'Descrição',
    detalhe: 'Detalhe',
    detalhes: 'Detalhes',
    documento: 'Documento',
    email: 'Email',
    emConstrucao: 'Em construção',
    endereco: 'Endereço',
    enderecoNaoCadastrado: 'Endereço não cadastrado',
    entrar: 'Entrar',
    enviar: 'Enviar',
    estouCiente: 'Estou ciente',
    familia: 'Família',
    fechar: 'Fechar',
    filtro: 'Filtro',
    filtros: 'Filtros',
    fisica: 'Física',
    fornecedor: 'Fornecedor',
    fornecedorAlteradoComSucesso: 'Fornecedor alterado com sucesso',
    fornecedorCadastradoComSucesso: 'Fornecedor cadastrado com sucesso',
    fornecedores: 'Fornecedores',
    foto: 'Foto',
    fotos: 'Fotos',
    grade: 'Grade',
    historico: 'Histórico',
    home: 'Página inicial',
    identificacao: 'Identificação',
    impurezas: 'Impurezas',
    inconsistente: 'Inconsistente',
    indefinida: 'Indefinida',
    informacao: 'Informação',
    informacoes: 'Informações',
    item: 'Item',
    itens: 'Itens',
    itensPorPagina: 'Itens por página',
    juridica: 'Jurídica',
    justificativa: 'Justificativa',
    justificativaCancelamento: 'Justificativa de cancelamento',
    kg: 'kg',
    limpar: 'Limpar',
    limparBusca: 'Limpar busca',
    limparCarrinho: 'Limpar carrinho',
    limparMontadoras: 'Limpar montadoras',
    lista: 'Lista',
    logoutRealizadoComSucesso: 'Logout realizado com sucesso',
    logradouro: 'Logradouro',
    loja: 'Loja',
    lojas: 'Lojas',
    maiorValor: 'Maior valor',
    maisDetalhes: 'Mais detalhes',
    margem: 'Margem',
    margemMinima: 'Margem mínima',
    menorValor: 'Menor valor',
    menosDetalhes: 'Menos detalhes',
    meusDados: 'Meus dados',
    meusPedidos: 'Meus pedidos',
    minima: 'Mínima',
    modelo: 'Modelo',
    montadora: 'Montadora',
    montadoras: 'Montadoras',
    municipio: 'Muncípio',
    nao: 'Não',
    nenhumaMontadoraEncontradaBusca: 'Nenhuma montadora encontrada com a busca',
    nenhumFornecedorSelecionado: 'Nenhum fornecedor selecionado',
    nenhumItemAdicionado: 'Nenhum item adicionado',
    nenhumResultadoEncontrado: 'Nenhum resultado encontrado',
    nome: 'Nome',
    nomeSistema: process.env.VUE_APP_NOME_SISTEMA || 'Catálogo Lorene',
    novoPedido: 'Novo pedido',
    numero: 'Número',
    observacoes: 'Observações',
    opcional: 'Opcional',
    ordenarPor: 'Ordenar por',
    outro: 'Outro',
    outros: 'Outros',
    padrao: 'Padrão',
    pais: 'País',
    peca: 'Peça',
    pecaAdicionadaNoCarrinho: 'Peça adicionada no carrinho',
    pecaAdicionadaNoPedido: 'Peça adicionada no pedido',
    pecaCatalisador: 'Peça de catalisador',
    pecas: 'Peças',
    pecasCatalisadores: 'Peças de catalisadores',
    pedido: 'Pedido',
    pedidoAprovadoComSucesso: 'Pedido aprovado com sucesso',
    pedidoCanceladoComSucesso: 'Pedido cancelado com sucesso',
    pedidoCriadoComSucesso: 'Pedido criado com sucesso',
    pedidos: 'Pedidos',
    peso: 'Peso',
    pesoBruto: 'Peso bruto',
    pesoDaCeramica: 'Peso da cerâmica',
    pesoDetalhado: 'Peso detalhado',
    pesoLiquido: 'Peso líquido',
    pesoSeco: 'Peso seco',
    pessoa: 'Pessoa',
    pessoaFisica: 'Pessoa física',
    pessoaJuridica: 'Pessoa jurídica',
    poCatalisador: 'Pó de catalisador',
    poCatalisadores: 'Pó de catalisadores',
    ppm: 'ppm',
    produto: 'Produto',
    produtos: 'Produtos',
    prosseguirComPedido: 'Prosseguir com pedido',
    qtdeItens: 'Qtde. itens',
    quantidade: 'Quantidade',
    razaoSocial: 'Razão social',
    recursos: 'Recursos',
    remover: 'Remover',
    responsavel: 'Responsável',
    resultado: 'Resultado',
    resultados: 'Resultados',
    resumo: 'Resumo',
    sair: 'Sair',
    salvar: 'Salvar',
    selecionar: 'Selecionar',
    semEndereco: 'Sem endereço',
    semFoto: 'Sem foto',
    senha: 'Senha',
    seuCarrinhoContem1Item: 'Seu carrinho contém 1 item',
    seuCarrinhoContemNItens: 'Seu carrinho contém %{n} itens',
    seuCarrinhoEstaVazio: 'Seu carrinho está vazio',
    sim: 'Sim',
    sobrenome: 'Sobrenome',
    status: 'Status',
    suasObservacoes: 'Suas observações',
    telefone: 'Telefone',
    telefoneFixo: 'Telefone fixo',
    teores: 'Teores',
    teorPd: 'Teor Pd',
    teorPt: 'Teor Pt',
    teorRh: 'Teor Rh',
    total: 'Total',
    uf: 'UF',
    ultimaAtualizacao: 'Última atualização',
    ultimaAtualizacaoEm: 'Última atualização em',
    umidade: 'Umidade',
    valor: 'Valor',
    valores: 'Valores',
    valorLiquido: 'Valor líquido',
    valorMedio: 'Valor médio',
    valorTotal: 'Valor total',
    verifiqueSeusDados: 'Verifique seus dados',
    verMenos: 'Ver menos',
    verTodas: 'Ver todas',
    visualizacao: 'Visualização',
    visualizarEm: 'Visualizar em',
    voltar: 'Voltar',
    voltarParaCarrinho: 'Voltar para o carrinho',
    voltarParaCatalogo: 'Voltar para o catálogo',
  },
};

export default messages;
