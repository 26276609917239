import axios from 'axios';

const buscaFotosArquivos = async (
  catalisadorPecaId: number,
): Promise<any[]> => {
  const res = await axios.get<any[]>(
    `catalisadores_pecas/${catalisadorPecaId}/fotos_arquivos`,
  );
  return res.data;
};

const listaIdentificacao = async (): Promise<any[]> => {
  const res = await axios.get<any[]>(
    'catalisadores_pecas/listar_identificacao',
  );
  return res.data;
};

const meuCatalogo = async (queryDto?: any): Promise<any> => {
  const res = await axios.get<any>('catalisadores_pecas/meu_catalogo', {
    ...(queryDto ? { params: queryDto } : {}),
  });
  return res.data;
};

export default {
  buscaFotosArquivos,
  listaIdentificacao,
  meuCatalogo,
};
