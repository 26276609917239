import { RouteConfig } from 'vue-router';

import PedidosCarrinho from '@/pages/pedidos/PedidosCarrinho.vue';
import PedidosConfirmacao from '@/pages/pedidos/PedidosConfirmacao.vue';
import PedidosMeusPedidos from '@/pages/pedidos/PedidosMeusPedidos.vue';

const routes: RouteConfig[] = [
  {
    path: '/carrinho',
    name: 'PedidosCarrinho',
    component: PedidosCarrinho,
    meta: {
      icon: 'mdi-cart',
    },
  },
  {
    path: '/confirmacao_pedido',
    name: 'PedidosConfirmacao',
    component: PedidosConfirmacao,
    meta: {
      icon: 'mdi-cart',
    },
  },
  {
    path: '/meus_pedidos',
    name: 'PedidosMeusPedidos',
    component: PedidosMeusPedidos,
    meta: {
      icon: 'mdi-clipboard-text',
    },
  },
];

export default routes;
