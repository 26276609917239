import { RouteConfig } from 'vue-router';

import CalculadoraCatalisadorPo from '@/pages/calculadora/CalculadoraCatalisadorPo.vue';

const routes: RouteConfig[] = [
  {
    path: '/calculadora',
    name: 'CalculadoraCatalisadorPo',
    component: CalculadoraCatalisadorPo,
    meta: {
      icon: 'mdi-calculator',
    },
  },
];

export default routes;
