import axios from 'axios';

const cancelaParceiro = async (
  pedidoId: number,
  formData: any,
): Promise<any> => {
  const res = await axios.post<any>(
    `pedidos/parceiro/${pedidoId}/cancelar`,
    formData,
  );
  return res.data;
};

const cria = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('pedidos', formData);
  return res.data;
};

const criaParceiro = async (formData: any): Promise<any> => {
  const res = await axios.post<any>('pedidos/parceiro', formData);
  return res.data;
};

const lista = async (listarPedidosQueryDto?: any): Promise<any[]> => {
  const res = await axios.get<any[]>('pedidos', {
    ...(listarPedidosQueryDto ? { params: listarPedidosQueryDto } : {}),
  });
  return res.data;
};

const listaMeusPedidos = async (): Promise<any[]> => {
  const res = await axios.get<any[]>('pedidos/meus_pedidos', {});
  return res.data;
};

export default {
  cancelaParceiro,
  cria,
  criaParceiro,
  lista,
  listaMeusPedidos,
};
