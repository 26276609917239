var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      [
        'LojasPedidosIndex',
        'LojasFornecedoresIndex',
        'LojasCalculadora',
        'LojasCatalogo' ].includes(_vm.$route.name)
    )?[_c('div',{staticClass:"d-flex align-center mb-3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$router.push({
          name: 'LojasPedidosIndex',
          params: { lojaId: String(_vm.lojaId) },
        })}}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-store")]),(_vm.loading.loja)?_c('v-progress-circular',{attrs:{"size":"24","color":"secondary","indeterminate":""}}):_c('span',{staticClass:"text-h5 text-sm-h4"},[_vm._v(_vm._s(_vm.lojaIdentificacao))])],1),_c('v-divider',{staticClass:"mb-3 mb-sm-0"}),(
        ['LojasPedidosIndex', 'LojasFornecedoresIndex'].includes(_vm.$route.name)
      )?_c('div',{staticClass:"\n        d-flex\n        flex-column-reverse flex-sm-row\n        justify-sm-space-between\n        align-sm-center\n      "},[_c('v-tabs',{staticClass:"mb-2",attrs:{"grow":_vm.$vuetify.breakpoint.xsOnly,"background-color":"surface"}},[_c('v-tab',{attrs:{"to":{ name: 'LojasPedidosIndex' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-text")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.pedidos')))])],1),_c('v-tab',{attrs:{"to":{ name: 'LojasFornecedoresIndex' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.fornecedores')))])],1)],1),_c('div',{staticClass:"d-flex flex-column flex-sm-row align-sm-center"},[_c('v-btn',{staticClass:"mb-2 mb-sm-0 mr-sm-2",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"primary","to":{ name: 'LojasCatalogo', params: { lojaId: _vm.lojaId } }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.catalogoPecas')))])],1),_c('v-btn',{staticClass:"mb-2 mb-sm-0",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"secondary","to":{ name: 'LojasCalculadora', params: { lojaId: _vm.lojaId } }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calculator")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.calculadoraPo')))])],1)],1)],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }