




















import Vue from 'vue';

export default Vue.extend({
  name: 'TheToastSnackbar',
  data: () => ({
    show: false,
    color: 'info',
    text: [],
    details: [],
    timeout: 3000,
    showDetails: false,
  }),
  computed: {
    bottom() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    top() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
  created() {
    this.$root.$on(
      'showToast',
      (options: {
        text: string | string[];
        details?: string;
        color?: string;
        timeout?: number;
      }) => {
        this.showToast(options);
      },
    );
    this.$root.$on(
      'toastSuccess',
      (text: string | string[], timeout = 3000) => {
        this.showToast({
          text,
          color: 'success',
          timeout,
        });
      },
    );
    this.$root.$on(
      'toastError',
      (text: string | string[], details?: string, timeout = 5000) => {
        this.showToast({
          text,
          details,
          color: 'error',
          timeout,
        });
      },
    );
  },
  methods: {
    showToast(options: {
      text: string | string[];
      details?: string;
      color?: string;
      timeout?: number;
    }) {
      this.text = Array.isArray(options.text) ? options.text : [options.text];
      this.details = options?.details ?? null;
      this.color = options.color ?? 'info';
      this.timeout = options.timeout ?? 3000;
      this.show = true;
      this.showDetails = false;
    },
  },
});
