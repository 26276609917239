





















import Vue from 'vue';

import { rulesRequired } from '@/utils/input-rules';

import { Pedido } from '../../../../back/src/models/pedido.model';

import CustomDialog from '../CustomDialog.vue';
import LojasPedidoFormAprovar from './LojasPedidoFormAprovar.vue';

export default Vue.extend({
  name: 'LojasPedidoDialogAprovar',
  components: {
    CustomDialog,
    LojasPedidoFormAprovar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number,
      required: true,
    },
    pedido: {
      type: (Object as () => Pedido) || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      observacoes: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  methods: {
    onInput(event: boolean) {
      this.$emit('input', event);
    },
  },
});
