import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/src/locale/en';
import es from 'vuetify/src/locale/es';
import pt from 'vuetify/src/locale/pt';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#054446',
        surface: '#eee',
      },
    },
  },
  lang: {
    locales: { en, es, pt },
    current: 'pt',
  },
});
