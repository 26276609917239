import axios from 'axios';

const buscaAtual = async (): Promise<any> => {
  const res = await axios.get<any>('cotacoes/atual');
  return res.data;
};

export default {
  buscaAtual,
};
