import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import requester from '@/requester';
import store from '@/store';
import { ability } from '@/plugins/casl';
import { getDispositivoUuidInfo } from '@/utils/usuarios-dispositivos-helper';

import Home from '@/pages/Home.vue';
import ErroNaoEncontrado from '@/pages/erro/ErroNaoEncontrado.vue';

import authRoutes from './auth.routes';
import catalogoRoutes from './catalogo.routes';
import calculadoraRoutes from './calculadora.routes';
import lojasRoutes from './lojas.routes';
import pedidosRoutes from './pedidos.routes';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      icon: 'mdi-home',
    },
  },
  ...authRoutes,
  ...calculadoraRoutes,
  ...catalogoRoutes,
  ...lojasRoutes,
  ...pedidosRoutes,
  {
    path: '*',
    name: 'ErroNaoEncontrado',
    component: ErroNaoEncontrado,
    meta: {
      public: true,
      layout: 'Public',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  let aviso = to.name !== 'Home' ? 'usuarioDeslogado' : '';
  try {
    const usuarioCookie = await requester.auth.checkSession();

    // se rota for privada ou de login, verifica dispositivo
    if (!to?.meta?.public || to.name === 'AuthLogin') {
      aviso = 'dispositivoNaoAutorizado'; // se der erro a partir de agora, é por dispositivo não autorizado
      await requester.auth.verificaDispositivo(getDispositivoUuidInfo());
    }

    ability.update(usuarioCookie.permissoes); // atualiza permissoes
    store.dispatch('login', usuarioCookie); // salva usuario na store

    // se rota for de login e chegou até aqui, é porque já tá logado
    if (to.name === 'AuthLogin') {
      next({ name: 'Home', query: { rand: String(Date.now()) } });
      return;
    }

    // se usuario não tem permissao, redireciona pra home
    const rules = to?.meta?.rules || [];
    if (!rules.every((rule) => ability.can(rule?.action, rule?.subject))) {
      next({ name: 'Home' });
      return;
    }

    // tudo certo, segue em frente
    next();
  } catch (err) {
    // verifica se sistema está na versão correta (para caso sem erro, já tem um interceptor do axios)
    const version = err?.response?.headers?.['katalis-version'];
    if (version && version !== localStorage.getItem('katalis-version')) {
      localStorage.setItem('katalis-version', version);
      window.location.reload();
      return;
    }
    // se rota for pública, segue em frente
    if (to?.meta?.public) {
      next();
      return;
    }
    // se não, redireciona pra tela de login
    next({
      name: 'AuthLogin',
      query: {
        redirect: to.path,
        aviso,
        rand: String(Date.now()),
      },
    });
  }
});

export default router;
