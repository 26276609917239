































































































import Vue from 'vue';
import { debounce, orderBy } from 'lodash';

import requester from '@/requester';

import { ViewPedidoAtual } from '../../../../back/src/models/views/view-pedido-atual.model';
import { ListarPedidosLojasQueryDto } from '../../../../back/src/lojas/dto/listar-pedidos-loja-query.dto';

import LojasPedidoDialogInfo from '@/components/lojas/LojasPedidoDialogInfo.vue';
import TextOrNA from '@/components/TextOrNA.vue';
import { getPedidoStatusHtml } from '@/utils/pedidos-helper';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';

export default Vue.extend({
  name: 'LojasPedidosIndex',
  components: {
    LojasPedidoDialogInfo,
    TextOrNA,
  },
  data: () => ({
    loading: {
      pedidos: true,
    },
    dialogInfo: false,
    pedidoInfoId: null,
    search: null,
    options: {
      itemsPerPAge: 10,
      mustSort: true,
      sortBy: ['id'],
      sortDesc: [true],
    },
    pedidos: [] as ViewPedidoAtual[],
    serverItemsLength: 0,
    filterStatus: null,
  }),
  computed: {
    lojaId() {
      return Number(this.$route?.params?.lojaId) || 0;
    },
    itemsStatus() {
      return orderBy(
        Object.values(StatusPedidoEnum).map((el) => ({
          value: el,
          text: this.$t(`StatusPedidoEnum.${el}`),
        })),
        ['text'],
      );
    },
    headers() {
      return [
        {
          value: 'id',
          text: '#',
          class: 'text-no-wrap',
        },
        {
          value: 'fornecedorNome',
          text: this.$t('comum.fornecedor'),
          class: 'text-no-wrap',
        },
        {
          value: 'fornecedorDocumento',
          text: this.$t('comum.documento'),
          class: 'text-no-wrap',
        },
        {
          value: 'usuarioCreatedByNomeCompleto',
          text: this.$t('comum.criadoPor'),
          class: 'text-no-wrap',
        },
        {
          value: 'createdAt',
          text: this.$t('comum.criadoEm'),
          class: 'text-no-wrap',
        },
        {
          value: 'pedidoLogAtualCreatedAt',
          text: this.$t('comum.ultimaAtualizacaoEm'),
          class: 'text-no-wrap',
        },
        {
          value: 'pedidoLogAtualStatus',
          text: this.$t('comum.status'),
          class: 'text-no-wrap',
        },
        {
          value: 'info',
          text: this.$t('comum.detalhes'),
          class: 'text-no-wrap',
          sortable: false,
          align: 'center',
        },
      ];
    },
  },
  watch: {
    filterStatus() {
      this.listaPedidos(true);
    },
    options() {
      this.listaPedidos();
    },
    search() {
      this.loading.pedidos = true;
      this.listaPedidosDebounce(true);
    },
  },
  methods: {
    getPedidoStatusHtml,
    infoPedido(pedidoId: number) {
      this.pedidoInfoId = pedidoId;
      this.dialogInfo = true;
    },
    onPedidoAlterado() {
      this.listaPedidos();
    },
    montaQueryDto(resetPage = false): ListarPedidosLojasQueryDto {
      if (resetPage) {
        this.options.page = 1;
      }
      const page = this.options?.page || 1;
      const itemsPerPage = this.options?.itemsPerPage || 10;
      const limit = String(itemsPerPage);
      const offset = String((page - 1) * itemsPerPage);
      const orderBy = this.options.sortBy;
      const orderDesc = this.options.sortDesc.map(String);
      const search = this.search?.trim() || null;
      const status = this.filterStatus || null;
      return {
        limit,
        offset,
        orderBy,
        orderDesc,
        ...(search ? { search } : {}),
        ...(status ? { status } : {}),
      } as ListarPedidosLojasQueryDto;
    },
    listaPedidosDebounce: debounce(function (resetPage = false) {
      this.listaPedidos(resetPage);
    }, 250),
    async listaPedidos(resetPage = false) {
      this.loading.pedidos = true;
      try {
        const queryDto = this.montaQueryDto(resetPage);
        const resData = await requester.lojas.listaPedidos(
          this.lojaId,
          queryDto,
        );
        this.pedidos = resData?.rows || [];
        this.serverItemsLength = resData?.count || 0;
      } catch (err) {
        this.$root.$errorHandler(err, true);
      } finally {
        this.loading.pedidos = false;
      }
    },
  },
});
