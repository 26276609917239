





































import Vue from 'vue';

import { deburr } from 'lodash';

export default Vue.extend({
  name: 'CatalisadoresPecasAutocomplete',
  props: {
    value: {
      type: String,
      required: true,
    },
    catalisadoresPecas: {
      type: Array,
      default: () => [],
    },
    solo: {
      type: Boolean,
      required: false,
    },
    rounded: {
      type: Boolean,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    model: '',
    search: '',
  }),
  computed: {
    catalisadoresPecasIdentificacao() {
      return this.catalisadoresPecas.map((el) => ({
        ...el,
        codigoDetalhe: `${el.codigo}${el.detalhe ? ` - ${el.detalhe}` : ''}`,
        montadoraModelo: `${el.montadoraNome}${
          el.modelo ? ` - ${el.modelo}` : ''
        }`,
        searchDeburr: deburr(
          `${el.codigoAlfanumerico}${
            el.detalheAlfanumerico ? ` - ${el.detalheAlfanumerico}` : ''
          }  ${el.montadoraNomeAlfanumerico}${
            el.modeloAlfanumerico ? ` - ${el.modeloAlfanumerico} ` : ''
          }`,
        )
          .trim()
          .toLowerCase()
          .replace('-', ''),
      }));
    },
    items() {
      return this.search ? this.catalisadoresPecasIdentificacao : [];
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.search = '';
      }
    },
  },
  methods: {
    filter(item, search) {
      const needle = deburr(search).trim().toLowerCase().replace('-', '');
      if (item?.searchDeburr?.indexOf(needle) >= 0) {
        return true;
      }
      for (const column of ['codigo', 'detalhe', 'montadoraNome', 'modelo']) {
        const haystack = item?.[column]?.trim().toLowerCase().replace('-', '');
        if (haystack?.indexOf(needle) >= 0) {
          return true;
        }
      }
      return false;
    },
    updateSearch(evt) {
      if (this.model !== evt) {
        this.model = '';
      }
      this.search = evt || '';
      this.$emit('input', this.search);
    },
    onKeypressEnter() {
      this.$emit('apply-search');
      this.$refs?.autocomplete?.blur();
      this.model = '';
      this.search = '';
    },
    onChange(evt) {
      this.$emit('apply-search', evt);
      this.$refs?.autocomplete?.blur();
      this.model = '';
      this.search = '';
    },
  },
});
