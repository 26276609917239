import { KatalisTranslationPages } from '../translations.interface';

const messages: KatalisTranslationPages = {
  // auth
  AuthAtivarConta: {
    aceitoTermosDeUsoPoliticaPrivacidade:
      'Aceito os Termos de Uso e Política de Privacidade',
    ativarConta: 'Ativar conta',
    contaAtivadaComSucesso: 'Conta ativada com sucesso',
    definaUmaSenha: 'Defina uma senha para sua conta',
    seuEmaiDeAcesso: 'Seu email de acesso é',
    voltarParaLogin: 'Voltar para o login',
  },
  AuthEsqueciMinhaSenha: {
    esqueceuSuaSenha: 'Esqueceu sua senha?',
    insiraSeuEmail:
      'Insira seu e-mail e enviaremos um link para que possa renovar sua senha ',
    linkRenovacaoSenhaEnviado:
      'Link de renovação de senha enviado com sucesso! Verifique sua caixa de entrada.',
    solicitarRenovacaoSenha: 'Solicitar renovação de senha',
    voltarParaLogin: 'Voltar para o login',
  },
  AuthLogin: {
    bemVindo: 'Bem-vindo',
    entreComSuaConta: 'Entre com sua conta',
    esqueceuSuaSenha: 'Esqueceu sua senha?',
  },
  AuthRenovarSenha: {
    digiteSuaNovaSenha: 'Digite sua nova senha e uma confirmação',
    renovarSenha: 'Renovar senha',
    senhaRenovadaComSucesso: 'Senha renovada com sucesso',
    solicitarLinkRenovacaoDeSenha: 'Solicitar link para renovação de senha',
    voltarParaLogin: 'Voltar para o login',
  },
  // erro
  ErroNaoEncontrado: {
    caminhoNaoEncontrado:
      'O caminho <span class="text--secondary">"%{path}"</span> não foi encontrado no sistema.',
    naoEncontrado: 'Não encontrado',
    porFavorVerifique:
      'Por favor, verifique se digitou o endereço corretamente.',
    voltarPaginaInicial: 'Voltar para a página inicial',
  },
};

export default messages;
