


































































































import Vue from 'vue';

import requester from '@/requester';

import { Fornecedor } from '../../../../back/src/models/fornecedor.model';
import { FornecedorScopesEnum } from '../../../../back/src/fornecedores/fornecedores.constants';

import FornecedoresDialogBuscar from '@/components/fornecedores/FornecedoresDialogBuscar.vue';
import FornecedoresInfo from '@/components/fornecedores/FornecedoresInfo.vue';

export default Vue.extend({
  name: 'LojasPedidoFormFornecedor',
  components: {
    FornecedoresDialogBuscar,
    FornecedoresInfo,
  },
  props: {
    lojaId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: {
      fornecedor: false,
    },
    dialogCriarFornecedor: false,
    dialogBuscarFornecedores: false,
    resumoFornecedor: true,
    fornecedor: null as null | Fornecedor,
  }),
  methods: {
    onFornecedorSelecionado(fornecedorId: number) {
      this.buscaFornecedor(fornecedorId);
      this.dialogBuscarFornecedores = false;
    },
    // Função utilizada pelo componente pai
    getFornecedor() {
      return this.fornecedor;
    },
    async buscaFornecedor(fornecedorId: number) {
      this.loading.fornecedor = true;
      try {
        this.fornecedor = await requester.lojas.buscaFornecedor(
          this.lojaId,
          fornecedorId,
        );
        this.resumoFornecedor = true;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fornecedor = false;
      }
    },
  },
});
