import { KatalisTranslationComponents } from '../translations.interface';

const messages: KatalisTranslationComponents = {
  CalculadoraCatalisadorPo: {
    avisoErro: 'Verifique si ha completado todos los campos correctamente',
  },
  CatalisadoresCatalogoDialogAviso: {
    aviso1:
      'Los valores de las cotizaciones se actualizan todos los lunes. Presta atención a la fecha para cerrar tu pedido.',
    aviso2: 'Cuidado con las piezas y códigos adulterados.',
    aviso3: '¡Consulta siempre las imágenes de las piezas en el catálogo!',
  },
  PedidosFormItemCatalisadorPo: {
    calculadoraPoCatalisadores: 'Calculadora - Polvo de catalizadores',
  },
  PedidosInfoMeuCarrinho: {
    avisoVerifiqueSeusDados:
      'Si encuentra alguna inconsistencia, comuníquese con Lorene antes de realizar el pedido.',
  },
};

export default messages;
