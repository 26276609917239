
























import Vue from 'vue';

import requester from '@/requester';
import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { LojaMargem } from '../../../../back/src/models/loja-margem.model';

import LojasCatalogoPecas from '@/components/lojas/LojasCatalogoPecas.vue';

export default Vue.extend({
  name: 'LojasCatalogo',
  components: {
    LojasCatalogoPecas,
  },
  data: () => ({
    loading: {
      margens: true,
    },
    margens: [] as LojaMargem[],
  }),
  computed: {
    lojaId() {
      return Number(this.$route?.params?.lojaId) || 0;
    },
    margem() {
      const margem = this.margens.find(
        (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
      )?.margem;
      return Number.isFinite(margem) ? margem : 0.3;
    },
  },
  mounted() {
    this.listaMargens();
  },
  methods: {
    async listaMargens() {
      this.loading.margens = true;
      try {
        this.margens = await requester.lojas.listaMargens(this.lojaId);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.margens = false;
      }
    },
  },
});
