


















































































































































import Vue from 'vue';

import requester from '@/requester';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import PedidosDialogInfoMeuPedido from '@/components/pedidos/PedidosDialogInfoMeuPedido.vue';
import { last } from 'lodash';
import { Pedido } from '../../../../back/src/models/pedido.model';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'PedidosMeusPedidos',
  components: {
    PedidosDialogInfoMeuPedido,
  },
  data: () => ({
    loading: {
      pedidos: true,
    },
    dialogInfo: false,
    pedidoInfo: null,
    search: null,
    pedidos: [],
  }),
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
    lojaId() {
      return Number(this.$route?.params?.lojaId) || 0;
    },
    meusPedidos(): Pedido {
      return this.pedidos.map(
        (el) =>
          ({
            ...el,
            status: (last(el.log) as any)?.status,
          } as Pedido),
      );
    },
    headers() {
      return [
        {
          value: 'id',
          text: '#',
          class: 'text-no-wrap',
        },
        {
          value: 'createdAt',
          text: this.$t('comum.criadoEm'),
          class: 'text-no-wrap',
        },
        {
          value: 'status',
          text: this.$t('comum.status'),
          class: 'text-no-wrap',
        },
        {
          value: 'valor',
          text: this.$t('comum.valor'),
          class: 'text-no-wrap',
        },
        {
          value: 'info',
          text: this.$t('comum.informacoes'),
          class: 'text-no-wrap',
          sortable: false,
          align: 'center',
        },
      ];
    },
  },
  mounted() {
    this.listaPedidos();
  },
  methods: {
    maximumFractionDigitsCurrency,
    async pedidoCancelado() {
      const pedidoInfoId = this.pedidoInfo?.id;
      await this.listaPedidos();
      this.pedidoInfo = this.pedidos.find((el) => el.id === pedidoInfoId);
    },
    valorPedido(pedido: Pedido) {
      return (
        pedido?.pedidoItems?.reduce((acc, el) => acc + (el?.valor || 0), 0) || 0
      );
    },
    infoPedido(pedido) {
      this.pedidoInfo = pedido;
      this.dialogInfo = true;
    },
    async listaPedidos() {
      this.loading.pedidos = true;
      try {
        this.pedidos = await requester.pedidos.listaMeusPedidos();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedidos = false;
      }
    },
  },
});
