import { KatalisTranslationRoutes } from '../translations.interface';

const messages: KatalisTranslationRoutes = {
  routes: {
    // auth
    AuthAtivarConta: 'Ativar conta',
    AuthEsqueciMinhaSenha: 'Esqueci minha senha',
    AuthLogin: 'Entrar no sistema',
    AuthRenovarSenha: 'Renovar senha',
    // calculadora
    CalculadoraCatalisadorPo: 'Calculadora - Pó de Catalisador',
    // catalogo
    CatalogoCatalisadoresPecas: 'Peças de catalisadores',
    // erro
    ErroNaoEncontrado: 'Não encontrado',
    // home
    Home: 'Página inicial',
    // lojas
    LojasCalculadora: 'Calculadora',
    LojasCatalogo: 'Catálogo',
    LojasFornecedoresIndex: 'Fornecedores',
    LojasPedidosIndex: 'Pedidos',
    LojasPedidosNovo: 'Novo pedido',
    // pedidos
    PedidosCarrinho: 'Carrinho',
    PedidosConfirmacao: 'Confirmar pedido',
    PedidosMeusPedidos: 'Meus pedidos',
  },
};

export default messages;
