import { KatalisTranslationComponents } from '../translations.interface';

const messages: KatalisTranslationComponents = {
  CalculadoraCatalisadorPo: {
    avisoErro: 'Check if you have filled in all fields correctly',
  },
  CatalisadoresCatalogoDialogAviso: {
    aviso1:
      'Quotations values are updated every Monday. Pay attention to the date to finalize your order.',
    aviso2: 'Beware of adulterated parts and codes.',
    aviso3: 'Always check the images of the parts in the catalog!',
  },
  PedidosFormItemCatalisadorPo: {
    calculadoraPoCatalisadores: 'Calculator - Catalyst powder',
  },
  PedidosInfoMeuCarrinho: {
    avisoVerifiqueSeusDados:
      'If you find any inconsistency, contact Lorene before placing the order.',
  },
};

export default messages;
