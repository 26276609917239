import { CONVERSION_TROY_OZ_TO_G } from '../../../back/src/cotacoes/cotacoes.constants';
import { Cotacao } from '../../../back/src/models/cotacao.model';

export function calculaValorPorKg(
  cotacao: Cotacao,
  ptPpm: number,
  pdPpm: number,
  rhPpm: number,
): number {
  return (
    (ptPpm * (cotacao?.valorPt || 0) +
      pdPpm * (cotacao?.valorPd || 0) +
      rhPpm * (cotacao?.valorRh || 0)) *
    ((cotacao?.dolar || 0) / CONVERSION_TROY_OZ_TO_G / 1000)
  );
}
