import axios from 'axios';

import { Loja } from '../../../back/src/models/loja.model';
import { LojaMargem } from '../../../back/src/models/loja-margem.model';
import { Pedido } from '../../../back/src/models/pedido.model';
import { ViewCatalisadorPecaAtual } from '../../../back/src/models/views/view-catalisador-peca-atual.model';
import { ViewPedidoAtual } from '../../../back/src/models/views/view-pedido-atual.model';

import { Fornecedor } from '../../../back/src/models/fornecedor.model';
import { ViewFornecedorResumo } from '../../../back/src/models/views/view-fornecedor-resumo.model';
import { PaginationQueryDto } from '../../../back/src/utils/pagination-query.dto';
import { AprovaPedidoLojaDto } from '../../../back/src/lojas/dto/aprova-pedido-loja.dto';
import { BuscarLojaQueryDto } from '../../../back/src/lojas/dto/buscar-loja-query.dto';
import { CancelaPedidoLojaDto } from '../../../back/src/lojas/dto/cancela-pedido-loja.dto';
import { CatalogoLojaQueryDto } from '../../../back/src/catalisadores-pecas/dto/catalogo-loja-query.dto';
import { CreateFornecedorDto } from '../../../back/src/fornecedores/dto/create-fornecedor.dto';
import { UpdateFornecedorDto } from '../../../back/src/fornecedores/dto/update-fornecedor.dto';
import { CriaPedidoLojaDto } from '../../../back/src/lojas/dto/cria-pedido-loja.dto';
import { ListarLojasQueryDto } from '../../../back/src/lojas/dto/listar-lojas-query.dto';

const alteraFornecedor = async (
  lojaId: number,
  fornecedorId: number,
  formData: UpdateFornecedorDto,
): Promise<Fornecedor> => {
  const res = await axios.patch<Fornecedor>(
    `lojas/${lojaId}/fornecedores/${fornecedorId}`,
    formData,
  );
  return res.data;
};

const aprovaPedido = async (
  lojaId: number,
  pedidoId: number,
  formData: AprovaPedidoLojaDto,
): Promise<Pedido> => {
  const res = await axios.post<Pedido>(
    `lojas/${lojaId}/pedidos/${pedidoId}/aprovar`,
    formData,
  );
  return res.data;
};

const busca = async (
  lojaId: number,
  buscarLojaQueryDto?: BuscarLojaQueryDto,
): Promise<Loja> => {
  const res = await axios.get<Loja>(`lojas/${lojaId}`, {
    ...(buscarLojaQueryDto ? { params: buscarLojaQueryDto } : {}),
  });
  return res.data;
};

const buscaFornecedor = async (
  lojaId: number,
  fornecedorId: number,
): Promise<Pedido> => {
  const res = await axios.get<Pedido>(
    `lojas/${lojaId}/fornecedores/${fornecedorId}`,
  );
  return res.data;
};

const buscaPedido = async (
  lojaId: number,
  pedidoId: number,
): Promise<Pedido> => {
  const res = await axios.get<Pedido>(`lojas/${lojaId}/pedidos/${pedidoId}`);
  return res.data;
};

const cancelaPedido = async (
  lojaId: number,
  pedidoId: number,
  formData: CancelaPedidoLojaDto,
): Promise<Pedido> => {
  const res = await axios.post<Pedido>(
    `lojas/${lojaId}/pedidos/${pedidoId}/cancelar`,
    formData,
  );
  return res.data;
};

const calculadoraCatalisadorPo = async (
  lojaId: number,
  formData: any,
): Promise<any> => {
  const res = await axios.post<any>(
    `lojas/${lojaId}/calculadora_catalisador_po`,
    formData,
  );
  return res.data;
};

const criaFornecedor = async (
  lojaId: number,
  formData: CreateFornecedorDto,
): Promise<Fornecedor> => {
  const res = await axios.post<Fornecedor>(
    `lojas/${lojaId}/fornecedores`,
    formData,
  );
  return res.data;
};

const criaPedido = async (
  lojaId: number,
  formData: CriaPedidoLojaDto,
): Promise<Pedido> => {
  const res = await axios.post<Pedido>(`lojas/${lojaId}/pedidos`, formData);
  return res.data;
};

const listaCatalisadoresPecas = async (
  lojaId: number,
  queryDto?: CatalogoLojaQueryDto,
): Promise<{ count: number; rows: ViewCatalisadorPecaAtual[] }> => {
  const res = await axios.get<{
    count: number;
    rows: ViewCatalisadorPecaAtual[];
  }>(`lojas/${lojaId}/catalisadores_pecas`, {
    ...(queryDto ? { params: queryDto } : {}),
  });
  return res.data;
};

const listaFornecedores = async (
  lojaId: number,
  queryDto?: PaginationQueryDto,
): Promise<{ count: number; rows: ViewFornecedorResumo[] }> => {
  const res = await axios.get<{ count: number; rows: ViewFornecedorResumo[] }>(
    `lojas/${lojaId}/fornecedores`,
    {
      ...(queryDto ? { params: queryDto } : {}),
    },
  );
  return res.data;
};

const listaMargens = async (lojaId: number): Promise<LojaMargem[]> => {
  const res = await axios.get<LojaMargem[]>(`lojas/${lojaId}/margens`);
  return res.data;
};

const listaPedidos = async (
  lojaId: number,
  paginationQueryDto: PaginationQueryDto,
): Promise<{ count: number; rows: ViewPedidoAtual[] }> => {
  const res = await axios.get<{ count: number; rows: ViewPedidoAtual[] }>(
    `lojas/${lojaId}/pedidos`,
    {
      ...(paginationQueryDto ? { params: paginationQueryDto } : {}),
    },
  );
  return res.data;
};

const removeFornecedor = async (
  lojaId: number,
  fornecedorId: number,
): Promise<Fornecedor> => {
  const res = await axios.delete<Fornecedor>(
    `lojas/${lojaId}/fornecedores/${fornecedorId}`,
  );
  return res.data;
};

export default {
  alteraFornecedor,
  aprovaPedido,
  busca,
  buscaFornecedor,
  buscaPedido,
  cancelaPedido,
  calculadoraCatalisadorPo,
  criaFornecedor,
  criaPedido,
  listaCatalisadoresPecas,
  listaFornecedores,
  listaMargens,
  listaPedidos,
  removeFornecedor,
};
