







import Vue from 'vue';

import FornecedoresTablePaginated from '@/components/fornecedores/FornecedoresTablePaginated.vue';

export default Vue.extend({
  name: 'LojasFornecedoresIndex',
  components: {
    FornecedoresTablePaginated,
  },
  data: () => ({}),
  computed: {
    lojaId() {
      return Number(this.$route?.params?.lojaId) || 0;
    },
  },
});
