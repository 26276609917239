import axios from 'axios';

const lista = async (listarFamiliasQuery?: any): Promise<any[]> => {
  const res = await axios.get<any[]>('familias', {
    ...(listarFamiliasQuery ? { params: listarFamiliasQuery } : {}),
  });
  return res.data;
};

const listaCatalisadoresPo = async (familiaId: number): Promise<any[]> => {
  const res = await axios.get<any[]>(`familias/${familiaId}/catalisadores_po`);
  return res.data;
};

export default {
  lista,
  listaCatalisadoresPo,
};
