































































































































































































































































































































































































import Vue from 'vue';
import { debounce } from 'lodash';

import { ArquivoKeyEnum } from '@/utils/constants/back.constants';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import CatalogoPecaDialogInfo from '@/components/catalogo/CatalogoPecaDialogInfo.vue';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'PedidosCarrinho',
  components: {
    CatalogoPecaDialogInfo,
  },
  data: () => ({
    dialogInfo: false,
    catalisadorPecaInfo: null,
  }),
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
    carrinho() {
      return this.$store.getters?.carrinho || [];
    },
    carrinhoCatalisadoresPecas() {
      return this.carrinho.filter((el) => el?.catalisadorPeca);
    },
    quantidadeTotal() {
      return this.carrinhoCatalisadoresPecas.reduce(
        (acc, el) => acc + (el?.quantidade || 0),
        0,
      );
    },
    total() {
      return this.carrinhoCatalisadoresPecas.reduce(
        (acc, el) =>
          acc + (el?.quantidade || 0) * (el?.catalisadorPeca?.valor || 0),
        0,
      );
    },
  },
  methods: {
    maximumFractionDigitsCurrency,
    infoPeca(catalisadorPeca) {
      this.dialogInfo = true;
      this.catalisadorPecaInfo = catalisadorPeca;
    },
    getFotoArquivoSrc(catalisadorPeca, thumbnail = false) {
      const path = catalisadorPeca?.fotoArquivo?.path;
      if (!path) return null;
      return `${process.env.VUE_APP_URL_API}${path}/${
        thumbnail ? ArquivoKeyEnum.SM : ArquivoKeyEnum.WATERMARK
      }`;
    },
    minusItem(catalisadorPecaId: number) {
      this.$store.dispatch('minusItemCatalisadorPeca', catalisadorPecaId);
    },
    plusItem(catalisadorPecaId: number) {
      this.$store.dispatch('plusItemCatalisadorPeca', catalisadorPecaId);
    },
    removeItem(catalisadorPecaId: number) {
      this.$store.dispatch('removeItemCatalisadorPeca', catalisadorPecaId);
    },
    limparCarrinhoDebounce: debounce(function () {
      this.limparCarrinho();
    }, 200),
    limparCarrinho() {
      this.$store.dispatch('limparCarrinho');
    },
  },
});
