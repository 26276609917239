
























































































































































































































import Vue from 'vue';
import { CategoriaPedidoItemEnum } from '../../../back/src/pedidos/pedidos.constants';
import { CategoriaUsuarioEnum } from '../../../back/src/usuarios/usuarios.constants';

export default Vue.extend({
  name: 'Home',
  data: () => ({
    loading: {
      familias: true,
    },
    CategoriaUsuarioEnum,
  }),
  computed: {
    lojas() {
      return this.$store?.getters?.lojas || [];
    },
    categoriasMargens() {
      return this.$store.getters?.categoriasMargens || [];
    },
    podeAcessarCalculadora() {
      return this.categoriasMargens.includes(
        CategoriaPedidoItemEnum.CATALISADORES_PO,
      );
    },
    podeAcessarCatalogo() {
      return this.categoriasMargens.includes(
        CategoriaPedidoItemEnum.CATALISADORES_PECAS,
      );
    },
    sizeIcon() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 60;
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 70;
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 80;
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return 90;
      }
      return 100;
    },
    imgAspectRatio() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 4;
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 16 / 9;
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 16 / 9;
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return 4 / 3;
      }
      return 4 / 3;
    },
    usuarioCategoria() {
      return this.$store.getters?.usuario?.categoria || null;
    },
    carrinhoLength() {
      return this.$store.getters?.carrinho?.length || 0;
    },
  },
});
