import requester from '@/requester';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    carrinho: [],
    usuario: null,
    currency: 'BRL',
  },
  getters: {
    carrinho: (state) => state.carrinho,
    usuario: (state) => state.usuario,
    currency: (state) => state.currency || 'BRL',
    categoriasMargens: (state) =>
      (state.usuario?.margens || []).map((el) => el.categoria),
    lojas: (state) =>
      (state.usuario?.lojas || []).map((loja) => ({
        id: loja?.id,
        codigo: loja?.codigo,
        enderecoMunicipioNome: loja?.endereco?.municipioNome,
        identificacao: `${loja?.codigo}${
          loja?.endereco?.municipioNome
            ? ` - ${loja?.endereco?.municipioNome}`
            : ''
        }`,
        vinculo: loja?.JoinLojaUsuario?.vinculo,
      })),
  },
  mutations: {
    async adicionaItemCarrinho(state, item) {
      if (!Array.isArray(state.carrinho)) {
        state.carrinho = [];
      }
      if (item?.catalisadorPeca) {
        const index = state.carrinho.findIndex(
          (el) => el?.catalisadorPeca?.id === item?.catalisadorPeca?.id,
        );
        if (index >= 0) {
          state.carrinho[index].quantidade += item.quantidade;
        } else {
          state.carrinho.push(item);
        }
      }
      try {
        await requester.usuarios.atualizaMeuCarrinho({
          items: state.carrinho,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async minusItemCatalisadorPeca(state, catalisadorPecaId) {
      const index = state.carrinho.findIndex(
        (el) => el?.catalisadorPeca?.id === catalisadorPecaId,
      );
      if (index >= 0 && state.carrinho[index].quantidade > 1) {
        state.carrinho[index].quantidade -= 1;
      }
      try {
        await requester.usuarios.atualizaMeuCarrinho({
          items: state.carrinho,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async plusItemCatalisadorPeca(state, catalisadorPecaId) {
      const index = state.carrinho.findIndex(
        (el) => el?.catalisadorPeca?.id === catalisadorPecaId,
      );
      if (index >= 0) {
        state.carrinho[index].quantidade += 1;
      }
      try {
        await requester.usuarios.atualizaMeuCarrinho({
          items: state.carrinho,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async removeItemCatalisadorPeca(state, catalisadorPecaId) {
      const index = state.carrinho.findIndex(
        (el) => el?.catalisadorPeca?.id === catalisadorPecaId,
      );
      if (index >= 0) {
        state.carrinho = state.carrinho
          .slice(0, index)
          .concat(state.carrinho.slice(index + 1));
      }
      try {
        await requester.usuarios.atualizaMeuCarrinho({
          items: state.carrinho,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async limparCarrinho(state) {
      state.carrinho = [];
      try {
        await requester.usuarios.atualizaMeuCarrinho({
          items: state.carrinho,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async buscaCarrinho(state) {
      if (!state.usuario) return;
      try {
        const items = await requester.usuarios.atualizaMeuCarrinho({
          items: state.carrinho,
        });
        state.carrinho = items;
      } catch (err) {
        console.error(err);
      }
    },
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
  },
  actions: {
    login(context, usuario) {
      context.commit('setUsuario', usuario);
      context.commit('buscaCarrinho');
    },
    logout(context) {
      context.commit('setUsuario', null);
    },
    buscaCarrinho(context) {
      context.commit('buscaCarrinho');
    },
    adicionaItemCarrinho(context, item) {
      context.commit('adicionaItemCarrinho', item);
    },
    minusItemCatalisadorPeca(context, catalisadorPecaId) {
      context.commit('minusItemCatalisadorPeca', catalisadorPecaId);
    },
    plusItemCatalisadorPeca(context, catalisadorPecaId) {
      context.commit('plusItemCatalisadorPeca', catalisadorPecaId);
    },
    removeItemCatalisadorPeca(context, catalisadorPecaId) {
      context.commit('removeItemCatalisadorPeca', catalisadorPecaId);
    },
    limparCarrinho(context) {
      context.commit('limparCarrinho');
    },
  },
  modules: {},
});
