




















































import Vue from 'vue';

import requester from '@/requester';

import { Fornecedor } from '../../../../back/src/models/fornecedor.model';

import CustomDialog from '../CustomDialog.vue';
import FornecedoresInfo from './FornecedoresInfo.vue';
import FornecedoresDialogAlterar from './FornecedoresDialogAlterar.vue';
import { vinculoLojaResponsavel } from '@/utils/lojas-helper';

export default Vue.extend({
  name: 'FornecedoresDialogInfo',
  components: {
    CustomDialog,
    FornecedoresInfo,
    FornecedoresDialogAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number || null,
      required: true,
    },
    fornecedorId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      fornecedor: false,
    },
    fornecedor: null as null | Fornecedor,
    dialogAlterar: false,
  }),
  computed: {
    permissaoAlterar() {
      return vinculoLojaResponsavel(this.lojaId);
    },
    permissaoRemover() {
      return vinculoLojaResponsavel(this.lojaId);
    },
  },
  watch: {
    fornecedorId() {
      this.buscaFornecedor();
    },
  },
  mounted() {
    this.buscaFornecedor();
  },
  methods: {
    onFornecedorAlterado() {
      this.buscaFornecedor();
      this.dialogAlterar = false;
      this.$emit('fornecedor-alterado');
    },
    async buscaFornecedor() {
      if (!this.lojaId || !this.fornecedorId) return;
      this.loading.fornecedor = true;
      try {
        this.fornecedor = await requester.lojas.buscaFornecedor(
          this.lojaId,
          this.fornecedorId,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fornecedor = false;
      }
    },
    async removeFornecedor() {
      if (!this.lojaId || !this.fornecedorId) return;
      const confirm = await this.$root.$confirm(
        'Remover fornecedor',
        'Tem certeza que deseja remover este fornecedor?',
      );
      if (!confirm) return;
      this.loading.fornecedor = true;
      try {
        this.fornecedor = await requester.lojas.removeFornecedor(
          this.lojaId,
          this.fornecedorId,
        );
        this.$root.$emit('toastSuccess', 'Fornecedor removido com sucesso');
        this.$emit('fornecedor-removido');
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fornecedor = false;
      }
    },
  },
});
