import store from '@/store';

export const priceFormat = (
  number: number | string,
  options?: {
    fractionDigits?: number;
    locale?: string;
  },
): string => {
  const locale =
    options?.locale ||
    store.getters.intlDefaultLocale ||
    process.env.VUE_APP_INTL_DEFAULT_LOCALE ||
    'pt-BR';
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: options?.fractionDigits || 2,
    maximumFractionDigits: options?.fractionDigits || 2,
  }).format(Number(number));
};

export const maskCurrency = (
  value: number | string | null,
  options?: {
    fractionDigits?: number;
    locale?: string;
  },
): string => {
  const number =
    Number(String(value).replace(/\D*/g, '')) /
    10 ** (options?.fractionDigits || 2);
  return priceFormat(number, options);
};

export const inputPriceToNumber = (
  value: string,
  fractionDigits = 2,
): number | null => {
  if (!value) return null;
  return Number(value.replace(/\D*/g, '')) / 10 ** fractionDigits;
};

export function maximumFractionDigitsCurrency(currency: string): number {
  switch (currency.toUpperCase()) {
    case 'CLP':
    case 'COP':
    case 'JPY':
      return 0;
    case 'BRL':
    default:
      return 2;
  }
}
