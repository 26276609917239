



















import Vue from 'vue';

import { Fornecedor } from '../../../../back/src/models/fornecedor.model';

import CustomDialog from '../CustomDialog.vue';
import FornecedoresFormCriarAlterar from './FornecedoresFormCriarAlterar.vue';

export default Vue.extend({
  name: 'FornecedoresDialogAlterar',
  components: {
    CustomDialog,
    FornecedoresFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number || null,
      required: true,
    },
    fornecedor: {
      type: (Object as () => Fornecedor) || null,
      default: null,
    },
  },
  methods: {
    onInput(event: boolean) {
      this.$emit('input', event);
    },
    onFornecedorAlterado(fornecedor: Fornecedor) {
      this.$emit('fornecedor-alterado', fornecedor);
    },
  },
});
