import { KatalisTranslationRules } from '../translations.interface';

const messages: KatalisTranslationRules = {
  rules: {
    campoObrigatorio: 'Campo obrigatório',
    confirmacaoSenha: 'Confirmação diferente da senha digitada',
    emailInvalido: 'Email inválido',
    inteiroPositivo: 'Valor deve um inteiro maior ou igual a zero',
    numericoPositivo: 'Valor deve um número maior ou igual a zero',
    telefoneInvalido: 'Número de telefone inválido',
  },
};

export default messages;
