import store from '@/store';

export function maskNumeric(value: string): string {
  const replaced = String(value)
    .replace(/,/, '.')
    .replace(/[^\d.]+/, '');
  const splitted = replaced.split('.');
  if (splitted.length === 1) return splitted[0];
  return `${splitted.slice(0, 1)}.${splitted.slice(1).join('')}`;
}

export const localNumberFormat = (
  number: number | string,
  options?: {
    fractionDigits?: number;
    locale?: string;
  },
): string => {
  const locale =
    options?.locale ||
    store.getters.locale ||
    process.env.VUE_APP_INTL_DEFAULT_LOCALE ||
    'pt-BR';
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: options?.fractionDigits ?? 2,
    maximumFractionDigits: options?.fractionDigits ?? 2,
  }).format(Number(number));
};

export const maskLocalNumber = (
  value: number | string | null,
  options?: {
    fractionDigits?: number;
    locale?: string;
  },
): string => {
  const number =
    Number(String(value).replace(/\D*/g, '')) /
    10 ** (options?.fractionDigits ?? 2);
  return localNumberFormat(number, options);
};

export const inputToNumber = (
  value: string,
  fractionDigits = 2,
): number | null => {
  if (!value) return null;
  return Number(value.replace(/\D*/g, '')) / 10 ** fractionDigits;
};
