


























































import Vue from 'vue';

import requester from '@/requester';

import { Pedido } from '../../../../back/src/models/pedido.model';

import CustomDialog from '../CustomDialog.vue';
import LojasPedidoInfo from './LojasPedidoInfo.vue';
import LojasPedidoDialogCancelar from './LojasPedidoDialogCancelar.vue';
import { last } from 'lodash';
import { PedidoLog } from '../../../../back/src/models/pedido-log.model';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import LojasPedidoDialogAprovar from './LojasPedidoDialogAprovar.vue';
import { vinculoLoja, vinculoLojaResponsavel } from '@/utils/lojas-helper';

export default Vue.extend({
  name: 'LojasPedidoDialogInfo',
  components: {
    CustomDialog,
    LojasPedidoInfo,
    LojasPedidoDialogCancelar,
    LojasPedidoDialogAprovar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number,
      required: true,
    },
    pedidoId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      pedido: false,
    },
    pedido: null as null | Pedido,
    dialogAprovar: false,
    dialogCancelar: false,
  }),
  computed: {
    usuarioId() {
      return this.$store?.getters?.usuario?.id;
    },
    permissaoAprovar() {
      return vinculoLojaResponsavel(this.lojaId);
    },
    permissaoCancelar() {
      return (
        vinculoLojaResponsavel(this.lojaId) ||
        (vinculoLoja(this.lojaId) && this.pedido?.createdBy === this.usuarioId)
      );
    },
    pedidoLogAtualStatus() {
      return (last(this.pedido?.log) as PedidoLog)?.status;
    },
    showAprovar() {
      return (
        ![StatusPedidoEnum.CANCELADO, StatusPedidoEnum.APROVADO].includes(
          this.pedidoLogAtualStatus,
        ) && this.permissaoAprovar
      );
    },
    showCancelar() {
      return (
        ![StatusPedidoEnum.CANCELADO].includes(this.pedidoLogAtualStatus) &&
        this.permissaoCancelar
      );
    },
  },
  watch: {
    pedidoId() {
      this.buscaPedidoLoja();
    },
  },
  mounted() {
    this.buscaPedidoLoja();
  },
  methods: {
    onPedidoAprovado() {
      this.buscaPedidoLoja();
      this.dialogAprovar = false;
      this.$emit('pedido-alterado');
    },
    onPedidoCancelado() {
      this.buscaPedidoLoja();
      this.dialogCancelar = false;
      this.$emit('pedido-alterado');
    },
    async buscaPedidoLoja() {
      if (!this.pedidoId) return;
      this.loading.pedido = true;
      try {
        this.pedido = await requester.lojas.buscaPedido(
          this.lojaId,
          this.pedidoId,
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.pedido = false;
      }
    },
  },
});
