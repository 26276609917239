import Vue from 'vue';

import './registerServiceWorker';

import App from './App.vue';
import router from './router';
import store from './store';

// plugins
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import '@/plugins/casl';
import '@/plugins/mask';

// filters
import '@/filters';

// global components
import Loading from '@/components/Loading.vue';

Vue.config.productionTip = false;

Vue.component('Loading', Loading);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
