

















import Vue from 'vue';
import { Fornecedor } from '../../../../back/src/models/fornecedor.model';

import CustomDialog from '../CustomDialog.vue';
import FornecedoresFormCriarAlterar from './FornecedoresFormCriarAlterar.vue';

export default Vue.extend({
  name: 'FornecedoresDialogCriar',
  components: {
    CustomDialog,
    FornecedoresFormCriarAlterar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onInput(event: boolean) {
      this.$emit('input', event);
    },
    onFornecedorCriado(fornecedor: Fornecedor) {
      this.$emit('fornecedor-criado', fornecedor);
    },
  },
});
