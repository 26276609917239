import { KatalisTranslationEnums } from '../translations.interface';

const messages: KatalisTranslationEnums = {
  CategoriaPedidoItemEnum: {
    CATALISADORES_PECAS: 'Catalizadores - Piezas',
    CATALISADORES_PO: 'Catalizadores - Polvo',
  },
  StatusPedidoEnum: {
    AGUARDANDO_APROVACAO: 'Esperando aprobación',
    APROVADO: 'Aprobado',
    CANCELADO: 'Cancelado',
    APROVADO_COM_ALTERACOES: 'Aprobado con modificaciones',
  },
};

export default messages;
