var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('LojasPedidoDialogInfo',{attrs:{"lojaId":_vm.lojaId,"pedidoId":_vm.pedidoInfoId},on:{"pedido-alterado":function($event){return _vm.onPedidoAlterado()}},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":_vm.$t('comum.busca'),"append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"ml-0 ml-sm-2 mb-2",style:(_vm.$vuetify.breakpoint.smAndUp ? 'max-width: 245px' : ''),attrs:{"label":_vm.$t('comum.status'),"items":_vm.itemsStatus,"dense":"","outlined":"","clearable":"","background-color":"white","hide-details":""},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}}),_c('v-btn',{staticClass:"ml-0 ml-sm-2 mb-2",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"depressed":"","color":"success","to":{ name: 'LojasPedidosNovo' },"exact":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.novoPedido')))])],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.pedidos,"headers":_vm.headers,"loading":_vm.loading.pedidos,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fornecedorDocumento",fn:function(ref){
var item = ref.item;
return [_c('TextOrNA',{attrs:{"text":item.fornecedorDocumento}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")]}},{key:"item.pedidoLogAtualCreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.pedidoLogAtualCreatedAt))+" ")]}},{key:"item.pedidoLogAtualStatus",fn:function(ref){
var item = ref.item;
return [(item.pedidoItemsMargemMenorPadrao)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('span',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s(_vm.getPedidoStatusHtml(item.pedidoLogAtualStatus, true))}},'span',attrs,false),on))]}}],null,true)},[_c('div',[_c('div',[_vm._v("Margens menores que o padrão")]),_c('div',[_vm._v("Requer aprovação do responsável comercial")])])]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.getPedidoStatusHtml(item.pedidoLogAtualStatus))}})]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.infoPedido(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }