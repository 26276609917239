import { KatalisTranslationPages } from '../translations.interface';

const messages: KatalisTranslationPages = {
  // auth
  AuthAtivarConta: {
    aceitoTermosDeUsoPoliticaPrivacidade:
      'Acepto los Términos de Uso y Política de Privacidad',
    ativarConta: 'Activar cuenta',
    contaAtivadaComSucesso: 'Cuenta activada con éxito',
    definaUmaSenha: 'Establezca una contraseña para su cuenta',
    seuEmaiDeAcesso: 'Su correo electrónico de acceso es',
    voltarParaLogin: 'Volver al inicio de sesión',
  },
  AuthEsqueciMinhaSenha: {
    esqueceuSuaSenha: '¿Olvidaste tu contraseña?',
    insiraSeuEmail:
      'Ingrese su correo electrónico y le enviaremos un enlace para que pueda renovar su contraseña ',
    linkRenovacaoSenhaEnviado:
      '¡Enlace de renovación de contraseña enviado con éxito! Verifique su bandeja de entrada.',
    solicitarRenovacaoSenha: 'Solicitar renovación de contraseña',
    voltarParaLogin: 'Volver al inicio de sesión',
  },
  AuthLogin: {
    bemVindo: 'Bienvenido',
    entreComSuaConta: 'Ingrese con su cuenta',
    esqueceuSuaSenha: '¿Olvidaste tu contraseña?',
  },
  AuthRenovarSenha: {
    digiteSuaNovaSenha: 'Ingrese su nueva contraseña y una confirmación',
    renovarSenha: 'Renovar contraseña',
    senhaRenovadaComSucesso: 'Contraseña renovada con éxito',
    solicitarLinkRenovacaoDeSenha:
      'Solicitar enlace para renovación de contraseña',
    voltarParaLogin: 'Volver al inicio de sesión',
  },
  // erro
  ErroNaoEncontrado: {
    caminhoNaoEncontrado:
      'La ruta <span class="text--secondary">"%{path}"</span> no se encontró en el sistema.',
    naoEncontrado: 'No encontrado',
    porFavorVerifique:
      'Por favor, verifique si ingresó la dirección correctamente.',
    voltarPaginaInicial: 'Volver a la página de inicio',
  },
};

export default messages;
