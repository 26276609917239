import { TranslateResult } from 'vue-i18n';
import i18n from '@/plugins/i18n';
import { inputToNumber } from './mask-functions';

function validateCpf(formCpf: string): boolean {
  const cpf: string = formCpf.replace(/\D+/gm, '');
  if (cpf.length !== 11) {
    return false;
  }
  let soma: number;
  let resto: number;
  if (Number(cpf) % 11111111111 === 0) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 9; i += 1) {
    soma += Number(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== Number(cpf.substring(9, 10))) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 10; i += 1) {
    soma += Number(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== Number(cpf.substring(10, 11))) {
    return false;
  }
  return true;
}

function validateCnpj(formCnpj: string): boolean {
  const cnpj: string = formCnpj.replace(/\D+/gm, '');
  if (cnpj.length !== 14) {
    return false;
  }
  if (Number(cnpj) % 11111111111111 === 0) {
    return false;
  }
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += Number(numeros.charAt(tamanho - i)) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) {
    return false;
  }
  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += Number(numeros.charAt(tamanho - i)) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) {
    return false;
  }
  return true;
}

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const rulesRequired = [
  (v: unknown): boolean | TranslateResult =>
    !!v || i18n.t('rules.campoObrigatorio'),
];

export const rulesBoolean = [
  (v: unknown): boolean | TranslateResult =>
    v === true || v === false || i18n.t('rules.campoObrigatorio'),
];

export const rulesNotEmpty = [
  (v: string): boolean | TranslateResult =>
    (!!v && !!v.trim()) || i18n.t('rules.campoObrigatorio'),
];

export const rulesEmail = [
  (v: string): boolean | TranslateResult =>
    !v || validateEmail(v) || i18n.t('rules.emailInvalido'),
];

export const rulesNumericPositive = [
  (v: unknown): boolean | TranslateResult =>
    (!isNaN(Number(v)) && Number(v) >= 0) || i18n.t('rules.numericoPositivo'),
];

export const rulesLocalNumericPositive = (
  fractionDigits = 2,
): Array<(v: string) => boolean | TranslateResult> => [
  (v: string): boolean | TranslateResult =>
    inputToNumber(v, fractionDigits) >= 0 || i18n.t('rules.numericoPositivo'),
];

export const rulesIntegerPositive = [
  (v: unknown): boolean | TranslateResult =>
    (!isNaN(Number(v)) && Number.isInteger(Number(v)) && Number(v) >= 0) ||
    i18n.t('rules.inteiroPositivo'),
];

export const rulesIntegerPositiveNonZero = [
  (v: unknown): boolean | TranslateResult =>
    (!isNaN(Number(v)) && Number.isInteger(Number(v)) && Number(v) > 0) ||
    i18n.t('rules.inteiroPositivoMaiorZero'),
];

export const rulesCep = [
  (v: string): boolean | TranslateResult =>
    !v ||
    v?.replace(/\D*/g, '')?.trim()?.length === 8 ||
    i18n.t('rules.cepInvalido'),
];

export const rulesCpf = [
  (v: string): boolean | TranslateResult =>
    !v || validateCpf(v) || i18n.t('rules.cpfInvalido'),
];

export const rulesCnpj = [
  (v: string): boolean | TranslateResult =>
    !v || validateCnpj(v) || i18n.t('rules.cnpjInvalido'),
];
