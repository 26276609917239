import Vue from 'vue';
import { priceFormat } from '@/utils/intl-functions';

/**
 * Filtro para formatar o valor monetário vindo do banco
 * @param {number} value Valor numérico
 * @param {{ fractionDigits?: number; locale?: string }} options Opções contendo número de casas decimais e/ou locale para formatação
 * @returns {string} Valor formatado
 */
const filterFormatPrice = (
  value: number,
  options: { fractionDigits?: number; locale?: string },
) => {
  if (!value) return '';
  return priceFormat(value, options);
};

Vue.filter('formatPrice', filterFormatPrice);
