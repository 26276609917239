import { RouteConfig } from 'vue-router';

import CatalogoCatalisadoresPecas from '@/pages/catalogo/CatalogoCatalisadoresPecas.vue';

const routes: RouteConfig[] = [
  {
    path: '/catalisadores',
    name: 'CatalogoCatalisadoresPecas',
    component: CatalogoCatalisadoresPecas,
    meta: {
      icon: 'mdi-view-list',
    },
  },
];

export default routes;
