



























































































import Vue from 'vue';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import { ArquivoKeyEnum } from '@/utils/constants/back.constants';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'CatalisadoresCatalogoCardList',
  props: {
    catalisadorPeca: {
      type: Object,
      required: true,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
  },
  methods: {
    maximumFractionDigitsCurrency,
    getFotoArquivoSrc(catalisadorPeca, thumbnail = false) {
      const path = catalisadorPeca?.fotoArquivo?.path;
      if (!path) return null;
      return `${process.env.VUE_APP_URL_API}${path}/${
        thumbnail ? ArquivoKeyEnum.SM : ArquivoKeyEnum.WATERMARK
      }`;
    },
  },
});
