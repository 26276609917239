

































































import Vue from 'vue';
import { orderBy } from 'lodash';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';

import TextOrNA from '../TextOrNA.vue';

interface Item {
  id: number;
  index: number;
  familiaCodigo: string;
  codigo: string;
  detalhe: string;
  montadoraNome: string;
  modelo: string;
  pesoCeramicaKg: number;
  quantidade: number;
  valor: number;
  valorLiquido: number;
}

export default Vue.extend({
  name: 'PedidosTabelaItemsPecas',
  components: { TextOrNA },
  props: {
    items: {
      type: Array as () => PedidoItem[],
      default: [] as PedidoItem[],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pesoDetalhado: false,
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    thClass() {
      return 'text-no-wrap';
    },
    tdClass() {
      return 'text-no-wrap';
    },
    itemsMapped(): Item[] {
      return orderBy(
        this.items.map((item: PedidoItem) => {
          const quantidade = item?.pedidoItemPeca?.quantidade;
          const catalisadorPecaDados =
            item?.pedidoItemPeca?.catalisadorPecaDados;
          return {
            id: item.id,
            index: item?.index,
            familiaCodigo:
              item?.pedidoItemPeca?.catalisadorPeca?.familia?.codigo,
            familiaDescricao:
              item?.pedidoItemPeca?.catalisadorPeca?.familia?.descricao,
            codigo: catalisadorPecaDados?.codigo,
            detalhe: catalisadorPecaDados?.detalhe,
            montadoraNome: catalisadorPecaDados?.montadoraNome,
            modelo: catalisadorPecaDados?.modelo,
            pesoCeramicaKg: catalisadorPecaDados?.pesoKg,
            quantidade,
            valor: quantidade > 0 ? item?.valor / quantidade : 0,
            valorLiquido: item?.valor,
          };
        }),
        ['index'],
      );
    },
  },
});
