




































































































































































































































































































































































































































import Vue from 'vue';

import requester from '@/requester';

import { CategoriaFamiliaEnum } from '../../../../back/src/familias/familias.constants';
import { FornecedorScopesEnum } from '../../../../back/src/fornecedores/fornecedores.constants';
import {
  CriaPedidoItemPecaLojaDto,
  CriaPedidoItemPoLojaDto,
} from '../../../../back/src/lojas/dto/cria-pedido-loja.dto';
import { ListarFamiliaQueryDto } from '../../../../back/src/familias/dto/listar-familia-query.dto';

import LojasCalculadoraDialog from '@/components/lojas/LojasCalculadoraDialog.vue';
import { Cotacao } from '../../../../back/src/models/cotacao.model';
import { Familia } from '../../../../back/src/models/familia.model';
import { Fornecedor } from '../../../../back/src/models/fornecedor.model';
import { ItemCalculadoraCatalisadorPo } from '@/utils/helper-types';
import { calculaValorPorKg } from '@/utils/calculator-functions';
import { map, sum } from 'lodash';
import LojasCatalogoPecasBottomSheet from './LojasCatalogoPecasBottomSheet.vue';

import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { ItemPecaCatalogoLoja, ItemPecaLoja } from '@/utils/lojas-helper';
import InputNumber from '@/components/inputs/InputNumber.vue';

export default Vue.extend({
  name: 'LojasPedidoFormCatalisador',
  components: {
    InputNumber,
    LojasCalculadoraDialog,
    LojasCatalogoPecasBottomSheet,
  },
  props: {
    lojaId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: {
      fornecedores: true,
      familias: true,
      cotacaoAtual: true,
      margens: true,
    },
    fornecedores: [] as Fornecedor[],
    familiasPo: [] as Familia[],
    cotacaoAtual: null as Cotacao | null,
    dialogCalculadora: false,
    dialogCatalogo: false,
    dynamicKey: 0,
    pesoDetalhado: false,
    itemSelecionado: null as ItemCalculadoraCatalisadorPo | null,
    itemsPo: [] as ItemCalculadoraCatalisadorPo[],
    itemsPecas: [],
    // inputs : {
    //   $categoria: valor
    // }
    // $categoria pode ser
    // CategoriaPedidoItemEnum.CATALISADORES_PECAS
    // CategoriaPedidoItemEnum.CATALISADORES_PO
    inputs: {},
    // margens : {
    //   $categoria {
    //     minima(display) (campo margem minima que vem de loja margem),
    //     escolhida(calculo) (comeca com a margem padrao ),
    //     padrao(display) (campo margem que vem de loja margem)
    //   }
    // }
    margens: {},
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    CategoriaPedidoItemEnum() {
      return CategoriaPedidoItemEnum;
    },
    margensCarregadas() {
      return Object.keys(this.margens).length === 2;
    },
    quantidadeAtivosPecas() {
      return this.itemsPecasMapped.reduce(
        (acc, el) => acc + (el.ativo ? 1 : 0),
        0,
      );
    },
    quantidadeAtivosPo() {
      return this.itemsPoMapped.reduce(
        (acc, el) => acc + (el.ativo ? 1 : 0),
        0,
      );
    },
    iconSelectAllPecas() {
      if (this.quantidadeAtivosPecas === this.itemsPecasMapped.length) {
        return 'mdi-checkbox-marked';
      }
      return 'mdi-checkbox-blank-outline';
    },
    iconSelectAllPo() {
      if (this.quantidadeAtivosPo === this.itemsPoMapped.length) {
        return 'mdi-checkbox-marked';
      }
      return 'mdi-checkbox-blank-outline';
    },
    itemsPecasMapped() {
      return this.itemsPecas.map((item, index) => {
        const margemPecas =
          this.margens[CategoriaPedidoItemEnum.CATALISADORES_PECAS].escolhida;
        const valorUnidadeMargem =
          this.cotacaoAtual.dolar *
          item.catalisadorPeca.valorTotalKg *
          item.catalisadorPeca.pesoKg *
          (1 - item.catalisadorPeca?.margemPeca || 0) *
          (1 - margemPecas || 0);
        const valorLiquido = item.quantidade * valorUnidadeMargem;
        return {
          ...item,
          quantidade: item.quantidade,
          numero: index + 1,
          valorUnidadeMargem,
          valorLiquido,
          margem:
            this.margens[CategoriaPedidoItemEnum.CATALISADORES_PECAS].escolhida,
        };
      });
    },
    itemsPoMapped() {
      return this.itemsPo.map((item, index) => {
        const margemEscolhida =
          this.margens[CategoriaPedidoItemEnum.CATALISADORES_PO].escolhida || 0;
        const familia = this.familiasPo.find((f) => f.id === item.familiaId);
        const pesoLiquido = item.pesoBruto - item.impurezas;
        const pesoSeco = pesoLiquido * (1 - item.umidade);
        const valorKg = calculaValorPorKg(
          this.cotacaoAtual,
          item.ptPpm,
          item.pdPpm,
          item.rhPpm,
        );
        const valorKgMargem = valorKg * (1 - margemEscolhida);
        const valorLiquido = valorKgMargem * pesoSeco;
        return {
          ...item,
          numero: index + 1,
          familia,
          pesoLiquido,
          pesoSeco,
          valorLiquido,
          valorKgMargem,
          margem: margemEscolhida,
        };
      });
    },
    itemsPoFiltered() {
      return this.itemsPoMapped.filter((el) => el.ativo);
    },
    itemsPecasFiltered() {
      return this.itemsPecasMapped.filter((el) => el.ativo);
    },
    itemsGrouped() {
      const itemsGrouped = {};

      // PO
      const pesoSecoTotalPo = this.itemsPoFiltered.reduce(
        (acc, el) => acc + el.pesoSeco,
        0,
      );
      const valorLiquidoTotalPo = this.itemsPoFiltered.reduce(
        (acc, el) => acc + el.valorLiquido,
        0,
      );
      const valorKgMedioPo =
        pesoSecoTotalPo > 0 ? valorLiquidoTotalPo / pesoSecoTotalPo : 0;
      itemsGrouped[CategoriaPedidoItemEnum.CATALISADORES_PO] = {
        quantidade: this.itemsPoFiltered.length,
        pesoSecoTotal: pesoSecoTotalPo,
        valorLiquidoTotal: valorLiquidoTotalPo,
        valorKgMedio: valorKgMedioPo,
      };
      // END PO

      // PECAS
      const quantidade = this.itemsPecasFiltered.reduce(
        (acc, el) => acc + el.quantidade,
        0,
      );
      const pesoSecoTotalPecas = this.itemsPecasFiltered.reduce(
        (acc, el) => acc + el.catalisadorPeca.pesoKg * el.quantidade,
        0,
      );
      const valorLiquidoTotalPecas = this.itemsPecasFiltered.reduce(
        (acc, el) => acc + el.valorLiquido,
        0,
      );
      const valorKgMedioPecas =
        pesoSecoTotalPecas > 0
          ? valorLiquidoTotalPecas / pesoSecoTotalPecas
          : 0;
      itemsGrouped[CategoriaPedidoItemEnum.CATALISADORES_PECAS] = {
        quantidade,
        pesoSecoTotal: pesoSecoTotalPecas,
        valorLiquidoTotal: valorLiquidoTotalPecas,
        valorKgMedio: valorKgMedioPecas,
      };
      // END PECAS

      return itemsGrouped;
    },
    quantidadeTotal() {
      return sum(map(this.itemsGrouped, (el) => el.quantidade));
    },
    pesoTotal() {
      return sum(map(this.itemsGrouped, (el) => el.pesoSecoTotal));
    },
    valorTotal() {
      return sum(map(this.itemsGrouped, (el) => el.valorLiquidoTotal));
    },
    headersPo() {
      return [
        {
          value: 'numero',
          text: '#',
          class: 'text-no-wrap',
        },
        {
          value: 'familia.codigo',
          text: this.$t('comum.familia'),
          class: 'text-no-wrap',
        },
        ...(this.pesoDetalhado
          ? [
              {
                value: 'pesoBruto',
                text: `${this.$t('comum.pesoBruto')} (${this.$t('comum.kg')})`,
                class: 'text-no-wrap',
                align: 'end',
              },
              {
                value: 'pesoLiquido',
                text: `${this.$t('comum.pesoLiquido')} (${this.$t(
                  'comum.kg',
                )})`,
                class: 'text-no-wrap',
                align: 'end',
              },
              {
                value: 'umidade',
                text: this.$t('comum.umidade'),
                class: 'text-no-wrap',
                align: 'end',
              },
            ]
          : []),
        {
          value: 'pesoSeco',
          text: `${this.$t('comum.pesoSeco')} (${this.$t('comum.kg')})`,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'ptPpm',
          text: `Pt (${this.$t('comum.ppm')})`,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'pdPpm',
          text: `Pd (${this.$t('comum.ppm')})`,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'rhPpm',
          text: `Rh (${this.$t('comum.ppm')})`,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'margem',
          text: this.$t('comum.margem'),
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'valorKgMargem',
          text: `${this.$t('comum.valor')} (${this.currency}/${this.$t(
            'comum.kg',
          )}) `,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'valorLiquido',
          text: `${this.$t('comum.valorLiquido')} (${this.currency})`,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'ativo',
          text: this.$t('comum.ativo'),
          sortable: false,
          class: 'text-no-wrap',
          align: 'center',
          width: 1,
        },
        {
          value: 'alterar',
          text: this.$t('comum.alterar'),
          sortable: false,
          class: 'text-no-wrap',
          align: 'center',
          width: 1,
        },
        {
          value: 'remover',
          text: this.$t('comum.remover'),
          sortable: false,
          class: 'text-no-wrap',
          align: 'center',
          width: 1,
        },
      ];
    },
    headersPecas() {
      return [
        {
          value: 'numero',
          text: '#',
          class: 'text-no-wrap',
        },
        {
          value: 'catalisadorPeca.familiaCodigo',
          text: this.$t('comum.familia'),
          class: 'text-no-wrap',
        },
        {
          value: 'catalisadorPeca.codigo',
          text: this.$t('comum.peca'),
          class: 'text-no-wrap',
        },
        {
          value: 'catalisadorPeca.pesoKg',
          text: `${this.$t('comum.pesoDaCeramica')} (${this.$t('comum.kg')})`,
          class: 'text-no-wrap',
        },
        {
          value: 'quantidade',
          text: this.$t('comum.quantidade'),
          class: 'text-no-wrap',
          align: 'center',
          sortable: false,
        },
        {
          value: 'margem',
          text: this.$t('comum.margem'),
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'valorUnidadeMargem',
          text: `${this.$t('comum.valor')} (${this.currency}/${this.$t(
            'comum.peca',
          )}) `,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'valorLiquido',
          text: `${this.$t('comum.valorLiquido')} (${this.currency})`,
          class: 'text-no-wrap',
          align: 'end',
        },
        {
          value: 'ativo',
          text: this.$t('comum.ativo'),
          sortable: false,
          class: 'text-no-wrap',
          align: 'center',
          width: 1,
        },
        {
          value: 'remover',
          text: this.$t('comum.remover'),
          sortable: false,
          class: 'text-no-wrap',
          align: 'center',
          width: 1,
        },
      ];
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        this.atualizaMargemEscolhida();
      },
    },
  },
  mounted() {
    this.$set(this.inputs, CategoriaPedidoItemEnum.CATALISADORES_PO, '');
    this.$set(this.inputs, CategoriaPedidoItemEnum.CATALISADORES_PECAS, '');
    this.buscaCotacaoAtual();
    this.listaMargens();
    this.listaFamiliasPo();
  },
  methods: {
    atualizaMargemEscolhida() {
      if (!this.margensCarregadas) return;
      for (const categoria of Object.keys(this.inputs)) {
        this.margens[categoria].escolhida = this.inputs[categoria] / 100;
      }
    },
    rulesMargem(margemMinima = 0) {
      return [
        (v: string) => !isNaN(Number(v)) || 'Margem deve ser um número',
        (v: string) => Number(v) >= margemMinima || 'Margem menor que a mínima',
        (v: string) => Number(v) < 100 || 'Margem maior que a máxima',
      ];
    },
    onAdicionaItemPeca(itemPecaCatalogoLoja: ItemPecaCatalogoLoja) {
      const index = this.itemsPecas.findIndex(
        (el) =>
          el.catalisadorPeca.id === itemPecaCatalogoLoja.catalisadorPeca.id,
      );
      if (index >= 0) {
        this.$set(
          this.itemsPecas[index],
          'quantidade',
          this.itemsPecas[index].quantidade + itemPecaCatalogoLoja.quantidade,
        );
      } else {
        const itemPecaLoja: ItemPecaLoja = {
          ...itemPecaCatalogoLoja,
          ativo: true,
          key: this.dynamicKey++,
        };
        this.itemsPecas.push(itemPecaLoja);
      }
    },
    selectAllPeca() {
      const value = this.quantidadeAtivosPecas < this.itemsPecasMapped.length;
      this.itemsPo.forEach((item) => {
        item.ativo = value;
      });
    },
    toggleAtivoPeca(key: number) {
      const index = this.itemsPecas.findIndex((el) => el.key === key);
      if (index < 0) return;
      this.$set(
        this.itemsPecas[index],
        'ativo',
        !this.itemsPecas[index]?.ativo,
      );
    },
    removerItemPeca(key: number) {
      const index = this.itemsPecas.findIndex((el) => el.key === key);
      if (index < 0) return;
      this.$delete(this.itemsPecas, index);
    },
    minusQuantidadeItemPeca(key: number) {
      const index = this.itemsPecas.findIndex((el) => el.key === key);
      if (index < 0) return;
      const quantidade = this.itemsPecas[index].quantidade;
      this.$set(
        this.itemsPecas[index],
        'quantidade',
        quantidade > 1 ? quantidade - 1 : 1,
      );
    },
    plusQuantidadeItemPeca(key: number) {
      const index = this.itemsPecas.findIndex((el) => el.key === key);
      if (index < 0) return;
      const quantidade = this.itemsPecas[index].quantidade;
      this.$set(this.itemsPecas[index], 'quantidade', quantidade + 1);
    },
    selectAllPo() {
      const value = this.quantidadeAtivosPo < this.itemsPoMapped.length;
      this.itemsPo.forEach((item) => {
        item.ativo = value;
      });
    },
    toggleAtivo(key: number) {
      const index = this.itemsPo.findIndex((el) => el.key === key);
      if (index < 0) return;
      this.$set(this.itemsPo[index], 'ativo', !this.itemsPo[index]?.ativo);
    },
    cadastrarNovoItem() {
      this.itemSelecionado = null;
      this.dialogCalculadora = true;
    },
    alterarItem(key: number) {
      const index = this.itemsPo.findIndex((el) => el.key === key);
      if (index < 0) return;
      this.itemSelecionado = this.itemsPo[index];
      this.dialogCalculadora = true;
    },
    removerItem(key: number) {
      const index = this.itemsPo.findIndex((el) => el.key === key);
      if (index < 0) return;
      this.$delete(this.itemsPo, index);
    },
    onUpsertItem(item: ItemCalculadoraCatalisadorPo) {
      const index = this.itemsPo.findIndex(
        (el) => el.key === this.itemSelecionado?.key,
      );
      if (index < 0) {
        this.itemsPo.push({ ...item, ativo: true, key: this.dynamicKey++ });
      } else {
        this.$set(this.itemsPo, index, {
          ...item,
          ativo: this.itemSelecionado?.ativo ?? true,
          key: this.itemSelecionado?.key || this.dynamicKey++,
        });
      }
      this.dialogCalculadora = false;
    },
    async listaFornecedores() {
      this.loading.fornecedores = true;
      try {
        const queryDto = {
          scope: FornecedorScopesEnum.ComEndereco,
        };
        this.fornecedores = await requester.fornecedores.lista(queryDto);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.fornecedores = false;
      }
    },
    async listaMargens() {
      this.loading.margens = true;
      try {
        const margens = await requester.lojas.listaMargens(this.lojaId);
        const margemPo = margens?.find(
          (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PO,
        );
        const margemPecas = margens?.find(
          (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
        );
        this.$set(this.margens, CategoriaPedidoItemEnum.CATALISADORES_PO, {
          minima: margemPo?.margemMinima || 0.2,
          escolhida: margemPo?.margem || 0.3,
          padrao: margemPo?.margem || 0.3,
          temMargemPadrao: Number.isFinite(margemPo?.margem),
          temMargemMinima: Number.isFinite(margemPo?.margemMinima),
        });
        this.inputs[CategoriaPedidoItemEnum.CATALISADORES_PO] =
          margemPo?.margem * 100 || 30;
        this.$set(this.margens, CategoriaPedidoItemEnum.CATALISADORES_PECAS, {
          minima: margemPecas?.margemMinima || 0.2,
          escolhida: margemPecas?.margem || 0.3,
          padrao: margemPecas?.margem || 0.3,
          temMargemPadrao: Number.isFinite(margemPecas?.margem),
          temMargemMinima: Number.isFinite(margemPecas?.margemMinima),
        });
        this.inputs[CategoriaPedidoItemEnum.CATALISADORES_PECAS] =
          margemPecas?.margem * 100 || 30;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.margens = false;
      }
    },
    async listaFamiliasPo() {
      this.loading.familias = true;
      try {
        const querystring = {
          categoria: CategoriaFamiliaEnum.CATALISADORES_PO,
        } as ListarFamiliaQueryDto;
        this.familiasPo = await requester.familias.lista(querystring);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.familias = false;
      }
    },
    async buscaCotacaoAtual() {
      this.loading.cotacaoAtual = true;
      try {
        this.cotacaoAtual = await requester.cotacoes.buscaAtual();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.cotacaoAtual = false;
      }
    },
    // Função utilizada pelo componente pai
    getItemsPo(): (CriaPedidoItemPoLojaDto & {
      ativo: boolean;
      margemMinima: number;
    })[] {
      return this.itemsPo.map((item: ItemCalculadoraCatalisadorPo, index) => {
        const margemEscolhida =
          this.margens[CategoriaPedidoItemEnum.CATALISADORES_PO].escolhida || 0;
        const margemMinima =
          this.margens[CategoriaPedidoItemEnum.CATALISADORES_PO].minima;

        const familiaId = item.familiaId;
        const pesoBruto = item?.pesoBruto || 0;
        const impurezas = item?.impurezas || 0;
        const pesoLiquido = pesoBruto - impurezas;
        const umidade = item?.umidade || 0.01;
        const ptPpm = item?.ptPpm >= 0 ? item.ptPpm : null;
        const pdPpm = item?.pdPpm >= 0 ? item.pdPpm : null;
        const rhPpm = item?.rhPpm >= 0 ? item.rhPpm : null;
        return {
          familiaId,
          index: index + 1,
          margem: margemEscolhida,
          margemMinima,
          pesoBruto,
          pesoLiquido,
          umidade,
          ptPpm,
          pdPpm,
          rhPpm,
          ativo: item?.ativo ?? true,
        };
      });
    },
    // Função utilizada pelo componente pai
    getItemsPecas(): (CriaPedidoItemPecaLojaDto & {
      ativo: boolean;
      margemMinima: number;
    })[] {
      return this.itemsPecasMapped.map((item, index) => {
        const margemEscolhida =
          this.margens[CategoriaPedidoItemEnum.CATALISADORES_PECAS].escolhida ||
          0;
        const margemMinima =
          this.margens[CategoriaPedidoItemEnum.CATALISADORES_PECAS].minima;
        return {
          index: index + 1,
          margem: margemEscolhida,
          margemMinima,
          quantidade: item?.quantidade || 0,
          catalisadorPecaId: item.catalisadorPeca?.id || null,
          ativo: item?.ativo ?? true,
        };
      });
    },
  },
});
