import axios from 'axios';

import auth from './auth.requester';
import catalisadoresPecas from './catalisadores-pecas.requester';
import catalisadoresPo from './catalisadores-po.requester';
import cotacoes from './cotacoes.requester';
import externo from './externo.requester';
import familias from './familias.requester';
import fornecedores from './fornecedores.requester';
import lojas from './lojas.requester';
import montadoras from './montadoras.requester';
import pedidos from './pedidos.requester';
import usuarios from './usuarios.requester';

// backend base URL
axios.defaults.baseURL = process.env.VUE_APP_URL_API;
// send authentication cookie
axios.defaults.withCredentials = true;
// query string serializer
// axios.defaults.paramsSerializer = (params) =>
//   new URLSearchParams(params).toString();

axios.interceptors.response.use((res) => {
  const version = res?.headers['katalis-version'];
  if (
    version &&
    res.config.method === 'get' &&
    version !== localStorage.getItem('katalis-version')
  ) {
    localStorage.setItem('katalis-version', version);
    window.location.reload();
  }
  return Promise.resolve(res);
});

export default {
  auth,
  catalisadoresPecas,
  catalisadoresPo,
  cotacoes,
  externo,
  familias,
  fornecedores,
  lojas,
  montadoras,
  pedidos,
  usuarios,
};
