import store from '@/store';
import { VinculoLojaUsuarioEnum } from '../../../back/src/lojas/lojas.constants';
import { ViewCatalisadorPecaAtual } from '@models/views/view-catalisador-peca-atual.model';

export function vinculoLojaResponsavel(lojaId: number): boolean {
  const vinculo = store?.getters?.lojas?.find(
    (loja) => loja.id === lojaId,
  )?.vinculo;
  return [
    VinculoLojaUsuarioEnum.RESPONSAVEL_ADMINISTRATIVO,
    VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL,
  ].includes(vinculo);
}

export function vinculoLoja(lojaId: number): boolean {
  const vinculo = store?.getters?.lojas?.find(
    (loja) => loja.id === lojaId,
  )?.vinculo;
  return [
    VinculoLojaUsuarioEnum.RESPONSAVEL_ADMINISTRATIVO,
    VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL,
    VinculoLojaUsuarioEnum.COMPRADOR,
  ].includes(vinculo);
}

export interface ItemPecaCatalogoLoja {
  catalisadorPeca: ViewCatalisadorPecaAtual;
  quantidade: number;
}

export interface ItemPecaLoja {
  catalisadorPeca: ViewCatalisadorPecaAtual;
  quantidade: number;
  ativo: boolean;
  key: number;
}
