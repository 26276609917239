import { KatalisTranslationEnums } from '../translations.interface';

const messages: KatalisTranslationEnums = {
  CategoriaPedidoItemEnum: {
    CATALISADORES_PECAS: 'Catalisadores - Peças',
    CATALISADORES_PO: 'Catalisadores - Pó',
  },
  StatusPedidoEnum: {
    AGUARDANDO_APROVACAO: 'Aguardando aprovação',
    APROVADO: 'Aprovado',
    CANCELADO: 'Cancelado',
    APROVADO_COM_ALTERACOES: 'Aprovado com alterações',
  },
};

export default messages;
