


































import Vue from 'vue';
export default Vue.extend({
  name: 'CustomDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 960,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    onInput(evt: boolean) {
      this.$emit('input', evt);
    },
  },
});
