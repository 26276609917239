import { KatalisTranslationPages } from '../translations.interface';

const messages: KatalisTranslationPages = {
  // auth
  AuthAtivarConta: {
    aceitoTermosDeUsoPoliticaPrivacidade:
      'I accept the Terms of Use and Privacy Policy',
    ativarConta: 'Activate account',
    contaAtivadaComSucesso: 'Account activated successfully',
    definaUmaSenha: 'Set a password for your account',
    seuEmaiDeAcesso: 'Your access email is',
    voltarParaLogin: 'Back to login',
  },
  AuthEsqueciMinhaSenha: {
    esqueceuSuaSenha: 'Forgot your password?',
    insiraSeuEmail:
      'Enter your email, and we will send a link for you to renew your password ',
    linkRenovacaoSenhaEnviado:
      'Password renewal link sent successfully! Check your inbox.',
    solicitarRenovacaoSenha: 'Request password renewal',
    voltarParaLogin: 'Back to login',
  },
  AuthLogin: {
    bemVindo: 'Welcome',
    entreComSuaConta: 'Sign in with your account',
    esqueceuSuaSenha: 'Forgot your password?',
  },
  AuthRenovarSenha: {
    digiteSuaNovaSenha: 'Enter your new password and confirmation',
    renovarSenha: 'Renew password',
    senhaRenovadaComSucesso: 'Password renewed successfully',
    solicitarLinkRenovacaoDeSenha: 'Request link for password renewal',
    voltarParaLogin: 'Back to login',
  },
  // erro
  ErroNaoEncontrado: {
    caminhoNaoEncontrado:
      'The path <span class="text--secondary">"%{path}"</span> was not found in the system.',
    naoEncontrado: 'Not found',
    porFavorVerifique: 'Please check if you entered the address correctly.',
    voltarPaginaInicial: 'Back to home page',
  },
};

export default messages;
