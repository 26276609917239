




















































































import Vue from 'vue';

import requester from '@/requester';

import { LojaScopesEnum } from '../../../../back/src/lojas/lojas.constants';
import { Loja } from '../../../../back/src/models/loja.model';

export default Vue.extend({
  name: 'LojasHeader',
  props: {
    lojaId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: {
      loja: true,
    },
    loja: null as null | Loja,
  }),
  computed: {
    lojaIdentificacao() {
      const municipio = this.loja?.endereco?.municipioNome;
      return municipio
        ? `${this.loja.codigo} - ${municipio}`
        : `${this.loja.codigo}`;
    },
  },
  watch: {
    lojaId() {
      this.buscaLoja();
    },
  },
  mounted() {
    this.buscaLoja();
  },
  methods: {
    async buscaLoja() {
      this.loading.loja = true;
      try {
        this.loja = await requester.lojas.busca(this.lojaId, {
          scope: LojaScopesEnum.ComEndereco,
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.loja = false;
      }
    },
  },
});
