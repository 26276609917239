import axios, { AxiosResponse } from 'axios';

import { Usuario } from '@models/usuario.model';
import { LoginAuthDto } from '../../../back/src/auth/dto/login-auth.dto';
import { VerificaDispositivoAuthDto } from '../../../back/src/auth/dto/verifica-dispositivo-auth.dto';

const ativarConta = async (formData: any): Promise<void> => {
  await axios.post('auth/ativar_conta', formData);
};

const checkSession = async (): Promise<any> => {
  const res = await axios.get<any>('auth/check_session');
  return res.data;
};

const esqueciMinhaSenha = async (formData: any): Promise<void> => {
  await axios.post('auth/esqueci_minha_senha', formData);
};

const login = async (dto: LoginAuthDto): Promise<any> => {
  const res = await axios.post<any>('auth/login', dto);
  return res.data;
};

const logout = async (): Promise<void> => {
  await axios.post('auth/logout');
};

const renovarSenha = async (formData: any): Promise<void> => {
  await axios.post('auth/renovar_senha', formData);
};

const verificaDispositivo = async (
  dto: VerificaDispositivoAuthDto,
): Promise<Usuario> => {
  const res = await axios.post<
    VerificaDispositivoAuthDto,
    AxiosResponse<Usuario>
  >('auth/verifica_dispositivo', dto);
  return res.data;
};

const verificaTokenSenha = async (token: string): Promise<void> => {
  await axios.get(`auth/verifica_token_senha/${token}`);
};

const verificaTokenUsuario = async (token: string): Promise<any> => {
  const res = await axios.get<any>(`auth/verifica_token_usuario/${token}`);
  return res.data;
};

export default {
  ativarConta,
  checkSession,
  esqueciMinhaSenha,
  login,
  logout,
  renovarSenha,
  verificaDispositivo,
  verificaTokenSenha,
  verificaTokenUsuario,
};
