import axios from 'axios';

const meuFornecedor = async (): Promise<any> => {
  const res = await axios.get<any>('usuarios/meu_fornecedor');
  return res.data;
};

const buscaMeuCarrinho = async (): Promise<any> => {
  const res = await axios.get<any>('usuarios/meu_carrinho');
  return res.data;
};

const atualizaMeuCarrinho = async (dto: any): Promise<any> => {
  const res = await axios.put<any>('usuarios/meu_carrinho', dto);
  return res.data;
};

export default {
  atualizaMeuCarrinho,
  buscaMeuCarrinho,
  meuFornecedor,
};
