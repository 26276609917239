import axios from 'axios';

const minhaCalculadora = async (formData?: any): Promise<any> => {
  const res = await axios.post<any>(
    'catalisadores_po/minha_calculadora',
    formData,
  );
  return res.data;
};

export default {
  minhaCalculadora,
};
