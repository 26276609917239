























































































































































































































































import Vue from 'vue';

import requester from '@/requester';

import { debounce, map, sum } from 'lodash';
import { Pedido } from '../../../../back/src/models/pedido.model';
import { PedidoItem } from '../../../../back/src/models/pedido-item.model';
import { AprovaPedidoLojaDto } from '../../../../back/src/lojas/dto/aprova-pedido-loja.dto';
import { VinculoLojaUsuarioEnum } from '../../../../back/src/lojas/lojas.constants';
import { CategoriaPedidoItemEnum } from '../../../../back/src/pedidos/pedidos.constants';
import InputNumber from '@/components/inputs/InputNumber.vue';

export default Vue.extend({
  name: 'LojasPedidoFormAprovar',
  components: { InputNumber },
  props: {
    lojaId: {
      type: Number,
      required: true,
    },
    pedido: {
      type: (Object as () => Pedido) || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
      margens: false,
    },
    margensAlteradas: false,
    itemsPecas: [],
    form: {
      observacoes: null,
    },
    showError: false,
    // inputs : {
    //   $categoria: valor
    // }
    // $categoria pode ser
    // CategoriaPedidoItemEnum.CATALISADORES_PECAS
    // CategoriaPedidoItemEnum.CATALISADORES_PO
    inputs: {},
    // margens : {
    //   $categoria {
    //     minima(display) (campo margem minima que vem de loja margem),
    //     escolhida(calculo) (comeca com a margem do pedido ),
    //     padrao(display) (campo margem que vem de loja margem)
    //   }
    // }
    margens: {},
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    margemPoNoPedido() {
      const itemPo = this.pedido.pedidoItems.find(
        (e) => e.categoria === CategoriaPedidoItemEnum.CATALISADORES_PO,
      );
      if (itemPo) {
        return itemPo.margemComposta;
      }
      console.error(`Não foi possível carregar margem pó do pedido`);
      return 0;
    },
    margemPecasNoPedido() {
      const itemPeca = this.pedido.pedidoItems.find(
        (e) => e.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
      );
      if (itemPeca) {
        return itemPeca.margemComposta;
      }
      console.error(`Não foi possível carregar margem peça do pedido`);
      return 0;
    },
    CategoriaPedidoItemEnum() {
      return CategoriaPedidoItemEnum;
    },
    margensCarregadas() {
      return Object.keys(this.margens).length === 2;
    },
    vinculoUsuario() {
      return this.$store?.getters?.lojas?.find((el) => el.id === this.lojaId)
        ?.vinculo;
    },
    permissaoAlterarMargens() {
      return (
        this.vinculoUsuario === VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL
      );
    },
    permissaoAprovar() {
      return (
        this.vinculoUsuario === VinculoLojaUsuarioEnum.RESPONSAVEL_COMERCIAL ||
        (this.vinculoUsuario ===
          VinculoLojaUsuarioEnum.RESPONSAVEL_ADMINISTRATIVO &&
          !this.margensAlteradas)
      );
    },
    quantidadeAtivosPecas() {
      return this.itemsMappedPecas.reduce(
        (acc, el) => acc + (el.ativo ? 1 : 0),
        0,
      );
    },
    itemsPecasMapped() {
      if (!this.margensCarregadas) return [];
      return (
        this.pedido?.pedidoItems
          ?.filter((item) => !!item.pedidoItemPeca)
          ?.map((item: PedidoItem) => {
            const margemPecas =
              this.margens[CategoriaPedidoItemEnum.CATALISADORES_PECAS]
                .escolhida;
            const familia = this.getFamiliaFromPedidoItem(item);
            const quantidade = item.pedidoItemPeca?.quantidade || 0;
            const pesoKg = item.pedidoItemPeca.catalisadorPecaDados.pesoKg || 0;
            const valorUnidadeMargem =
              item.pedidoItemPeca.catalisadorPecaDados.cotacaoDolar *
              item.pedidoItemPeca.catalisadorPecaDados.valorTotalKg *
              pesoKg *
              (1 - item.pedidoItemPeca.catalisadorPecaDados.margemPeca || 0) *
              (1 - margemPecas || 0);
            const valorLiquido = quantidade * valorUnidadeMargem;
            return {
              ...item,
              quantidade,
              numero: item.index,
              familia,
              pesoKg,
              valorUnidadeMargem,
              valorLiquido,
            };
          }) || []
      );
    },
    itemsPoMapped() {
      if (!this.margensCarregadas) return [];
      return (
        this.pedido?.pedidoItems
          ?.filter((item) => !!item.pedidoItemPo)
          ?.map((item: PedidoItem) => {
            const margemEscolhida =
              this.margens[CategoriaPedidoItemEnum.CATALISADORES_PO].escolhida;
            const familia = this.getFamiliaFromPedidoItem(item);
            const pesoSeco = item.pedidoItemPo?.pesoSeco;
            const valorSemMargem = item.valor / (1 - item.margemUsuario);
            const valorKgSemMargem =
              pesoSeco > 0 ? valorSemMargem / pesoSeco : 0;
            const valorKgMargem = valorKgSemMargem * (1 - margemEscolhida);
            const valorLiquido = valorKgMargem * pesoSeco;
            return {
              ...item,
              numero: item.index,
              familia,
              pesoSeco,
              valorLiquido,
              valorKgMargem,
            };
          }) || []
      );
    },
    itemsPoFiltered() {
      return this.itemsPoMapped;
    },
    itemsPecasFiltered() {
      return this.itemsPecasMapped.filter(
        (el) => el.pedidoItemPeca.catalisadorPeca.ativo,
      );
    },
    itemsGrouped() {
      const itemsGrouped = {};

      // PO
      const pesoSecoTotalPo = this.itemsPoFiltered.reduce(
        (acc, el) => acc + el.pesoSeco,
        0,
      );
      const valorLiquidoTotalPo = this.itemsPoFiltered.reduce(
        (acc, el) => acc + el.valorLiquido,
        0,
      );
      const valorKgMedioPo =
        pesoSecoTotalPo > 0 ? valorLiquidoTotalPo / pesoSecoTotalPo : 0;
      itemsGrouped[CategoriaPedidoItemEnum.CATALISADORES_PO] = {
        quantidade: this.itemsPoFiltered.length,
        pesoSecoTotal: pesoSecoTotalPo,
        valorLiquidoTotal: valorLiquidoTotalPo,
        valorKgMedio: valorKgMedioPo,
      };
      // END PO

      // PECAS
      const quantidade = this.itemsPecasFiltered.reduce(
        (acc, el) => acc + el.quantidade,
        0,
      );
      const pesoSecoTotalPecas = this.itemsPecasFiltered.reduce(
        (acc, el) => acc + el.pesoKg * el.quantidade,
        0,
      );
      const valorLiquidoTotalPecas = this.itemsPecasFiltered.reduce(
        (acc, el) => acc + el.valorLiquido,
        0,
      );
      const valorKgMedioPecas =
        pesoSecoTotalPecas > 0
          ? valorLiquidoTotalPecas / pesoSecoTotalPecas
          : 0;
      itemsGrouped[CategoriaPedidoItemEnum.CATALISADORES_PECAS] = {
        quantidade,
        pesoSecoTotal: pesoSecoTotalPecas,
        valorLiquidoTotal: valorLiquidoTotalPecas,
        valorKgMedio: valorKgMedioPecas,
      };
      // END PECAS

      return itemsGrouped;
    },
    quantidadeTotal() {
      return sum(map(this.itemsGrouped, (el) => el.quantidade));
    },
    pesoTotal() {
      return sum(map(this.itemsGrouped, (el) => el.pesoSecoTotal));
    },
    /**
     * Devolve a média das margens por peso.
     */
    porcentageMediaPorPeso() {
      let acc = 0;
      for (const categoria of Object.keys(this.margens)) {
        acc +=
          this.itemsGrouped[categoria].pesoSecoTotal *
          this.margens[categoria].escolhida;
      }
      return acc / this.pesoTotal;
    },
    valorTotal() {
      return sum(map(this.itemsGrouped, (el) => el.valorLiquidoTotal));
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        this.atualizaMargemEscolhida();
      },
    },
  },
  async mounted() {
    this.$set(this.inputs, CategoriaPedidoItemEnum.CATALISADORES_PO, '');
    this.$set(this.inputs, CategoriaPedidoItemEnum.CATALISADORES_PECAS, '');
    await this.listaMargens();
  },
  methods: {
    getFamiliaFromPedidoItem(pedidoItem) {
      if (
        pedidoItem.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS
      ) {
        return pedidoItem.pedidoItemPeca.catalisadorPeca.familia;
      }
      if (pedidoItem.categoria === CategoriaPedidoItemEnum.CATALISADORES_PO) {
        return pedidoItem.pedidoItemPo.familia;
      }
      console.error(`Item ${pedidoItem.id} não é peça nem pó`);
      throw Error('Erro carregando pedido');
    },
    atualizaMargemEscolhida() {
      if (!this.margensCarregadas) return;
      for (const categoria of Object.keys(this.inputs)) {
        this.margens[categoria].escolhida = this.inputs[categoria] / 100;
      }
    },
    rulesMargem(margemMinima = 0) {
      return [
        (v: string) => !isNaN(Number(v)) || 'Margem deve ser um número',
        (v: string) => Number(v) >= margemMinima || 'Margem menor que a mínima',
        (v: string) => Number(v) < 100 || 'Margem maior que a máxima',
      ];
    },
    async listaMargens() {
      this.loading.margens = true;
      try {
        const margens = await requester.lojas.listaMargens(this.lojaId);
        const margemPo = margens?.find(
          (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PO,
        );
        const margemPecas = margens?.find(
          (el) => el.categoria === CategoriaPedidoItemEnum.CATALISADORES_PECAS,
        );

        const margemPoPadrao = margemPo?.margem || 0.3;
        const margemPoMinima = margemPo?.margemMinima || 0.2;
        this.$set(this.margens, CategoriaPedidoItemEnum.CATALISADORES_PO, {
          minima: margemPoMinima,
          escolhida: this.margemPoNoPedido,
          padrao: margemPoPadrao,
          temMargemPadrao: Number.isFinite(margemPo?.margem),
          temMargemMinima: Number.isFinite(margemPo?.margemMinima),
        });
        this.inputs[CategoriaPedidoItemEnum.CATALISADORES_PO] = Number(
          (this.margemPoNoPedido * 100).toFixed(2),
        );

        const margemPecasPadrao = margemPecas?.margem || 0.3;
        const margemPecasMinima = margemPecas?.margemMinima || 0.2;
        this.$set(this.margens, CategoriaPedidoItemEnum.CATALISADORES_PECAS, {
          minima: margemPecasMinima,
          escolhida: this.margemPecasNoPedido,
          padrao: margemPecasPadrao,
          temMargemPadrao: Number.isFinite(margemPecas?.margem),
          temMargemMinima: Number.isFinite(margemPecas?.margemMinima),
        });
        this.inputs[CategoriaPedidoItemEnum.CATALISADORES_PECAS] = Number(
          (this.margemPecasNoPedido * 100).toFixed(2),
        );

        const diffPo = Math.abs(margemPoPadrao - this.margemPoNoPedido);
        if (Number.EPSILON < diffPo) {
          this.margensAlteradas = true;
        }

        const diffPecas = Math.abs(
          margemPecasPadrao - this.margemPecasNoPedido,
        );
        if (Number.EPSILON < diffPecas) {
          this.margensAlteradas = true;
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.margens = false;
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      this.showError = false;
      if (!this.$refs?.form?.validate()) {
        this.showError = true;
        return;
      }
      this.loading.submitForm = true;
      try {
        const margens = Object.keys(this.margens).map((categoria) => ({
          categoria,
          margem: this.margens[categoria].escolhida,
        }));
        const formData: AprovaPedidoLojaDto = {
          observacoes: this.form.observacoes?.trim() || null,
          ...(this.permissaoAlterarMargens ? margens : {}),
        };
        const pedido = await requester.lojas.aprovaPedido(
          this.lojaId,
          this.pedido.id,
          formData,
        );
        this.$emit('pedido-aprovado', pedido);
        this.$root.$emit(
          'toastSuccess',
          this.$t('comum.pedidoAprovadoComSucesso'),
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
