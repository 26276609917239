import { DeviceUUID } from 'device-uuid';

import {
  geraDispositivoUuid,
  getIdentificacaoFromSource,
} from '../../../back/src/utils/usuarios-dispositivos-helper';

export const getDispositivoUuidInfo = (): {
  dispositivoUuid: string;
  dispositivoIdentificacao: string;
  dispositivoInfo: unknown;
} => {
  const du = new DeviceUUID().parse();
  const identificacao = getIdentificacaoFromSource(du.source);
  const uuid = geraDispositivoUuid(du);

  return {
    dispositivoUuid: uuid,
    dispositivoIdentificacao: identificacao,
    dispositivoInfo: du,
  };
};
