


































































































































































































































import Vue from 'vue';

import { deburr, orderBy, toLower } from 'lodash';

export default Vue.extend({
  name: 'CatalogoPecaFiltros',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderOptions: {
      type: Array,
      default: () => [],
    },
    perPageOptions: {
      type: Array,
      default: () => [],
    },
    montadoras: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showFiltroOrdenacao: false,
    showFiltroPerPage: false,
    showFiltroVisualizacao: false,
    orderKey: 0,
    perPage: 12,
    viewList: false,
    searchMontadora: null,
    filterMontadora: [],
  }),
  computed: {
    order() {
      return this.orderOptions.find((el) => el.key === this.orderKey);
    },
    montadorasOrdenadas() {
      return orderBy(
        this.montadoras.filter((el) =>
          deburr(toLower(el.nome)).includes(
            deburr(toLower(this.searchMontadora)),
          ),
        ),
        (el) => (this.filterMontadora.includes(el.id) ? 0 : 1),
      );
    },
  },
  methods: {
    reset(options) {
      this.showFiltroOrdenacao = false;
      this.showFiltroPerPage = false;
      this.showFiltroVisualizacao = false;
      this.orderKey = options?.orderKey || 0;
      this.perPage = options?.perPage || 12;
      this.viewList = options?.viewList || false;
      this.filterMontadora = [...options?.filterMontadora] || [];
    },
    aplicar() {
      this.$emit('aplicar', {
        orderKey: this.orderKey,
        perPage: this.perPage,
        viewList: this.viewList,
        filterMontadora: [...this.filterMontadora],
      });
    },
    onClickOrder(itemKey: number) {
      this.orderKey = itemKey;
      this.showFiltroOrdenacao = false;
    },
    onClickPerPage(perPage: number) {
      this.perPage = perPage;
      this.showFiltroPerPage = false;
    },
    onClickViewGrid() {
      this.viewList = false;
      this.showFiltroVisualizacao = false;
    },
    onClickViewList() {
      this.viewList = true;
      this.showFiltroVisualizacao = false;
    },
    checkMontadora(montadoraId: number) {
      this.searchMontadora = null;
      const index = this.filterMontadora.findIndex((el) => el === montadoraId);
      if (index >= 0) {
        this.$delete(this.filterMontadora, index);
      } else {
        this.filterMontadora.push(montadoraId);
      }
    },
    limparFilterMontadora() {
      this.filterMontadora = [];
    },
  },
});
