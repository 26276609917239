












































import Vue from 'vue';

import LojasCatalogoPecas from './LojasCatalogoPecas.vue';
import { ItemPecaCatalogoLoja } from '@/utils/lojas-helper';

export default Vue.extend({
  name: 'LojasCatalogoPecasBottomSheet',
  components: {
    LojasCatalogoPecas,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number,
      required: true,
    },
    margem: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    itemKey() {
      return this.item?.key || 0;
    },
  },
  methods: {
    onInput(evt: boolean) {
      this.$emit('input', evt);
    },
    onAdicionaItemPeca(itemPecaCatalogoLoja: ItemPecaCatalogoLoja) {
      this.$emit('adiciona-item-peca', itemPecaCatalogoLoja);
    },
  },
});
