
































































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { vinculoLojaResponsavel } from '@/utils/lojas-helper';

import { ListarFornecedoresQueryDto } from '../../../../back/src/fornecedores/dto/listar-fornecedores-query.dto';
import { PaginationQueryDto } from '../../../../back/src/utils/pagination-query.dto';
import { PessoaFornecedorEnum } from '../../../../back/src/fornecedores/fornecedores.constants';
import { ViewFornecedorResumo } from '../../../../back/src/models/views/view-fornecedor-resumo.model';
import FornecedoresDialogCriar from './FornecedoresDialogCriar.vue';
import { Fornecedor } from '../../../../back/src/models/fornecedor.model';
import FornecedoresDialogInfo from './FornecedoresDialogInfo.vue';

export default Vue.extend({
  name: 'FornecedoresTablePaginated',
  components: {
    FornecedoresDialogCriar,
    FornecedoresDialogInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number || null,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogCriar: false,
    dialogInfo: false,
    fornecedorInfoId: null,
    loading: {
      fornecedores: true,
    },
    search: null,
    options: {
      itemsPerPage: 10,
      mustSort: true,
      sortBy: ['nome'],
      sortDesc: [false],
    },
    serverItemsLength: 0,
    fornecedores: [] as ViewFornecedorResumo[],
    PessoaFornecedorEnum,
  }),
  computed: {
    permissaoCriar() {
      return vinculoLojaResponsavel(this.lojaId);
    },
    headers() {
      return [
        {
          value: 'nome',
          text: this.$t('comum.nome'),
          class: 'text-no-wrap',
        },
        {
          value: 'documento',
          text: this.$t('comum.documento'),
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
        },
        {
          value: 'email',
          text: this.$t('comum.email'),
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
        },
        {
          value: 'celular',
          text: `${this.$t('comum.celular')}/${this.$t('comum.telefone')}`,
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
        },
        {
          value: 'enderecoMunicipioNome',
          text: this.$t('comum.municipio'),
          class: 'text-no-wrap',
        },
        {
          value: 'enderecoUfSigla',
          text: this.$t('comum.uf'),
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap',
        },
        ...(this.selectable
          ? [
              {
                value: 'selecionar',
                text: this.$t('comum.selecionar'),
                sortable: false,
                align: 'center',
              },
            ]
          : []),
        ...(this.info
          ? [
              {
                value: 'info',
                text: this.$t('comum.detalhes'),
                sortable: false,
                align: 'center',
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    options() {
      this.listaFornecedores();
    },
    search() {
      this.loading.fornecedores = true;
      this.listaFornecedoresDebounce(true);
    },
  },
  methods: {
    infoFornecedor(fornecedorId: number) {
      this.fornecedorInfoId = fornecedorId;
      this.dialogInfo = true;
    },
    onFornecedorCriado() {
      this.dialogCriar = false;
      this.listaFornecedores(true);
    },
    onFornecedorAlterado() {
      this.listaFornecedores(true);
    },
    onFornecedorRemovido() {
      this.dialogInfo = false;
      this.listaFornecedores(true);
    },
    selecionaFornecedor(fornecedor: ViewFornecedorResumo) {
      this.$emit('fornecedor-selecionado', fornecedor);
    },
    montaQueryDto(resetPage = false): ListarFornecedoresQueryDto {
      if (resetPage) {
        this.options.page = 1;
      }
      const page = this.options?.page || 1;
      const itemsPerPage = this.options?.itemsPerPage || 10;
      const limit = String(itemsPerPage);
      const offset = String((page - 1) * itemsPerPage);
      const orderBy = this.options.sortBy;
      const orderDesc = this.options.sortDesc.map(String);
      const search = this.search?.trim() || null;
      return {
        limit,
        offset,
        orderBy,
        orderDesc,
        ...(search ? { search } : {}),
      } as PaginationQueryDto;
    },
    listaFornecedoresDebounce: debounce(function (resetPage = false) {
      this.listaFornecedores(resetPage);
    }, 250),
    async listaFornecedores(resetPage = false) {
      this.loading.fornecedores = true;
      try {
        const queryDto = this.montaQueryDto(resetPage);
        const resData = await requester.lojas.listaFornecedores(
          this.lojaId,
          queryDto,
        );
        this.fornecedores = resData?.rows || [];
        this.serverItemsLength = resData?.count || 0;
      } catch (err) {
        this.$root.$errorHandler(err, true);
      } finally {
        this.loading.fornecedores = false;
      }
    },
  },
});
