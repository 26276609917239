






















































































import { getPedidoStatusHtml } from '@/utils/pedidos-helper';
import Vue from 'vue';

import { Pedido } from '../../../../back/src/models/pedido.model';
import FornecedoresInfo from '../fornecedores/FornecedoresInfo.vue';
import PedidosTabelaItemsPecas from '../pedidos/PedidosTabelaItemsPecas.vue';
import PedidosTabelaItemsPo from '../pedidos/PedidosTabelaItemsPo.vue';
import PedidosTabelaLog from '../pedidos/PedidosTabelaLog.vue';
import PedidosTabelaValores from '../pedidos/PedidosTabelaValores.vue';

export default Vue.extend({
  name: 'LojasPedidoInfo',
  components: {
    FornecedoresInfo,
    PedidosTabelaItemsPo,
    PedidosTabelaValores,
    PedidosTabelaLog,
    PedidosTabelaItemsPecas,
  },
  props: {
    pedido: {
      type: (Object as () => Pedido) || null,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    itemsPecas() {
      return this.pedido.pedidoItems.filter((el) => !!el?.pedidoItemPeca);
    },
    itemsPo() {
      return this.pedido.pedidoItems.filter((el) => !!el?.pedidoItemPo);
    },
    pedidoStatusAtual() {
      const log = this.pedido?.log || [];
      return log?.[log.length - 1]?.status || null;
    },
    lojaIdentificacao() {
      if (!this.pedido?.loja) return null;
      const endereco = this.pedido.loja?.endereco;
      return endereco
        ? `${this.pedido.loja.codigo} - ${endereco?.municipioNome}`
        : this.pedido.loja.codigo;
    },
  },
  methods: {
    getPedidoStatusHtml,
  },
});
