import { KatalisTranslationRoutes } from '../translations.interface';

const messages: KatalisTranslationRoutes = {
  routes: {
    // auth
    AuthAtivarConta: 'Activar cuenta',
    AuthEsqueciMinhaSenha: 'Olvidé mi contraseña',
    AuthLogin: 'Iniciar sesión en el sistema',
    AuthRenovarSenha: 'Renovar contraseña',
    // calculadora
    CalculadoraCatalisadorPo: 'Calculadora - Polvo de Catalizador',
    // catalogo
    CatalogoCatalisadoresPecas: 'Catalizadores - Piezas',
    // erro
    ErroNaoEncontrado: 'No encontrado',
    // home
    Home: 'Página de inicio',
    // lojas
    LojasCalculadora: 'Calculadora',
    LojasCatalogo: 'Catálogo',
    LojasFornecedoresIndex: 'Proveedores',
    LojasPedidosIndex: 'Pedidos',
    LojasPedidosNovo: 'Nuevo pedido',
    // pedidos
    PedidosCarrinho: 'Carrito',
    PedidosConfirmacao: 'Confirmar pedido',
    PedidosMeusPedidos: 'Mis pedidos',
  },
};

export default messages;
