
























































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';
import { getDispositivoUuidInfo } from '@/utils/usuarios-dispositivos-helper';

import { LoginAuthDto } from '../../../../back/src/auth/dto/login-auth.dto';

export default Vue.extend({
  name: 'AuthLogin',
  data: () => ({
    mostrarSenha: false,
    form: {
      email: '',
      senha: '',
    },
    rules: {
      required: rulesRequired,
    },
  }),
  computed: {
    appendIconSenha() {
      return this.mostrarSenha ? 'mdi-eye-off' : 'mdi-eye';
    },
    aviso() {
      return this.$route.query?.aviso || null;
    },
    redirect() {
      const redirect = this.$route.query?.redirect;
      if (redirect === this.$route.path) return '/';
      return redirect || '/';
    },
  },
  mounted() {
    if (this.aviso) {
      switch (this.aviso) {
        case 'usuarioDeslogado':
          this.$root.$emit(
            'toastError',
            'Você precisa estar logado para acessar o sistema',
          );
          break;
        case 'dispositivoNaoAutorizado':
          this.$root.$emit('toastError', 'Dispositivo não autorizado');
          break;
        default:
          break;
      }
    }
  },
  methods: {
    onKeypressEnterInputEmail() {
      if (this.form.senha) {
        this.submitFormDebounce();
      } else {
        this.$refs?.inputSenha?.focus();
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs?.formAuthLogin?.validate()) {
        return;
      }
      const dispositivoUuidInfo = getDispositivoUuidInfo();
      const dto: LoginAuthDto = {
        email: this.form.email?.trim(),
        senha: this.form.senha,
        ...dispositivoUuidInfo,
      };
      try {
        const usuario = await requester.auth.login(dto);
        this.$ability.update(usuario.permissoes);
        this.$store.dispatch('login', usuario);
        const redirectWithRandQuery = `${this.redirect}${
          this.redirect.includes('?') ? '&' : '?'
        }rand=${Date.now()}`;
        this.$router.push(redirectWithRandQuery);
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
