import { RouteConfig } from 'vue-router';

import LojasCalculadora from '@/pages/lojas/LojasCalculadora.vue';
import LojasFornecedoresIndex from '@/pages/lojas/LojasFornecedoresIndex.vue';
import LojasPedidosIndex from '@/pages/lojas/LojasPedidosIndex.vue';
import LojasPedidosNovo from '@/pages/lojas/LojasPedidosNovo.vue';
import LojasCatalogo from '@/pages/lojas/LojasCatalogo.vue';

const routes: RouteConfig[] = [
  {
    path: '/lojas/:lojaId/pedidos/novo',
    name: 'LojasPedidosNovo',
    component: LojasPedidosNovo,
    meta: {
      icon: 'mdi-clipboard-plus',
      layout: 'Loja',
    },
  },
  {
    path: '/lojas/:lojaId/pedidos',
    name: 'LojasPedidosIndex',
    component: LojasPedidosIndex,
    meta: {
      icon: 'mdi-clipboard',
      layout: 'Loja',
    },
  },
  {
    path: '/lojas/:lojaId/fornecedores',
    name: 'LojasFornecedoresIndex',
    component: LojasFornecedoresIndex,
    meta: {
      icon: 'mdi-account',
      layout: 'Loja',
    },
  },
  {
    path: '/lojas/:lojaId/calculadora',
    name: 'LojasCalculadora',
    component: LojasCalculadora,
    meta: {
      icon: 'mdi-calculator',
      layout: 'Loja',
    },
  },
  {
    path: '/lojas/:lojaId/catalogo',
    name: 'LojasCatalogo',
    component: LojasCatalogo,
    meta: {
      icon: 'mdi-magnify',
      layout: 'Loja',
    },
  },
];

export default routes;
