

























































































































































import Vue from 'vue';
import { orderBy } from 'lodash';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';

import TextOrNA from '../TextOrNA.vue';

interface Item {
  id: number;
  index: number;
  familiaCodigo: string;
  pesoBruto: number;
  pesoLiquido: number;
  umidade: number;
  pesoSeco: number;
  ptPpm: number;
  pdPpm: number;
  rhPpm: number;
  margem: number;
  valorKg: number;
  valorLiquido: number;
}

export default Vue.extend({
  name: 'PedidosTabelaItemsPo',
  components: { TextOrNA },
  props: {
    items: {
      type: Array as () => PedidoItem[],
      default: [] as PedidoItem[],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pesoDetalhado: false,
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    thClass() {
      return 'text-no-wrap';
    },
    tdClass() {
      return 'text-no-wrap';
    },
    itemsMapped(): Item[] {
      return orderBy(
        this.items.map((item: PedidoItem, index: number) => {
          const pesoSeco = item?.pedidoItemPo?.pesoSeco || 0;
          const valorKg = pesoSeco > 0 ? item?.valor / pesoSeco : 0;
          return {
            id: item?.id,
            index: item?.index || index + 1,
            familiaCodigo: item?.pedidoItemPo?.familia?.codigo,
            familiaDescricao: item?.pedidoItemPo?.familia?.descricao,
            pesoBruto: item?.pedidoItemPo?.pesoBruto,
            pesoLiquido: item?.pedidoItemPo?.pesoLiquido,
            umidade: item?.pedidoItemPo?.umidade,
            pesoSeco,
            ptPpm: item?.pedidoItemPo?.ptPpm,
            pdPpm: item?.pedidoItemPo?.pdPpm,
            rhPpm: item?.pedidoItemPo?.rhPpm,
            margem: item.margemUsuario,
            valorKg,
            valorLiquido: item?.valor,
          } as Item;
        }),
        ['index'],
      );
    },
  },
});
