var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{attrs:{"disabled":!_vm.hoverable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"outlined":"","elevation":hover ? 4 : 0},on:{"click":function($event){return _vm.$emit('info')}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-img',{attrs:{"width":"100px","aspect-ratio":4 / 3,"src":_vm.getFotoArquivoSrc(_vm.catalisadorPeca)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"\n              d-flex\n              justify-center\n              align-center\n              fill-height\n              grey\n              lighten-2\n            "},[_c('v-icon',{attrs:{"x-large":"","color":"grey lighten-1"}},[_vm._v("mdi-image-off")])],1)]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"\n          d-flex\n          flex-column flex-sm-row\n          justify-sm-space-between\n          align-start align-sm-center\n          px-4\n          py-2\n        ",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column align-start",staticStyle:{"width":"100%"}},[_c('div',{class:[
              'text-subtitle-1 font-weight-medium',
              hover ? 'primary--text' : 'secondary--text' ]},[_c('span',[_vm._v(_vm._s(_vm.catalisadorPeca.codigo))]),(_vm.catalisadorPeca.detalhe)?_c('span',[_vm._v(" - "+_vm._s(_vm.catalisadorPeca.detalhe)+" ")]):_vm._e()]),_c('div',{class:[
              'text-body-2',
              hover ? 'primary--text' : 'secondary--text text--lighten-2' ]},[_c('span',[_vm._v(_vm._s(_vm.catalisadorPeca.montadoraNome))]),(_vm.catalisadorPeca.modelo)?_c('span',[_vm._v(" - "+_vm._s(_vm.catalisadorPeca.modelo)+" ")]):_vm._e()])]),_c('div',{class:[
            'd-flex justify-end align-center',
            hover ? 'primary--text' : 'secondary--text text--lighten-2' ],staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(new Intl.NumberFormat(_vm.locale, { style: 'currency', currency: _vm.currency, minimumFractionDigits: _vm.maximumFractionDigitsCurrency(_vm.currency), maximumFractionDigits: _vm.maximumFractionDigitsCurrency(_vm.currency), }).format(_vm.catalisadorPeca.valor))+" ")])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }