import axios from 'axios';
import { Fornecedor } from '../../../back/src/models/fornecedor.model';
import { ViewFornecedorResumo } from '../../../back/src/models/views/view-fornecedor-resumo.model';
import { CreateFornecedorDto } from '../../../back/src/fornecedores/dto/create-fornecedor.dto';
import { BuscarFornecedorQueryDto } from '../../../back/src/fornecedores/dto/buscar-fornecedor-query.dto';
import { ListarFornecedoresQueryDto } from '../../../back/src/fornecedores/dto/listar-fornecedores-query.dto';

const cria = async (formData?: CreateFornecedorDto): Promise<Fornecedor> => {
  const res = await axios.post<Fornecedor>('fornecedores', formData);
  return res.data;
};

const busca = async (
  fornecedorId: number,
  queryDto?: BuscarFornecedorQueryDto,
): Promise<Fornecedor> => {
  const res = await axios.get<Fornecedor>(`fornecedores/${fornecedorId}`, {
    ...(queryDto ? { params: queryDto } : {}),
  });
  return res.data;
};

const lista = async (
  queryDto?: ListarFornecedoresQueryDto,
): Promise<{ count: number; rows: ViewFornecedorResumo[] }> => {
  const res = await axios.get<{ count: number; rows: ViewFornecedorResumo[] }>(
    'fornecedores',
    {
      ...(queryDto ? { params: queryDto } : {}),
    },
  );
  return res.data;
};

export default {
  cria,
  busca,
  lista,
};
