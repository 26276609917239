export const CONVERSION_TROY_OZ_TO_G = 31.1034768;

export enum ArquivoKeyEnum {
  RAW = 'raw',
  WATERMARK = 'watermark',
  THUMBNAIL = 'thumbnail',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum CategoriaFamiliaEnum {
  CATALISADORES_PECAS = 'CATALISADORES_PECAS',
  CATALISADORES_PO = 'CATALISADORES_PO',
  PLACAS = 'PLACAS',
}

export enum CategoriaUsuarioEnum {
  FUNCIONARIO = 'FUNCIONARIO',
  PARCEIRO = 'PARCEIRO',
}

export enum FornecedorScopesEnum {
  ComEndereco = 'ComEndereco',
}

export enum LojaScopesEnum {
  ComEndereco = 'ComEndereco',
  ComUsuarios = 'ComUsuarios',
  ComUsuariosUsuarioCreatedUpdatedMargens = 'ComUsuariosUsuarioCreatedUpdatedMargens',
}

export enum PedidoScopesEnum {
  ComLojaUsuarioCreatedByUpdatedBy = 'ComLojaUsuarioCreatedByUpdatedBy',
}
