import { KatalisTranslationComum } from '../translations.interface';

const messages: KatalisTranslationComum = {
  comum: {
    adicionar: 'Add',
    agencia: 'Agency',
    aguardandoAprovacao: 'Awaiting approval',
    alterar: 'Change',
    analise: 'Analysis',
    aplicar: 'Apply',
    aprovado: 'Approved',
    aprovadoComAlteracoes: 'Approved with changes',
    aprovarPedido: 'Approve order',
    aviso: 'Warning',
    bairro: 'Neighborhood',
    banco: 'Bank',
    busca: 'Search',
    buscar: 'Search',
    buscarFornecedor: 'Search supplier',
    buscarMontadora: 'Search automaker',
    cadastrarFornecedor: 'Register supplier',
    calculadora: 'Calculator',
    calculadoraPo: 'Calculator - Powder',
    calculadoraPoCatalisadores: 'Calculator - Catalyst powder',
    calcular: 'Calculate',
    cancelado: 'Canceled',
    cancelar: 'Cancel',
    cancelarPedido: 'Cancel order',
    carrinho: 'Cart',
    catalisadoresPecas: 'Catalysts - Parts',
    catalogo: 'Catalog',
    catalogoPecas: 'Catalog - Parts',
    categoria: 'Category',
    celular: 'Cellphone',
    cep: 'ZIP code',
    cepNaoEncontrado: 'ZIP code not found',
    certezaAprovarPedido: 'Are you sure you want to approve this order?',
    certezaCancelarPedido: 'Are you sure you want to cancel this order?',
    classe: 'Class',
    cnpj: 'CNPJ',
    codigo: 'Code',
    codigoPeca: 'Part code',
    comecoAFimDeTotal: '%{comeco} to %{fim} of %{total}',
    complemento: 'Complement',
    confirmacaoSenha: 'Password confirmation',
    confirmar: 'Confirm',
    confirmarPedido: 'Confirm order',
    conta: 'Account',
    cpf: 'CPF',
    criadoEm: 'Created on',
    criadoPor: 'Created by',
    dadosBancarios: 'Banking information',
    dadosFornecedorNaoEncontrados: 'Supplier data not found',
    dadosGerais: 'General data',
    data: 'Date',
    descricaoCatalogo: 'Description',
    detalhe: 'Detail',
    detalhes: 'Details',
    documento: 'Document',
    email: 'Email',
    emConstrucao: 'Under construction',
    endereco: 'Address',
    enderecoNaoCadastrado: 'Address not registered',
    entrar: 'Log in',
    enviar: 'Send',
    estouCiente: 'I am aware',
    familia: 'Family',
    fechar: 'Close',
    filtro: 'Filter',
    filtros: 'Filters',
    fisica: 'Physical',
    fornecedor: 'Supplier',
    fornecedorAlteradoComSucesso: 'Supplier changed successfully',
    fornecedorCadastradoComSucesso: 'Supplier registered successfully',
    fornecedores: 'Suppliers',
    foto: 'Photo',
    fotos: 'Photos',
    grade: 'Grade',
    historico: 'History',
    home: 'Home page',
    identificacao: 'Identification',
    impurezas: 'Impurities',
    inconsistente: 'Inconsistent',
    indefinida: 'Undefined',
    informacao: 'Information',
    informacoes: 'Information',
    item: 'Item',
    itens: 'Items',
    itensPorPagina: 'Items per page',
    juridica: 'Legal',
    justificativa: 'Justification',
    justificativaCancelamento: 'Cancellation justification',
    kg: 'kg',
    limpar: 'Clear',
    limparBusca: 'Clear search',
    limparCarrinho: 'Clear cart',
    limparMontadoras: 'Clear automakers',
    lista: 'List',
    logoutRealizadoComSucesso: 'Logout successful',
    logradouro: 'Street',
    loja: 'Store',
    lojas: 'Stores',
    maiorValor: 'Highest value',
    maisDetalhes: 'More details',
    margem: 'Margin',
    margemMinima: 'Minimum margin',
    menorValor: 'Lowest value',
    menosDetalhes: 'Less details',
    meusDados: 'My data',
    meusPedidos: 'My orders',
    minima: 'Minimum',
    modelo: 'Model',
    montadora: 'Automaker',
    montadoras: 'Automakers',
    municipio: 'Municipality',
    nao: 'No',
    nenhumaMontadoraEncontradaBusca: 'No automaker found with search',
    nenhumFornecedorSelecionado: 'No supplier selected',
    nenhumItemAdicionado: 'No item added',
    nenhumResultadoEncontrado: 'No result found',
    nome: 'Name',
    nomeSistema: process.env.VUE_APP_NOME_SISTEMA || 'Catalog Lorene',
    novoPedido: 'New order',
    numero: 'Number',
    observacoes: 'Observations',
    opcional: 'Optional',
    ordenarPor: 'Sort by',
    outro: 'Other',
    outros: 'Others',
    padrao: 'Standard',
    pais: 'Country',
    peca: 'Part',
    pecaAdicionadaNoCarrinho: 'Part added to cart',
    pecaAdicionadaNoPedido: 'Part added to order',
    pecaCatalisador: 'Catalyst part',
    pecas: 'Parts',
    pecasCatalisadores: 'Catalyst parts',
    pedido: 'Order',
    pedidoAprovadoComSucesso: 'Order approved successfully',
    pedidoCanceladoComSucesso: 'Order canceled successfully',
    pedidoCriadoComSucesso: 'Order created successfully',
    pedidos: 'Orders',
    peso: 'Weight',
    pesoBruto: 'Gross weight',
    pesoDaCeramica: 'Ceramic weight',
    pesoDetalhado: 'Detailed weight',
    pesoLiquido: 'Net weight',
    pesoSeco: 'Dry weight',
    pessoa: 'Person',
    pessoaFisica: 'Natural person',
    pessoaJuridica: 'Legal person',
    poCatalisador: 'Catalyst powder',
    poCatalisadores: 'Catalyst powders',
    ppm: 'ppm',
    produto: 'Product',
    produtos: 'Products',
    prosseguirComPedido: 'Proceed with order',
    qtdeItens: 'Qty. items',
    quantidade: 'Quantity',
    razaoSocial: 'Corporate name',
    recursos: 'Resources',
    remover: 'Remove',
    responsavel: 'Responsible',
    resultado: 'Result',
    resultados: 'Results',
    resumo: 'Summary',
    sair: 'Exit',
    salvar: 'Save',
    selecionar: 'Select',
    semEndereco: 'No address',
    semFoto: 'No photo',
    senha: 'Password',
    seuCarrinhoContem1Item: 'Your cart contains 1 item',
    seuCarrinhoContemNItens: 'Your cart contains %{n} items',
    seuCarrinhoEstaVazio: 'Your cart is empty',
    sim: 'Yes',
    sobrenome: 'Last name',
    status: 'Status',
    suasObservacoes: 'Your observations',
    telefone: 'Phone',
    telefoneFixo: 'Landline',
    teores: 'Contents',
    teorPd: 'Pd content',
    teorPt: 'Pt content',
    teorRh: 'Rh content',
    total: 'Total',
    uf: 'State',
    ultimaAtualizacao: 'Last update',
    ultimaAtualizacaoEm: 'Last update on',
    umidade: 'Humidity',
    valor: 'Value',
    valores: 'Values',
    valorLiquido: 'Net value',
    valorMedio: 'Average value',
    valorTotal: 'Total value',
    verifiqueSeusDados: 'Check your data',
    verMenos: 'Show less',
    verTodas: 'Show all',
    visualizacao: 'Visualization',
    visualizarEm: 'View in',
    voltar: 'Back',
    voltarParaCarrinho: 'Back to cart',
    voltarParaCatalogo: 'Back to catalog',
  },
};

export default messages;
