





















































































































































































































































































import Vue from 'vue';

import { ArquivoKeyEnum } from '@/utils/constants/back.constants';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import CatalogoPecaDialogInfo from '../catalogo/CatalogoPecaDialogInfo.vue';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'PedidosInfoMeuCarrinho',
  components: {
    CatalogoPecaDialogInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fornecedor: {
      type: Object || null,
      default: null,
    },
    carrinho: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialogInfo: false,
    catalisadorPecaInfo: null,
    form: {
      observacoes: '',
    },
  }),
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
    quantidadeTotal() {
      return this.carrinho.reduce((acc, el) => acc + (el?.quantidade || 0), 0);
    },
    valorFinal() {
      return (
        this.carrinho?.reduce(
          (acc, el) =>
            acc +
            (el?.valor || el?.quantidade * el?.catalisadorPeca.valor || 0),
          0,
        ) || 0
      );
    },
  },
  methods: {
    maximumFractionDigitsCurrency,
    infoPeca(catalisadorPeca) {
      this.dialogInfo = true;
      this.catalisadorPecaInfo = catalisadorPeca;
    },
    getFotoArquivoSrc(catalisadorPeca, thumbnail = false) {
      const path = catalisadorPeca?.fotoArquivo?.path;
      if (!path) return null;
      return `${process.env.VUE_APP_URL_API}${path}/${
        thumbnail ? ArquivoKeyEnum.SM : ArquivoKeyEnum.WATERMARK
      }`;
    },
  },
});
