import { KatalisTranslationRules } from '../translations.interface';

const messages: KatalisTranslationRules = {
  rules: {
    campoObrigatorio: 'Campo obligatorio',
    confirmacaoSenha: 'Confirmación diferente de la contraseña ingresada',
    emailInvalido: 'Email inválido',
    inteiroPositivo: 'El valor debe ser un entero mayor o igual a cero',
    numericoPositivo: 'El valor debe ser un número mayor o igual a cero',
    telefoneInvalido: 'Número de teléfono inválido',
  },
};

export default messages;
