
















































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesEmail, rulesRequired } from '@/utils/input-rules';
import { FrontEnum } from '../../../../back/src/auth/auth.constants';

export default Vue.extend({
  name: 'AuthEsqueciMinhaSenha',
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      email: '',
    },
    alertText: '',
    rules: {
      email: [...rulesRequired, ...rulesEmail],
    },
  }),
  computed: {
    email(): string {
      return this.$route.query.email || '';
    },
  },
  mounted() {
    this.form.email = this.email;
  },
  methods: {
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading.submitForm = true;
      const formData = {
        email: this.form.email,
        front: FrontEnum.CATALOGO,
      };
      try {
        await requester.auth.esqueciMinhaSenha(formData);
        this.alertText = this.$t(
          'AuthEsqueciMinhaSenha.linkRenovacaoSenhaEnviado',
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
