import { KatalisTranslationEnums } from '../translations.interface';

const messages: KatalisTranslationEnums = {
  CategoriaPedidoItemEnum: {
    CATALISADORES_PECAS: 'Catalysts - Parts',
    CATALISADORES_PO: 'Catalysts - Powder',
  },
  StatusPedidoEnum: {
    AGUARDANDO_APROVACAO: 'Awaiting approval',
    APROVADO: 'Approved',
    CANCELADO: 'Canceled',
    APROVADO_COM_ALTERACOES: 'Approved with changes',
  },
};
export default messages;
