import Vue from 'vue';
import { DateTime } from 'luxon';

Vue.filter('formatDate', (value: string, filterFormat: string) => {
  if (value) {
    return DateTime.fromISO(value)
      .toLocal()
      .toFormat(filterFormat || 'dd/MM/yyyy');
  }
  return '';
});
