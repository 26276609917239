import { KatalisTranslationRules } from '../translations.interface';

const messages: KatalisTranslationRules = {
  rules: {
    campoObrigatorio: 'Mandatory field',
    confirmacaoSenha: 'Confirmation different from entered password',
    emailInvalido: 'Invalid email',
    inteiroPositivo: 'Value must be an integer greater than or equal to zero',
    numericoPositivo: 'Value must be a number greater than or equal to zero',
    telefoneInvalido: 'Invalid phone number',
  },
};

export default messages;
