





















import { findLastIndex } from 'lodash';
import Vue from 'vue';
export default Vue.extend({
  name: 'InputNumber',
  props: {
    value: {
      type: null || Number || String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: '',
    },
    separator: {
      type: String,
      default: ',',
    },
    mystyle: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formValue: '',
  }),
  watch: {
    value(novoValor) {
      this.formValue = String(novoValor ?? '')?.replace('.', this.separator);
    },
    formValue() {
      this.$emit(
        'input',
        Number(String(this.formValue || '')?.replace(this.separator, '.')),
      );
    },
  },
  mounted() {
    this.formValue = String(this.value ?? '')?.replace('.', this.separator);
  },
  methods: {
    onPaste(e) {
      this.onKeyUp(e.clipboardData.getData('text'));
    },
    onKeyUp(formPorcentagem) {
      const value = String(formPorcentagem || '');
      const preNumber = value.replace(/[^\d,.]/g, '');
      const index = findLastIndex(
        preNumber,
        (char: string) => char === '.' || char === this.separator,
      );
      const number = value.replace(/\D/g, '');
      const diff = preNumber.length - index;
      this.formValue =
        index < 0
          ? number
          : `${number.substring(
              0,
              number.length - diff + 1,
            )},${number.substring(number.length - diff + 1, number.length)}`;
    },
  },
});
