








































































































































































































































































































































































































































import Vue from 'vue';
import { debounce, deburr, orderBy, toLower } from 'lodash';

import requester from '@/requester';

import CatalisadoresCatalogoCard from '@/components/catalogo/CatalisadoresCatalogoCard.vue';
import CatalisadoresCatalogoCardList from '@/components/catalogo/CatalisadoresCatalogoCardList.vue';
import CatalogoPecaDialogInfo from '@/components/catalogo/CatalogoPecaDialogInfo.vue';
import CatalogoPecaFiltros from '@/components/catalogo/CatalogoPecaFiltros.vue';
import CatalisadoresPecasAutocomplete from '@/components/catalogo/CatalisadoresPecasAutocomplete.vue';
import CatalisadoresCatalogoDialogAviso from '@/components/catalogo/CatalisadoresCatalogoDialogAviso.vue';

export default Vue.extend({
  name: 'CatalogoCatalisadoresPecas',
  components: {
    CatalisadoresCatalogoCard,
    CatalisadoresCatalogoCardList,
    CatalogoPecaDialogInfo,
    CatalogoPecaFiltros,
    CatalisadoresPecasAutocomplete,
    CatalisadoresCatalogoDialogAviso,
  },
  data: () => ({
    loading: {
      catalogo: true,
      montadoras: true,
      catalisadoresPecasIdentificacao: true,
    },
    dialogAviso: true,
    dialogInfo: false,
    bottomSheetFiltros: false,
    catalisadorPecaInfo: null,
    viewList: false,
    page: 1,
    perPage: 12,
    perPageOptions: [12, 24, 48],
    search: '',
    searchTerm: '',
    filtros: false,
    filterMontadora: [] as number[],
    searchMontadora: null,
    verTodasMontadoras: false,
    total: 0,
    catalogo: [],
    montadoras: [],
    catalisadoresPecasIdentificacao: [],
    orderKey: 0,
    orderOptions: [
      {
        key: 0,
        value: 'codigo',
        text: 'comum.codigoPeca',
        desc: false,
        icon: 'mdi-sort-alphabetical-ascending',
      },
      {
        key: 1,
        value: 'valor',
        text: 'comum.menorValor',
        desc: false,
        icon: 'mdi-sort-numeric-ascending',
      },
      {
        key: 2,
        value: 'valor',
        text: 'comum.maiorValor',
        desc: true,
        icon: 'mdi-sort-numeric-descending',
      },
    ],
  }),
  computed: {
    usuarioFuncionario() {
      return this.$store.getters?.usuario?.categoria === 'FUNCIONARIO';
    },
    order() {
      return this.orderOptions.find((el) => el.key === this.orderKey);
    },
    totalStart() {
      const start = (this.page - 1) * this.perPage + 1;
      if (start > this.total) return this.total;
      return start;
    },
    totalEnd() {
      const end = this.page * this.perPage;
      if (end > this.total) return this.total;
      return end;
    },
    paginationLength() {
      return Math.ceil(this.total / this.perPage);
    },
    limitMontadoras() {
      return this.verTodasMontadoras || !!this.searchMontadora
        ? this.montadoras.length
        : this.filterMontadora.length > 4
        ? this.filterMontadora.length + 1
        : 5;
    },
    montadorasOrdenadas() {
      return orderBy(
        this.montadoras.filter((el) =>
          deburr(toLower(el.nome)).includes(
            deburr(toLower(this.searchMontadora)),
          ),
        ),
        (el) => (this.filterMontadora.includes(el.id) ? 0 : 1),
      );
    },
  },
  watch: {
    page() {
      this.buscaMeuCatalogo(false);
    },
    perPage() {
      this.buscaMeuCatalogo();
    },
  },
  mounted() {
    this.filtros = this.$vuetify.breakpoint.mdAndUp;
    this.listaIdentificacao();
    this.buscaMontadoras();
    this.buscaMeuCatalogo();
  },
  methods: {
    clearSearch() {
      this.search = '';
      this.searchTerm = '';
      this.buscaMeuCatalogo();
    },
    applySearch(search?: string) {
      if (search) {
        this.filterMontadora = [];
      }
      const trimmedSearch = search?.trim() || this.search?.trim() || '';
      if (this.searchTerm !== trimmedSearch) {
        this.searchTerm = trimmedSearch;
        this.loading.catalogo = true;
        this.buscaMeuCatalogo();
      }
    },
    openFiltros() {
      this.$refs?.catalogoPecaFiltros?.reset({
        orderKey: this.orderKey,
        perPage: this.perPage,
        viewList: this.viewList,
        filterMontadora: this.filterMontadora,
      });
      this.bottomSheetFiltros = true;
    },
    aplicaFiltros(filtros) {
      this.orderKey = filtros?.orderKey || 0;
      this.perPage = filtros?.perPage || 12;
      this.viewList = filtros?.viewList || false;
      this.$set(this, 'filterMontadora', filtros?.filterMontadora || []);
      this.buscaMeuCatalogo();
      this.bottomSheetFiltros = false;
    },
    infoPeca(catalisadorPeca) {
      this.dialogInfo = true;
      this.catalisadorPecaInfo = catalisadorPeca;
    },
    getNomeMontadora(montadoraId) {
      return this.montadoras.find((el) => el.id === montadoraId)?.nome || null;
    },
    onClickOrder(orderKey: number) {
      this.orderKey = orderKey;
      this.buscaMeuCatalogo();
    },
    limparFilterMontadora() {
      this.filterMontadora = [];
      this.loading.catalogo = true;
      this.buscaMeuCatalogoDebounce();
    },
    checkMontadora(montadoraId) {
      const index = this.filterMontadora.findIndex((el) => el === montadoraId);
      if (index >= 0) {
        this.$delete(this.filterMontadora, index);
      } else {
        this.filterMontadora.push(montadoraId);
      }
      this.searchMontadora = null;
      this.loading.catalogo = true;
      this.buscaMeuCatalogoDebounce();
    },
    async buscaMontadoras() {
      this.loading.montadoras = true;
      try {
        this.montadoras = await requester.montadoras.listar();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.montadoras = false;
      }
    },
    async listaIdentificacao() {
      this.loading.catalisadoresPecasIdentificacao = true;
      try {
        this.catalisadoresPecasIdentificacao =
          await requester.catalisadoresPecas.listaIdentificacao();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalisadoresPecasIdentificacao = false;
      }
    },
    buscaMeuCatalogoDebounce: debounce(function () {
      this.buscaMeuCatalogo();
    }, 500),
    async buscaMeuCatalogo(resetPage = true) {
      if (resetPage) this.page = 1;
      this.loading.catalogo = true;
      try {
        const queryDto = {
          offset: (this.page - 1) * this.perPage,
          limit: this.perPage,
          ...(this.order ? { orderBy: this.order.value } : {}),
          ...(this.order ? { orderDesc: this.order.desc } : {}),
          search: this.searchTerm,
          ...(this.filterMontadora.length > 0
            ? { montadorasIds: this.filterMontadora.map((el) => Number(el)) }
            : {}),
        };
        const result = await requester.catalisadoresPecas.meuCatalogo(queryDto);
        this.catalogo = result.rows;
        this.total = result.count;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.catalogo = false;
      }
    },
  },
});
