































































































import Vue from 'vue';

import { ArquivoKeyEnum } from '@/utils/constants/back.constants';

export default Vue.extend({
  name: 'CatalisadoresPecasGaleria',
  props: {
    fotosArquivos: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    window: 0,
  }),
  methods: {
    getFotoArquivoSrc(fotoArquivo, thumbnail = false) {
      return `${process.env.VUE_APP_URL_API}${fotoArquivo?.path}/${
        thumbnail ? ArquivoKeyEnum.THUMBNAIL : ArquivoKeyEnum.WATERMARK
      }`;
    },
  },
});
