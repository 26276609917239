import { KatalisTranslationComum } from '../translations.interface';

const messages: KatalisTranslationComum = {
  comum: {
    adicionar: 'Agregar',
    agencia: 'Agencia',
    aguardandoAprovacao: 'Esperando aprobación',
    alterar: 'Cambiar',
    analise: 'Análisis',
    aplicar: 'Aplicar',
    aprovado: 'Aprobado',
    aprovadoComAlteracoes: 'Aprobado con cambios',
    aprovarPedido: 'Aprobar pedido',
    aviso: 'Aviso',
    bairro: 'Barrio',
    banco: 'Banco',
    busca: 'Búsqueda',
    buscar: 'Buscar',
    buscarFornecedor: 'Buscar proveedor',
    buscarMontadora: 'Buscar fabricante de automóviles',
    cadastrarFornecedor: 'Registrar proveedor',
    calculadora: 'Calculadora',
    calculadoraPo: 'Calculadora - Polvo',
    calculadoraPoCatalisadores: 'Calculadora - Polvo de catalizadores',
    calcular: 'Calcular',
    cancelado: 'Cancelado',
    cancelar: 'Cancelar',
    cancelarPedido: 'Cancelar pedido',
    carrinho: 'Carrito',
    catalisadoresPecas: 'Catalizadores - Piezas',
    catalogo: 'Catálogo',
    catalogoPecas: 'Catálogo - Piezas',
    categoria: 'Categoría',
    celular: 'Celular',
    cep: 'CEP',
    cepNaoEncontrado: 'CEP no encontrado',
    certezaAprovarPedido: '¿Estás seguro de que quieres aprobar este pedido?',
    certezaCancelarPedido: '¿Estás seguro de que quieres cancelar este pedido?',
    classe: 'Clase',
    cnpj: 'CNPJ',
    codigo: 'Código',
    codigoPeca: 'Código de pieza',
    comecoAFimDeTotal: '%{comeco} a %{fim} de %{total}',
    complemento: 'Complemento',
    confirmacaoSenha: 'Confirmación de contraseña',
    confirmar: 'Confirmar',
    confirmarPedido: 'Confirmar pedido',
    conta: 'Cuenta',
    cpf: 'CPF',
    criadoEm: 'Creado en',
    criadoPor: 'Creado por',
    dadosBancarios: 'Datos bancarios',
    dadosFornecedorNaoEncontrados: 'Datos del proveedor no encontrados',
    dadosGerais: 'Datos generales',
    data: 'Fecha',
    descricaoCatalogo: 'Descripción',
    detalhe: 'Detalle',
    detalhes: 'Detalles',
    documento: 'Documento',
    email: 'Email',
    emConstrucao: 'En construcción',
    endereco: 'Dirección',
    enderecoNaoCadastrado: 'Dirección no registrada',
    entrar: 'Iniciar sesión',
    enviar: 'Enviar',
    estouCiente: 'Estoy al tanto',
    familia: 'Familia',
    fechar: 'Cerrar',
    filtro: 'Filtro',
    filtros: 'Filtros',
    fisica: 'Física',
    fornecedor: 'Proveedor',
    fornecedorAlteradoComSucesso: 'Proveedor cambiado con éxito',
    fornecedorCadastradoComSucesso: 'Proveedor registrado con éxito',
    fornecedores: 'Proveedores',
    foto: 'Foto',
    fotos: 'Fotos',
    grade: 'Grado',
    historico: 'Histórico',
    home: 'Página de inicio',
    identificacao: 'Identificación',
    impurezas: 'Impurezas',
    inconsistente: 'Inconsistente',
    indefinida: 'Indefinida',
    informacao: 'Información',
    informacoes: 'Informaciones',
    item: 'Ítem',
    itens: 'Ítems',
    itensPorPagina: 'Ítems por página',
    juridica: 'Jurídica',
    justificativa: 'Justificación',
    justificativaCancelamento: 'Justificación de cancelación',
    kg: 'kg',
    limpar: 'Limpiar',
    limparBusca: 'Limpiar búsqueda',
    limparCarrinho: 'Limpiar carrito',
    limparMontadoras: 'Limpiar fabricantes de automóviles',
    lista: 'Lista',
    logoutRealizadoComSucesso: 'Cierre de sesión realizado con éxito',
    logradouro: 'Calle',
    loja: 'Tienda',
    lojas: 'Tiendas',
    maiorValor: 'Mayor valor',
    maisDetalhes: 'Más detalles',
    margem: 'Margen',
    margemMinima: 'Margen mínimo',
    menorValor: 'Menor valor',
    menosDetalhes: 'Menos detalles',
    meusDados: 'Mis datos',
    meusPedidos: 'Mis pedidos',
    minima: 'Mínima',
    modelo: 'Modelo',
    montadora: 'Fabricante de automóviles',
    montadoras: 'Fabricantes de automóviles',
    municipio: 'Municipio',
    nao: 'No',
    nenhumaMontadoraEncontradaBusca:
      'Ningún fabricante de automóviles encontrado en la búsqueda',
    nenhumFornecedorSelecionado: 'Ningún proveedor seleccionado',
    nenhumItemAdicionado: 'Ningún ítem agregado',
    nenhumResultadoEncontrado: 'Ningún resultado encontrado',
    nome: 'Nombre',
    nomeSistema: process.env.VUE_APP_NOME_SISTEMA || 'Catálogo Lorene',
    novoPedido: 'Nuevo pedido',
    numero: 'Número',
    observacoes: 'Observaciones',
    opcional: 'Opcional',
    ordenarPor: 'Ordenar por',
    outro: 'Otro',
    outros: 'Otros',
    padrao: 'Estándar',
    pais: 'País',
    peca: 'Pieza',
    pecaAdicionadaNoCarrinho: 'Pieza añadida al carrito',
    pecaAdicionadaNoPedido: 'Pieza añadida al pedido',
    pecaCatalisador: 'Pieza de catalizador',
    pecas: 'Piezas',
    pecasCatalisadores: 'Piezas de catalizadores',
    pedido: 'Pedido',
    pedidoAprovadoComSucesso: 'Pedido aprobado con éxito',
    pedidoCanceladoComSucesso: 'Pedido cancelado con éxito',
    pedidoCriadoComSucesso: 'Pedido creado con éxito',
    pedidos: 'Pedidos',
    peso: 'Peso',
    pesoBruto: 'Peso bruto',
    pesoDaCeramica: 'Peso de la cerámica',
    pesoDetalhado: 'Peso detallado',
    pesoLiquido: 'Peso líquido',
    pesoSeco: 'Peso seco',
    pessoa: 'Persona',
    pessoaFisica: 'Persona física',
    pessoaJuridica: 'Persona jurídica',
    poCatalisador: 'Polvo de catalizador',
    poCatalisadores: 'Polvo de catalizadores',
    ppm: 'ppm',
    produto: 'Producto',
    produtos: 'Productos',
    prosseguirComPedido: 'Continuar con el pedido',
    qtdeItens: 'Cant. ítems',
    quantidade: 'Cantidad',
    razaoSocial: 'Razón social',
    recursos: 'Recursos',
    remover: 'Eliminar',
    responsavel: 'Responsable',
    resultado: 'Resultado',
    resultados: 'Resultados',
    resumo: 'Resumen',
    sair: 'Salir',
    salvar: 'Guardar',
    selecionar: 'Seleccionar',
    semEndereco: 'Sin dirección',
    semFoto: 'Sin foto',
    senha: 'Contraseña',
    seuCarrinhoContem1Item: 'Tu carrito contiene 1 ítem',
    seuCarrinhoContemNItens: 'Tu carrito contiene %{n} ítems',
    seuCarrinhoEstaVazio: 'Tu carrito está vacío',
    sim: 'Sí',
    sobrenome: 'Apellido',
    status: 'Estado',
    suasObservacoes: 'Tus observaciones',
    telefone: 'Teléfono',
    telefoneFixo: 'Teléfono fijo',
    teores: 'Contenidos',
    teorPd: 'Contenido Pd',
    teorPt: 'Contenido Pt',
    teorRh: 'Contenido Rh',
    total: 'Total',
    uf: 'UF',
    ultimaAtualizacao: 'Última actualización',
    ultimaAtualizacaoEm: 'Última actualización en',
    umidade: 'Humedad',
    valor: 'Valor',
    valores: 'Valores',
    valorLiquido: 'Valor líquido',
    valorMedio: 'Valor medio',
    valorTotal: 'Valor total',
    verifiqueSeusDados: 'Verifica tus datos',
    verMenos: 'Ver menos',
    verTodas: 'Ver todas',
    visualizacao: 'Visualización',
    visualizarEm: 'Visualizar en',
    voltar: 'Volver',
    voltarParaCarrinho: 'Volver al carrito',
    voltarParaCatalogo: 'Volver al catálogo',
  },
};

export default messages;
