










































































































import Vue from 'vue';

import { CategoriaUsuarioEnum } from '@/utils/constants/back.constants';
import { CategoriaPedidoItemEnum } from '../../../back/src/pedidos/pedidos.constants';

interface Menu {
  title: string;
  icon: string;
  to: { name: string };
  children?: Menu[];
}

export default Vue.extend({
  name: 'TheNavigationDrawer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    showLinksSistemas() {
      return this.usuarioCategoria === CategoriaUsuarioEnum.FUNCIONARIO;
    },
    urlPortal() {
      return process.env.VUE_APP_URL_PORTAL;
    },
    urlLims() {
      return process.env.VUE_APP_URL_LIMS;
    },
    showLims() {
      return process.env.VUE_APP_SHOW_LIMS === 'true';
    },
    usuario() {
      return this.$store.getters?.usuario || null;
    },
    usuarioCategoria() {
      return this.usuario?.categoria || null;
    },
    lojas() {
      return this.$store.getters?.lojas || [];
    },
    categoriasMargens() {
      return this.$store.getters?.categoriasMargens || [];
    },
    menu(): Menu[] {
      return this.usuario ? this.menuLoggedIn : this.menuLoggedOut;
    },
    menuLoggedIn() {
      return [
        {
          title: this.$t('comum.home'),
          icon: 'mdi-home',
          to: { name: 'Home' },
        },
        ...this.lojas.map((loja) => ({
          title: loja.identificacao,
          icon: 'mdi-store',
          children: [
            {
              title: this.$t('comum.pedidos'),
              icon: 'mdi-clipboard-text',
              to: {
                name: 'LojasPedidosIndex',
                params: { lojaId: loja.id },
              },
            },
            {
              title: this.$t('comum.fornecedores'),
              icon: 'mdi-account',
              to: {
                name: 'LojasFornecedoresIndex',
                params: { lojaId: loja.id },
              },
            },
            {
              title: this.$t('comum.calculadora'),
              icon: 'mdi-calculator',
              to: {
                name: 'LojasCalculadora',
                params: { lojaId: loja.id },
              },
            },
            {
              title: this.$t('comum.catalogo'),
              icon: 'mdi-magnify',
              to: {
                name: 'LojasCatalogo',
                params: { lojaId: loja.id },
              },
            },
          ],
        })),
        ...(this.categoriasMargens.length > 0
          ? [
              {
                title: this.$t('comum.recursos'),
                icon: 'mdi-view-list',
                children: [
                  ...(this.categoriasMargens.includes(
                    CategoriaPedidoItemEnum.CATALISADORES_PO,
                  )
                    ? [
                        {
                          title: this.$t('comum.calculadora'),
                          icon: 'mdi-calculator',
                          to: { name: 'CalculadoraCatalisadorPo' },
                        },
                      ]
                    : []),
                  ...(this.categoriasMargens.includes(
                    CategoriaPedidoItemEnum.CATALISADORES_PECAS,
                  )
                    ? [
                        {
                          title: this.$t('comum.catalogo'),
                          icon: 'mdi-magnify',
                          to: { name: 'CatalogoCatalisadoresPecas' },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),
        ...(this.usuarioCategoria === CategoriaUsuarioEnum.PARCEIRO
          ? [
              {
                title: this.$t('comum.pedidos'),
                icon: 'mdi-clipboard-text',
                to: { name: 'PedidosMeusPedidos' },
              },
              {
                title: this.$t('comum.carrinho'),
                icon: 'mdi-cart',
                to: { name: 'PedidosCarrinho' },
              },
            ]
          : []),
      ];
    },
    menuLoggedOut() {
      return [
        {
          title: this.$t('comum.entrar'),
          icon: 'mdi-login',
          to: { name: 'AuthLogin' },
        },
      ];
    },
  },
  methods: {
    showItem(routeName: string) {
      const route = this.$router.options.routes.find(
        (el) => el.name === routeName,
      );
      const rules = route?.meta?.rules || [];
      return rules.every((rule) =>
        this.$ability.can(rule?.action, rule?.subject),
      );
    },
  },
});
