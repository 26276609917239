



















import Vue from 'vue';

import { ItemCalculadoraCatalisadorPo } from '@/utils/helper-types';

import { Familia } from '../../../../back/src/models/familia.model';

import CustomDialog from '../CustomDialog.vue';
import LojasCalculadoraForm from './LojasCalculadoraForm.vue';

export default Vue.extend({
  name: 'LojasCalculadoraDialog',
  components: {
    LojasCalculadoraForm,
    CustomDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: null || (Object as () => ItemCalculadoraCatalisadorPo),
      default: null,
    },
    familias: {
      type: Array as () => Familia[],
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {
    itemKey() {
      return this.item?.key || 0;
    },
  },
  methods: {
    onInput(evt: boolean) {
      this.$emit('input', evt);
    },
    onConfirmar(item: ItemCalculadoraCatalisadorPo) {
      this.$emit('upsert-item', item);
    },
  },
});
