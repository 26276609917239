import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import config from '../config/locales';

Vue.use(VueI18n);

const { locale, fallbackLocale, availableLocales } = config;

const messages: LocaleMessages = {};
availableLocales.forEach((el) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages[el.code] = el.messages as any;
});

export default new VueI18n({
  locale,
  fallbackLocale,
  messages,
  silentTranslationWarn: true,
});
