









































































































































































































































































































































































































import Vue from 'vue';

import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';

import PedidosDialogCancelar from './PedidosDialogCancelar.vue';
import { last } from 'lodash';
import { PedidoLog } from '../../../../back/src/models/pedido-log.model';
import { StatusPedidoEnum } from '../../../../back/src/pedidos/pedidos.constants';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';

export default Vue.extend({
  name: 'PedidosDialogInfoMeuPedido',
  components: {
    PedidosDialogCancelar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    pedido: {
      type: Object || null,
      default: null,
    },
  },
  data: () => ({
    dialogCancelar: false,
    showDadosGerais: false,
    showProdutos: true,
  }),
  watch: {
    value(val) {
      if (val) {
        this.$router.push('#info');
      }
    },
    '$route.hash'(newHash, oldHash) {
      if (oldHash === '#info') {
        this.$emit('input', false);
      }
    },
  },
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
    observacoesColor() {
      switch (this.logAtual?.status) {
        case StatusPedidoEnum.APROVADO:
          return 'success';
        case StatusPedidoEnum.CANCELADO:
          return 'error';
        case StatusPedidoEnum.APROVADO_COM_ALTERACOES:
          return 'error';
        default:
          return 'info';
      }
    },
    observacoesLabel() {
      switch (this.logAtual?.status) {
        case StatusPedidoEnum.CANCELADO:
          return 'justificativaCancelamento';
        case StatusPedidoEnum.AGUARDANDO_APROVACAO:
          return 'suasObservacoes';
        default:
          return 'observacoes';
      }
    },
    showCancelar() {
      return this.logAtual?.status === StatusPedidoEnum.AGUARDANDO_APROVACAO;
    },
    showUltimaAtualizacao() {
      return this.pedido?.log?.length > 1;
    },
    logAtual(): PedidoLog {
      return last(this.pedido?.log);
    },
    quantidadeTotal() {
      return (
        this.pedido?.pedidoItems?.reduce(
          (acc, el) => acc + el?.pedidoItemPeca?.quantidade || 0,
          0,
        ) || 0
      );
    },
    valorFinal() {
      return (
        this.pedido?.pedidoItems?.reduce(
          (acc, el) => acc + el?.valor || 0,
          0,
        ) || 0
      );
    },
  },
  methods: {
    maximumFractionDigitsCurrency,
    onInput(event: boolean) {
      if (!event) {
        this.$router.back();
      }
      this.$emit('input', event);
    },
    pedidoCancelado() {
      this.$emit('pedido-cancelado');
      this.dialogCancelar = false;
    },
  },
});
