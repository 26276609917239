import { KatalisTranslationComponents } from '../translations.interface';

const messages: KatalisTranslationComponents = {
  CalculadoraCatalisadorPo: {
    avisoErro: 'Verifique se preencheu todos os campos corretamente',
  },
  CatalisadoresCatalogoDialogAviso: {
    aviso1:
      'Os valores das cotações são atualizados toda segunda-feira. Atente-se à data para fechar o seu pedido.',
    aviso2: 'Cuidado com as peças e códigos adulterados.',
    aviso3: 'Sempre consulte as imagens das peças no catálogo!',
  },
  PedidosFormItemCatalisadorPo: {
    calculadoraPoCatalisadores: 'Calculadora - Pó de catalisadores',
  },
  PedidosInfoMeuCarrinho: {
    avisoVerifiqueSeusDados:
      'Caso encontre alguma inconsistência, entre em contato com a Lorene antes de realizar o pedido.',
  },
};

export default messages;
