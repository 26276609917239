import { KatalisTranslationRoutes } from '../translations.interface';

const messages: KatalisTranslationRoutes = {
  routes: {
    // auth
    AuthAtivarConta: 'Activate account',
    AuthEsqueciMinhaSenha: 'Forgot my password',
    AuthLogin: 'Log in to the system',
    AuthRenovarSenha: 'Renew password',
    // calculadora
    CalculadoraCatalisadorPo: 'Calculator - Catalyst Powder',
    // catalogo
    CatalogoCatalisadoresPecas: 'Catalysts - Parts',
    // erro
    ErroNaoEncontrado: 'Not found',
    // home
    Home: 'Home page',
    // lojas
    LojasCalculadora: 'Calculator',
    LojasCatalogo: 'Catalog',
    LojasFornecedoresIndex: 'Suppliers',
    LojasPedidosIndex: 'Orders',
    LojasPedidosNovo: 'New order',
    // pedidos
    PedidosCarrinho: 'Shopping cart',
    PedidosConfirmacao: 'Confirm order',
    PedidosMeusPedidos: 'My orders',
  },
};

export default messages;
