


















































































import Vue from 'vue';
import { groupBy, map, orderBy } from 'lodash';

import { PedidoItem } from '../../../../back/src/models/pedido-item.model';

import TextOrNA from '../TextOrNA.vue';

interface Item {
  familiaId: number;
  familiaCodigo: string;
  familiaDescricao: string;
  quantidade: number;
  margemComposta: number;
  pesoSeco: number;
  valorLiquido: number;
  valorMedio: number;
}

export default Vue.extend({
  name: 'PedidosTabelaValores',
  components: { TextOrNA },
  props: {
    items: {
      type: Array as () => PedidoItem[],
      default: [] as PedidoItem[],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pesoDetalhado: false,
  }),
  computed: {
    currency() {
      return process.env.VUE_APP_PAIS_CURRENCY || 'BRL';
    },
    thClass() {
      return 'text-no-wrap';
    },
    tdClass() {
      return 'text-no-wrap';
    },
    quantidadeTotal() {
      return this.itemsMapped?.reduce(
        (acc, el) => acc + el?.quantidade || 0,
        0,
      );
    },
    pesoTotal() {
      return this.itemsMapped?.reduce((acc, el) => acc + el?.pesoSeco || 0, 0);
    },
    /**
     * Devolve a média das margens por peso.
     */
    porcentageMediaPorPeso() {
      return (
        this.itemsMapped.reduce((acc, el) => {
          return acc + el?.pesoSeco * el.margemComposta;
        }, 0) / this.pesoTotal
      );
    },
    valorTotal() {
      return this.itemsMapped?.reduce(
        (acc, el) => acc + el?.valorLiquido || 0,
        0,
      );
    },
    itemsMapped(): Item[] {
      const itemsGrouped = groupBy(this.items as PedidoItem[], (pedidoItem) => {
        if (pedidoItem.pedidoItemPeca) {
          return pedidoItem.pedidoItemPeca?.catalisadorPeca?.familiaId;
        }
        if (pedidoItem.pedidoItemPo) {
          return pedidoItem.pedidoItemPo?.familiaId;
        }
        throw new Error(`Item ${pedidoItem} não é peça nem pó.`);
      });
      return orderBy(
        map(itemsGrouped, (itemsFamilia) => {
          const pesoSeco = itemsFamilia.reduce((acc, el) => {
            if (el?.pedidoItemPo) {
              return acc + (el.pedidoItemPo?.pesoSeco || 0);
            } else if (el?.pedidoItemPeca) {
              return (
                acc +
                (el.pedidoItemPeca?.catalisadorPecaDados?.pesoKg || 0) *
                  (el.pedidoItemPeca?.quantidade || 0)
              );
            }
            return acc;
          }, 0);
          const valorLiquido = itemsFamilia.reduce(
            (acc, el) => acc + el?.valor || 0,
            0,
          );
          const valorMedio = pesoSeco > 0 ? valorLiquido / pesoSeco : 0;
          let familia;
          if (itemsFamilia?.[0].pedidoItemPeca) {
            familia = itemsFamilia[0]?.pedidoItemPeca?.catalisadorPeca?.familia;
          } else {
            familia = itemsFamilia[0]?.pedidoItemPo?.familia;
          }
          return {
            familiaId: familia.id,
            familiaCodigo: familia.codigo,
            familiaDescricao: familia.descricao,
            quantidade: itemsFamilia.length,
            margemComposta: itemsFamilia?.[0]?.margemComposta,
            pesoSeco,
            valorLiquido,
            valorMedio,
          };
        }),
        ['familiaCodigo'],
      );
    },
  },
});
