












































































































import Vue from 'vue';

import { version } from '../../package.json';
import { CategoriaUsuarioEnum } from '@/utils/constants/back.constants';
import { getPaisEmojiFlag } from '@/utils/pais-helper';

import TheNavigationDrawer from '@/components/TheNavigationDrawer.vue';

export default Vue.extend({
  name: 'LayoutDefault',
  components: {
    TheNavigationDrawer,
  },
  data: () => ({
    drawer: false,
    bottom: 0,
    version,
    CategoriaUsuarioEnum,
  }),
  computed: {
    usuario() {
      return this.$store.getters?.usuario || null;
    },
    usuarioCategoria() {
      return this.usuario?.categoria || null;
    },
    carrinhoLength() {
      return this.$store.getters?.carrinho?.length || 0;
    },
    containerFluid() {
      return (
        this.$route?.meta?.containerFluid &&
        this.$vuetify.breakpoint.mdAndUp &&
        this.$vuetify.breakpoint.lgAndDown
      );
    },
    paisEmojiFlag() {
      return getPaisEmojiFlag();
    },
  },
});
