








































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';
import { rulesRequired } from '@/utils/input-rules';

import { CancelaPedidoLojaDto } from '../../../../back/src/lojas/dto/cancela-pedido-loja.dto';

import CustomDialog from '../CustomDialog.vue';

export default Vue.extend({
  name: 'LojasPedidoDialogCancelar',
  components: {
    CustomDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number,
      required: true,
    },
    pedidoId: {
      type: Number || null,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      justificativaCancelamento: null,
    },
    rules: {
      required: rulesRequired,
    },
  }),
  methods: {
    onInput(event: boolean) {
      if (!event) {
        this.$refs?.form?.reset();
      }
      this.$emit('input', event);
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.$refs?.form?.validate()) return;
      this.loading.submitForm = true;
      try {
        const formData: CancelaPedidoLojaDto = {
          observacoes: this.form.justificativaCancelamento?.trim() || null,
        };
        const pedido = await requester.lojas.cancelaPedido(
          this.lojaId,
          this.pedidoId,
          formData,
        );
        this.$emit('pedido-cancelado', pedido);
        this.$root.$emit(
          'toastSuccess',
          this.$t('comum.pedidoCanceladoComSucesso'),
        );
        this.$refs?.form?.reset();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
