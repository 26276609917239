





















































































































































































































import Vue from 'vue';

import {
  rulesBoolean,
  rulesCnpj,
  rulesCpf,
  rulesEmail,
  rulesIntegerPositiveNonZero,
  rulesRequired,
} from '@/utils/input-rules';
import InputTelefone from '../inputs/InputTelefone.vue';
import EnderecosFormBrasil from '../enderecos/EnderecosFormBrasil.vue';
import { PessoaFornecedorEnum } from '../../../../back/src/fornecedores/fornecedores.constants';
import {
  CreateFornecedorDto,
  CreateEnderecoDto,
} from '../../../../back/src/fornecedores/dto/create-fornecedor.dto';

import { debounce, orderBy } from 'lodash';
import { autocompleteFilterDeburr } from '@/utils/filter-functions';
import requester from '@/requester';
import { Fornecedor } from '../../../../back/src/models/fornecedor.model';

export default Vue.extend({
  name: 'FornecedoresFormCriarAlterar',
  components: {
    InputTelefone,
    EnderecosFormBrasil,
  },
  props: {
    fornecedor: {
      type: (Object as () => Fornecedor) || null,
      default: null,
    },
    lojaId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: {
      bancos: true,
      submitForm: false,
    },
    semEndereco: false,
    bancoOutro: false,
    form: {
      pessoa: PessoaFornecedorEnum.FISICA,
      cpf: null,
      cnpj: null,
      nome: null,
      sobrenome: null,
      razaoSocial: null,
      email: null,
      celular: null,
      telefone: null,
      bancoCodigo: null,
      bancoNome: null,
      bancoAgencia: null,
      bancoConta: null,
      endereco: null,
    },
    rules: {
      required: rulesRequired,
      email: [...rulesRequired, ...rulesEmail],
      cpf: [...rulesRequired, ...rulesCpf],
      cnpj: [...rulesRequired, ...rulesCnpj],
      integerPositiveNonZero: rulesIntegerPositiveNonZero,
      boolean: rulesBoolean,
    },
    bancos: [],
    familias: [],
    itemsFamilias: [],
    PessoaFornecedorEnum,
  }),
  computed: {
    itemsBancos() {
      return orderBy(
        this.bancos
          .filter((el) => el.code)
          .map((el) => ({
            value: el.code,
            text: `${el.code} - ${el.name}`,
          })),
        ['value'],
      );
    },
  },
  async mounted() {
    await this.listaBancos();
    this.preencheForm();
  },
  methods: {
    autocompleteFilterDeburr,
    preencheForm() {
      if (!this.fornecedor) return;
      this.semEndereco = false;
      this.$refs?.formDadosGerais?.resetValidation();
      this.$refs?.formDadosBancarios?.resetValidation();
      this.bancoOutro = !this.fornecedor.bancoCodigo;
      this.form.pessoa = this.fornecedor.pessoa;
      this.form.cpf =
        this.fornecedor.pessoa === PessoaFornecedorEnum.FISICA
          ? this.fornecedor.documento
          : null;
      this.form.cnpj =
        this.fornecedor.pessoa === PessoaFornecedorEnum.JURIDICA
          ? this.fornecedor.documento
          : null;
      this.form.nome = this.fornecedor.nome;
      this.form.sobrenome = this.fornecedor.sobrenome;
      this.form.razaoSocial = this.fornecedor.razaoSocial;
      this.form.email = this.fornecedor.email;
      this.form.celular = this.fornecedor.celular;
      this.form.telefone = this.fornecedor.telefone;
      this.form.bancoCodigo = this.fornecedor.bancoCodigo;
      this.form.bancoNome = this.fornecedor.bancoNome;
      this.form.bancoAgencia = this.fornecedor.bancoAgencia;
      this.form.bancoConta = this.fornecedor.bancoConta;
      this.form.endereco = this.fornecedor.endereco;
      this.$nextTick(() => {
        this.$refs?.enderecosFormBrasil?.preencheForm();
      });
    },
    limpaForm() {
      this.semEndereco = false;
      this.bancoOutro = false;
      this.form.pessoa = PessoaFornecedorEnum.FISICA;
      this.form.cpf = null;
      this.form.cnpj = null;
      this.form.nome = null;
      this.form.sobrenome = null;
      this.form.razaoSocial = null;
      this.form.email = null;
      this.form.celular = null;
      this.form.telefone = null;
      this.form.bancoCodigo = null;
      this.form.bancoNome = null;
      this.form.bancoAgencia = null;
      this.form.bancoConta = null;
      this.form.endereco = null;
      this.$refs?.enderecosFormBrasil?.limpaForm();
      this.$refs?.formDadosGerais?.resetValidation();
      this.$refs?.formDadosBancarios?.resetValidation();
    },
    cancelar() {
      this.$emit('cancelar');
      this.limpaForm();
    },
    async listaBancos() {
      this.loading.bancos = true;
      try {
        this.bancos = await requester.externo.brasilApi.bancos();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.bancos = false;
      }
    },
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      const validFormDadosGerais = this.$refs?.formDadosGerais?.validate();
      const validFormDadosBancarios =
        this.$refs?.formDadosBancarios?.validate();
      const validFormEndereco = this.semEndereco
        ? true
        : this.$refs?.enderecosFormBrasil?.validate();
      if (
        !validFormDadosGerais ||
        !validFormDadosBancarios ||
        !validFormEndereco
      ) {
        return;
      }
      try {
        const formDataEndereco: CreateEnderecoDto = this.semEndereco
          ? null
          : this.$refs?.enderecosFormBrasil?.getFormData();
        this.loading.submitForm = true;
        const formData: CreateFornecedorDto = {
          pessoa: this.form.pessoa,
          nome:
            this.form.pessoa === PessoaFornecedorEnum.FISICA
              ? this.form.nome?.trim() || null
              : null,
          sobrenome:
            this.form.pessoa === PessoaFornecedorEnum.FISICA
              ? this.form.sobrenome?.trim() || null
              : null,
          razaoSocial:
            this.form.pessoa === PessoaFornecedorEnum.JURIDICA
              ? this.form.razaoSocial?.trim() || null
              : null,
          documento:
            this.form.pessoa === PessoaFornecedorEnum.FISICA
              ? this.form.cpf?.trim() || null
              : this.form.cnpj?.trim() || null,
          email: this.form.email?.trim() || null,
          celular: this.form.celular?.trim() || null,
          telefone: this.form.telefone?.trim() || null,
          bancoCodigo: this.form?.bancoCodigo || null,
          bancoNome: this.form?.bancoCodigo
            ? this.bancos
                ?.find((el) => el.code === this.form?.bancoCodigo)
                ?.name?.trim() || null
            : this.form?.bancoNome?.trim() || null,
          bancoAgencia: this.form?.bancoAgencia?.trim() || null,
          bancoConta: this.form?.bancoConta?.trim() || null,
          endereco: formDataEndereco,
        };
        let fornecedor: Fornecedor;
        if (!this.fornecedor) {
          fornecedor = await requester.lojas.criaFornecedor(
            this.lojaId,
            formData,
          );
          this.$emit('fornecedor-criado', fornecedor);
          this.$root.$emit(
            'toastSuccess',
            this.$t('comum.fornecedorCadastradoComSucesso'),
          );
          this.limpaForm();
        } else {
          fornecedor = await requester.lojas.alteraFornecedor(
            this.lojaId,
            this.fornecedor.id,
            formData,
          );
          this.$emit('fornecedor-alterado', fornecedor);
          this.$root.$emit(
            'toastSuccess',
            this.$t('comum.fornecedorAlteradoComSucesso'),
          );
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
