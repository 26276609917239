import { maxBy } from 'lodash';

const hashMD5 = (string) => {
  function rotateLeft(lValue, iShiftBits) {
    return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
  }

  function addUnsigned(lX, lY) {
    const lX8 = lX & 0x80000000;
    const lY8 = lY & 0x80000000;
    const lX4 = lX & 0x40000000;
    const lY4 = lY & 0x40000000;
    const lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);

    if (lX4 & lY4) {
      return lResult ^ 0x80000000 ^ lX8 ^ lY8;
    }
    if (lX4 | lY4) {
      if (lResult & 0x40000000) {
        return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
      } else {
        return lResult ^ 0x40000000 ^ lX8 ^ lY8;
      }
    } else {
      return lResult ^ lX8 ^ lY8;
    }
  }

  function gF(x, y, z) {
    return (x & y) | (~x & z);
  }

  function gG(x, y, z) {
    return (x & z) | (y & ~z);
  }

  function gH(x, y, z) {
    return x ^ y ^ z;
  }

  function gI(x, y, z) {
    return y ^ (x | ~z);
  }

  function gFF(a, b, c, d, x, s, ac) {
    a = addUnsigned(a, addUnsigned(addUnsigned(gF(b, c, d), x), ac));
    return addUnsigned(rotateLeft(a, s), b);
  }

  function gGG(a, b, c, d, x, s, ac) {
    a = addUnsigned(a, addUnsigned(addUnsigned(gG(b, c, d), x), ac));
    return addUnsigned(rotateLeft(a, s), b);
  }

  function gHH(a, b, c, d, x, s, ac) {
    a = addUnsigned(a, addUnsigned(addUnsigned(gH(b, c, d), x), ac));
    return addUnsigned(rotateLeft(a, s), b);
  }

  function gII(a, b, c, d, x, s, ac) {
    a = addUnsigned(a, addUnsigned(addUnsigned(gI(b, c, d), x), ac));
    return addUnsigned(rotateLeft(a, s), b);
  }

  function convertToWordArray(string) {
    let lWordCount;
    const lMessageLength = string.length;
    const lNumberOfWordsTemp1 = lMessageLength + 8;
    const lNumberOfWordsTemp2 =
      (lNumberOfWordsTemp1 - (lNumberOfWordsTemp1 % 64)) / 64;
    const lNumberOfWords = (lNumberOfWordsTemp2 + 1) * 16;
    const lWordArray = new Array(lNumberOfWords - 1);
    let lBytePosition = 0;
    let lByteCount = 0;

    while (lByteCount < lMessageLength) {
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] =
        lWordArray[lWordCount] |
        (string.charCodeAt(lByteCount) << lBytePosition);
      lByteCount++;
    }

    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
    lBytePosition = (lByteCount % 4) * 8;
    lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
    lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
    lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
    return lWordArray;
  }

  function wordToHex(lValue) {
    let wordToHexValue = '',
      wordToHexValueTemp = '',
      lByte,
      lCount;
    for (lCount = 0; lCount <= 3; lCount++) {
      lByte = (lValue >>> (lCount * 8)) & 255;
      wordToHexValueTemp = '0' + lByte.toString(16);
      wordToHexValue =
        wordToHexValue +
        wordToHexValueTemp.substr(wordToHexValueTemp.length - 2, 2);
    }
    return wordToHexValue;
  }

  function utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  }

  let x = [];
  let k, AA, BB, CC, DD, a, b, c, d;
  const S11 = 7,
    S12 = 12,
    S13 = 17,
    S14 = 22;
  const S21 = 5,
    S22 = 9,
    S23 = 14,
    S24 = 20;
  const S31 = 4,
    S32 = 11,
    S33 = 16,
    S34 = 23;
  const S41 = 6,
    S42 = 10,
    S43 = 15,
    S44 = 21;
  string = utf8Encode(string);
  x = convertToWordArray(string);
  a = 0x67452301;
  b = 0xefcdab89;
  c = 0x98badcfe;
  d = 0x10325476;

  for (k = 0; k < x.length; k += 16) {
    AA = a;
    BB = b;
    CC = c;
    DD = d;
    a = gFF(a, b, c, d, x[k + 0], S11, 0xd76aa478);
    d = gFF(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
    c = gFF(c, d, a, b, x[k + 2], S13, 0x242070db);
    b = gFF(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
    a = gFF(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
    d = gFF(d, a, b, c, x[k + 5], S12, 0x4787c62a);
    c = gFF(c, d, a, b, x[k + 6], S13, 0xa8304613);
    b = gFF(b, c, d, a, x[k + 7], S14, 0xfd469501);
    a = gFF(a, b, c, d, x[k + 8], S11, 0x698098d8);
    d = gFF(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
    c = gFF(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
    b = gFF(b, c, d, a, x[k + 11], S14, 0x895cd7be);
    a = gFF(a, b, c, d, x[k + 12], S11, 0x6b901122);
    d = gFF(d, a, b, c, x[k + 13], S12, 0xfd987193);
    c = gFF(c, d, a, b, x[k + 14], S13, 0xa679438e);
    b = gFF(b, c, d, a, x[k + 15], S14, 0x49b40821);
    a = gGG(a, b, c, d, x[k + 1], S21, 0xf61e2562);
    d = gGG(d, a, b, c, x[k + 6], S22, 0xc040b340);
    c = gGG(c, d, a, b, x[k + 11], S23, 0x265e5a51);
    b = gGG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
    a = gGG(a, b, c, d, x[k + 5], S21, 0xd62f105d);
    d = gGG(d, a, b, c, x[k + 10], S22, 0x2441453);
    c = gGG(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
    b = gGG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
    a = gGG(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
    d = gGG(d, a, b, c, x[k + 14], S22, 0xc33707d6);
    c = gGG(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
    b = gGG(b, c, d, a, x[k + 8], S24, 0x455a14ed);
    a = gGG(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
    d = gGG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
    c = gGG(c, d, a, b, x[k + 7], S23, 0x676f02d9);
    b = gGG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
    a = gHH(a, b, c, d, x[k + 5], S31, 0xfffa3942);
    d = gHH(d, a, b, c, x[k + 8], S32, 0x8771f681);
    c = gHH(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
    b = gHH(b, c, d, a, x[k + 14], S34, 0xfde5380c);
    a = gHH(a, b, c, d, x[k + 1], S31, 0xa4beea44);
    d = gHH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
    c = gHH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
    b = gHH(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
    a = gHH(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
    d = gHH(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
    c = gHH(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
    b = gHH(b, c, d, a, x[k + 6], S34, 0x4881d05);
    a = gHH(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
    d = gHH(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
    c = gHH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
    b = gHH(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
    a = gII(a, b, c, d, x[k + 0], S41, 0xf4292244);
    d = gII(d, a, b, c, x[k + 7], S42, 0x432aff97);
    c = gII(c, d, a, b, x[k + 14], S43, 0xab9423a7);
    b = gII(b, c, d, a, x[k + 5], S44, 0xfc93a039);
    a = gII(a, b, c, d, x[k + 12], S41, 0x655b59c3);
    d = gII(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
    c = gII(c, d, a, b, x[k + 10], S43, 0xffeff47d);
    b = gII(b, c, d, a, x[k + 1], S44, 0x85845dd1);
    a = gII(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
    d = gII(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
    c = gII(c, d, a, b, x[k + 6], S43, 0xa3014314);
    b = gII(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
    a = gII(a, b, c, d, x[k + 4], S41, 0xf7537e82);
    d = gII(d, a, b, c, x[k + 11], S42, 0xbd3af235);
    c = gII(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
    b = gII(b, c, d, a, x[k + 9], S44, 0xeb86d391);
    a = addUnsigned(a, AA);
    b = addUnsigned(b, BB);
    c = addUnsigned(c, CC);
    d = addUnsigned(d, DD);
  }
  const temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);
  return temp.toLowerCase();
};

export const getIdentificacaoFromSource = (source: string): string => {
  let indexOpen = -1;
  let indexClose = -1;
  let countOpen = 0;
  let countClose = 0;
  for (let i = 0; i < source.length; i++) {
    if (source[i] === '(') {
      countOpen += 1;
      if (countOpen === 1) {
        indexOpen = i;
      }
    } else if (source[i] === ')') {
      countClose += 1;
      if (countClose === countOpen && indexClose < 0) {
        indexClose = i;
      }
    }
  }
  if (indexOpen < 0 || indexClose < 0 || indexClose <= indexOpen) {
    return 'Unknown';
  }
  const aux = source.substring(indexOpen + 1, indexClose).split(';');
  let value = maxBy(aux.length > 2 ? aux.slice(2) : aux, (el) => el.length);
  // rv
  if (value.toLowerCase().trim().substring(0, 2) === 'rv') {
    const indexRv = aux.findIndex((el) => el === value);
    value = aux[indexRv - 1];
  }
  // build
  const indexBuild = value.toLowerCase().indexOf('build');
  if (indexBuild >= 0) {
    return value.substring(0, indexBuild)?.trim();
  }
  // iOS
  const indexCpu = value.toLowerCase().indexOf('cpu ');
  const indexLike = value.toLowerCase().indexOf(' like');
  if (indexCpu >= 0 && indexLike > indexCpu + 4) {
    return value
      .substring(indexCpu + 4, indexLike)
      .split('_')[0]
      ?.trim();
  }
  // x64
  if (value.toLowerCase().trim() === 'x64') {
    return aux[0]?.trim();
  }

  // Mobile
  if (value.toLowerCase().trim() === 'mobile') {
    return aux[0]?.trim();
  }

  return value?.trim();
};

export const geraDispositivoUuid = (dispositivoInfo: any) => {
  const identificacao = getIdentificacaoFromSource(
    dispositivoInfo?.source || '',
  );
  const options = [
    dispositivoInfo?.platform,
    dispositivoInfo?.os,
    dispositivoInfo?.pixelDepth,
    dispositivoInfo?.colorDepth,
    dispositivoInfo?.isAuthoritative,
    dispositivoInfo?.silkAccelerated,
    dispositivoInfo?.isKindleFire,
    dispositivoInfo?.isDesktop,
    dispositivoInfo?.isMobile,
    dispositivoInfo?.isTablet,
    dispositivoInfo?.isWindows,
    dispositivoInfo?.isLinux,
    dispositivoInfo?.isLinux64,
    dispositivoInfo?.isChromeOS,
    dispositivoInfo?.isMac,
    dispositivoInfo?.isiPad,
    dispositivoInfo?.isiPhone,
    dispositivoInfo?.isiPod,
    dispositivoInfo?.isAndroid,
    dispositivoInfo?.isSamsung,
    dispositivoInfo?.isSmartTV,
    dispositivoInfo?.isRaspberry,
    dispositivoInfo?.isBlackberry,
    dispositivoInfo?.isTouchScreen,
    dispositivoInfo?.browser,
    ...(dispositivoInfo?.isMobile ? [dispositivoInfo?.resolution] : []),
    identificacao,
  ];
  const tmpUuid = hashMD5(options.join(':'));
  return [
    tmpUuid.slice(0, 8),
    tmpUuid.slice(8, 12),
    '4' + tmpUuid.slice(12, 15),
    'b' + tmpUuid.slice(15, 18),
    tmpUuid.slice(20),
  ].join('-');
};
