














































































import Vue from 'vue';
import { debounce } from 'lodash';

import requester from '@/requester';

import {
  CriaPedidoItemPecaLojaDto,
  CriaPedidoItemPoLojaDto,
  CriaPedidoLojaDto,
} from '../../../../back/src/lojas/dto/cria-pedido-loja.dto';

import LojasPedidoFormCatalisador from '@/components/lojas/LojasPedidoFormCatalisador.vue';
import LojasPedidoFormFornecedor from '@/components/lojas/LojasPedidoFormFornecedor.vue';

export default Vue.extend({
  name: 'LojasPedidosNovo',
  components: {
    LojasPedidoFormCatalisador,
    LojasPedidoFormFornecedor,
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      observacoes: null,
    },
    showError: false,
    errorMsgs: [],
    pedidoSucesso: false,
  }),
  computed: {
    lojaId() {
      return Number(this.$route?.params?.lojaId || 0);
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.pedidoSucesso) {
      const fornecedor =
        this.$refs?.lojasPedidoFormFornecedor?.getFornecedor() || null;
      const items = this.$refs?.LojasPedidoFormCatalisador?.getItems() || [];
      if (fornecedor || items.length > 0) {
        const confirm = await this.$root.$confirm(
          'Aviso',
          'Tem certeza que deseja sair desta página? Os dados inseridos serão perdidos.',
        );
        if (confirm) {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  },
  methods: {
    submitFormDebounce: debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      try {
        this.showError = false;

        const fornecedor =
          this.$refs?.lojasPedidoFormFornecedor?.getFornecedor() || null;

        // PÓ
        const itemsPo: (CriaPedidoItemPoLojaDto & {
          ativo: boolean;
          margemMinima: number;
        })[] = this.$refs?.lojasPedidoFormCatalisador?.getItemsPo() || [];
        const itemsPoAtivos = itemsPo.filter((itemPo) => itemPo.ativo);

        // PEÇAS
        const itemsPecas: (CriaPedidoItemPecaLojaDto & {
          ativo: boolean;
          margemMinima: number;
        })[] = this.$refs?.lojasPedidoFormCatalisador?.getItemsPecas() || [];
        const itemsPecasAtivos = itemsPecas.filter((item) => item.ativo);

        // VERIFICAÇÕES
        this.errorMsgs = [];
        if (!fornecedor) {
          this.errorMsgs.push('É necessário selecionar um fornecedor.');
        }
        if (itemsPo.length + itemsPecas.length === 0) {
          this.errorMsgs.push(
            'O pedido precisa ter pelo menos um item cadastrado.',
          );
        }
        if (
          itemsPoAtivos.length + itemsPecasAtivos.length <
          itemsPo.length + itemsPecas.length
        ) {
          this.errorMsgs.push(
            'O pedido não pode ser realizado com itens inativos. Ative ou remova os itens inativos.',
          );
        }
        let errorMargem = false;
        itemsPoAtivos.forEach((item) => {
          if (item.margem < item.margemMinima) errorMargem = true;
        });
        itemsPecasAtivos.forEach((item) => {
          if (item.margem < item.margemMinima) errorMargem = true;
        });
        if (errorMargem) {
          this.errorMsgs.push(
            'O pedido não pode ser realizado com margens menores que as permitidas. Altere as margens definidas',
          );
        }
        if (this.errorMsgs.length > 0) {
          this.showError = true;
          return;
        }
        const confirm = await this.$root.$confirm(
          'Confirmar pedido',
          'Tem certeza que deseja confirmar este pedido?',
        );
        if (!confirm) {
          return;
        }
        // END VERIFICAÇÕES

        this.loading.submitForm = true;
        const formData: CriaPedidoLojaDto = {
          fornecedorId: fornecedor?.id || null,
          observacoes: this.form?.observacoes?.trim() || null,
          itemsPo: itemsPoAtivos,
          itemsPecas: itemsPecasAtivos,
        };
        const pedido = await requester.lojas.criaPedido(this.lojaId, formData);
        this.$root.$emit(
          'toastSuccess',
          `Pedido #${pedido.id} cadastrado com sucesso`,
        );
        this.pedidoSucesso = true;
        this.$router.push({
          name: 'LojasPedidosIndex',
          params: { lojaId: this.lojaId },
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
