








































































































































































































import Vue from 'vue';

import {
  rulesLocalNumericPositive,
  rulesNumericPositive,
  rulesRequired,
} from '@/utils/input-rules';
import {
  inputToNumber,
  maskLocalNumber,
  maskNumeric,
} from '@/utils/mask-functions';
import { ItemCalculadoraCatalisadorPo } from '@/utils/helper-types';

import { Familia } from '../../../../back/src/models/familia.model';

export default Vue.extend({
  name: 'LojasCalculadoraForm',
  props: {
    item: {
      type: null || (Object as () => ItemCalculadoraCatalisadorPo),
      default: null,
    },
    familias: {
      type: Array as () => Familia[],
      default: () => [],
    },
  },
  data: () => ({
    loading: {
      submitForm: false,
    },
    form: {
      familiaId: null,
      pesoBruto: null,
      impurezas: null,
      umidade: 1,
      ptPpm: null,
      pdPpm: null,
      rhPpm: null,
    },
    rules: {
      localNumericPositive3: rulesLocalNumericPositive(3),
      numericPositive: rulesNumericPositive,
      required: rulesRequired,
    },
    validForm: true,
    showError: false,
  }),
  computed: {
    confirmarDisabled() {
      return (
        this.pesoSeco <= 0 ||
        !this.form.familiaId ||
        Number(this.form.ptPpm) +
          Number(this.form.pdPpm) +
          Number(this.form.ptPpm) <=
          0
      );
    },
    itemsFamilias() {
      return this.familias.map((el) => ({
        value: el.id,
        text: `${el.codigo} - ${el.descricao}`,
      }));
    },
    pesoLiquido() {
      const pesoBruto =
        inputToNumber(this.form.pesoBruto, 3) > 0
          ? inputToNumber(this.form.pesoBruto, 3)
          : 0;
      const impurezas =
        inputToNumber(this.form.impurezas, 3) > 0
          ? inputToNumber(this.form.impurezas, 3)
          : 0;
      return (pesoBruto - impurezas).toFixed(3);
    },
    colorPesoLiquido() {
      if (this.pesoLiquido > 0) return 'grey lighten-4';
      if (this.pesoLiquido < 0) return 'error lighten-3';
      return 'grey lighten-3';
    },
    classPesoLiquido() {
      if (this.pesoLiquido > 0) return 'text--secondary';
      if (this.pesoLiquido < 0) return 'error--text text--darken-3';
      return 'text--disabled';
    },
    pesoSeco() {
      return (
        Number(this.pesoLiquido) *
        (1 - Number(this.form.umidade) / 100)
      ).toFixed(3);
    },
    colorPesoSeco() {
      if (this.pesoSeco > 0) return 'info lighten-2';
      if (this.pesoSeco < 0) return 'error lighten-3';
      return 'grey lighten-3';
    },
    classPesoSeco() {
      if (this.pesoSeco > 0) return 'info--text text--darken-3';
      if (this.pesoSeco < 0) return 'error--text text--darken-3';
      return 'text--disabled';
    },
    pesoInvalido() {
      return this.pesoLiquido < 0 || this.pesoSeco < 0;
    },
    pesoCalculo() {
      return Number(this.pesoSeco) > 0 ? Number(this.pesoSeco) : 0;
    },
  },
  mounted() {
    this.preencheForm();
  },
  methods: {
    maskNumeric,
    maskLocalNumber,
    preencheForm() {
      if (this.familias.length <= 1) {
        this.form.familiaId = this.familias?.[0]?.id || null;
      }
      if (!this.item) return;
      this.form.familiaId = this.item.familiaId;
      this.form.pesoBruto = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 3,
      }).format(this.item.pesoBruto);
      this.form.impurezas = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 3,
      }).format(this.item.impurezas);
      this.form.umidade = this.item.umidade * 100;
      this.form.ptPpm = this.item.ptPpm;
      this.form.pdPpm = this.item.pdPpm;
      this.form.rhPpm = this.item.rhPpm;
    },
    limpaForm() {
      this.showError = false;
      this.$refs?.form?.reset();
      this.form.umidade = 1;
    },
    cancelar() {
      this.$emit('cancelar');
      this.limpaForm();
    },
    confirmar() {
      const item: ItemCalculadoraCatalisadorPo = {
        familiaId: this.form.familiaId,
        pesoBruto: inputToNumber(this.form.pesoBruto, 3),
        impurezas: inputToNumber(this.form.impurezas, 3),
        umidade: Number(this.form.umidade) / 100,
        ptPpm: Number(this.form.ptPpm),
        pdPpm: Number(this.form.pdPpm),
        rhPpm: Number(this.form.rhPpm),
      };
      this.$emit('confirmar', item);
      this.limpaForm();
    },
  },
});
