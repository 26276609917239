



















import Vue from 'vue';
import { Fornecedor } from '../../../../back/src/models/fornecedor.model';
import { ViewFornecedorResumo } from '../../../../back/src/models/views/view-fornecedor-resumo.model';

import CustomDialog from '../CustomDialog.vue';
import FornecedoresTablePaginated from './FornecedoresTablePaginated.vue';

export default Vue.extend({
  name: 'FornecedoresDialogBuscar',
  components: {
    CustomDialog,
    FornecedoresTablePaginated,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lojaId: {
      type: Number || null,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    onInput(event: boolean) {
      this.$emit('input', event);
    },
    onFornecedorCriado(fornecedor: Fornecedor) {
      this.$emit('fornecedor-selecionado', fornecedor.id);
    },
    onFornecedorSelecionado(fornecedor: ViewFornecedorResumo) {
      this.$emit('fornecedor-selecionado', fornecedor.id);
    },
  },
});
