var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FornecedoresDialogInfo',{attrs:{"lojaId":_vm.lojaId,"fornecedorId":_vm.fornecedorInfoId},on:{"fornecedor-alterado":function($event){return _vm.onFornecedorAlterado()},"fornecedor-removido":function($event){return _vm.onFornecedorRemovido()}},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),_c('FornecedoresDialogCriar',{attrs:{"lojaId":_vm.lojaId},on:{"fornecedor-criado":function($event){return _vm.onFornecedorCriado()}},model:{value:(_vm.dialogCriar),callback:function ($$v) {_vm.dialogCriar=$$v},expression:"dialogCriar"}}),_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":_vm.$t('comum.busca'),"append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.permissaoCriar)?_c('v-btn',{staticClass:"mb-2 ml-sm-2",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":"success","depressed":""},on:{"click":function($event){_vm.dialogCriar = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.cadastrarFornecedor')))])],1):_vm._e()],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.fornecedores,"headers":_vm.headers,"options":_vm.options,"loading":_vm.loading.fornecedores,"server-items-length":_vm.serverItemsLength,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [(item.nome)?_c('span',[_vm._v(_vm._s(item.nome))]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.documento",fn:function(ref){
var item = ref.item;
return [(item.documento)?_c('span',[_vm._v(_vm._s(item.documento))]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('span',[_vm._v(_vm._s(item.email))]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.celular",fn:function(ref){
var item = ref.item;
return [(item.celular)?_c('div',[_vm._v(_vm._s(_vm._f("formatPhone")(item.celular || '')))]):_vm._e(),(item.telefone)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatPhone")(item.telefone || ''))+" ")]):_vm._e(),(!item.celular && !item.telefone)?_c('span',{staticClass:"text--disabled"},[_vm._v(" N/A ")]):_vm._e()]}},{key:"item.enderecoMunicipioNome",fn:function(ref){
var item = ref.item;
return [(item.enderecoMunicipioNome)?_c('span',[_vm._v(" "+_vm._s(item.enderecoMunicipioNome)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.enderecoUfSigla",fn:function(ref){
var item = ref.item;
return [(item.enderecoUfSigla)?_c('span',[_vm._v(" "+_vm._s(item.enderecoUfSigla)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.selecionar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.selecionaFornecedor(item)}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")])],1)]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.infoFornecedor(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }