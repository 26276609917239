



















































































































































































































































































































































import requester from '@/requester';
import {
  rulesLocalNumericPositive,
  rulesNumericPositive,
  rulesRequired,
} from '@/utils/input-rules';
import { maximumFractionDigitsCurrency } from '@/utils/intl-functions';
import {
  inputToNumber,
  maskLocalNumber,
  maskNumeric,
} from '@/utils/mask-functions';
import { getPaisCurrency, getPaisLocale } from '@/utils/pais-helper';
import { debounce } from 'lodash';
import Vue from 'vue';
export default Vue.extend({
  name: 'CalculadoraCatalisadorPo',
  data: () => ({
    loading: {
      submitForm: false,
    },
    umidade: 1,
    form: {
      peso: null,
      pesoSimples: null,
      pesoBruto: null,
      impurezas: null,
      ptPpm: null,
      pdPpm: null,
      rhPpm: null,
    },
    rules: {
      localNumericPositive0: rulesLocalNumericPositive(0),
      localNumericPositive3: rulesLocalNumericPositive(3),
      numericPositive: rulesNumericPositive,
      required: rulesRequired,
    },
    valorKg: 0,
    modoAvancado: false,
    modoEdicao: true,
    validForm: true,
    showError: false,
  }),
  computed: {
    locale() {
      return getPaisLocale();
    },
    currency() {
      return getPaisCurrency();
    },
    pesoLiquido() {
      const pesoBruto =
        inputToNumber(this.form.pesoBruto, 3) > 0
          ? inputToNumber(this.form.pesoBruto, 3)
          : 0;
      const impurezas =
        inputToNumber(this.form.impurezas, 3) > 0
          ? inputToNumber(this.form.impurezas, 3)
          : 0;
      return (pesoBruto - impurezas).toFixed(3);
    },
    colorPesoLiquido() {
      if (this.pesoLiquido > 0) return 'grey lighten-4';
      if (this.pesoLiquido < 0) return 'error lighten-3';
      return 'grey lighten-3';
    },
    classPesoLiquido() {
      if (this.pesoLiquido > 0) return 'text--secondary';
      if (this.pesoLiquido < 0) return 'error--text text--darken-3';
      return 'text--disabled';
    },
    pesoSeco() {
      return (Number(this.pesoLiquido) * (1 - this.umidade / 100)).toFixed(3);
    },
    colorPesoSeco() {
      if (this.pesoSeco > 0) return 'success lighten-3';
      if (this.pesoSeco < 0) return 'error lighten-3';
      return 'grey lighten-3';
    },
    classPesoSeco() {
      if (this.pesoSeco > 0) return 'success--text text--darken-3';
      if (this.pesoSeco < 0) return 'error--text text--darken-3';
      return 'text--disabled';
    },
    pesoInvalido() {
      if (!this.modoAvancado) {
        return inputToNumber(this.form.peso, 3) < 0;
      }
      return this.pesoLiquido < 0 || this.pesoSeco < 0;
    },
    pesoCalculo() {
      if (!this.modoAvancado) {
        return inputToNumber(this.form.peso, 3) > 0
          ? inputToNumber(this.form.peso, 3)
          : 0;
      }
      return Number(this.pesoSeco) > 0 ? Number(this.pesoSeco) : 0;
    },
    valorTotal() {
      return this.valorKg * this.pesoCalculo;
    },
  },
  methods: {
    maskNumeric,
    maskLocalNumber,
    maximumFractionDigitsCurrency,
    limpaForm() {
      this.modoEdicao = true;
      this.showError = false;
      this.$refs?.form?.reset();
    },
    submitFormDebounce: debounce(function () {
      this.submitForm();
    }, 200),
    async submitForm() {
      this.showError = true;
      if (!this.$refs?.form?.validate() || this.pesoInvalido) {
        return;
      }
      this.loading.submitForm = true;
      try {
        const formData = {
          ptPpm: Number(this.form.ptPpm) > 0 ? Number(this.form.ptPpm) : 0,
          pdPpm: Number(this.form.pdPpm) > 0 ? Number(this.form.pdPpm) : 0,
          rhPpm: Number(this.form.rhPpm) > 0 ? Number(this.form.rhPpm) : 0,
        };
        const resData = await requester.catalisadoresPo.minhaCalculadora(
          formData,
        );
        this.valorKg = resData?.valorKg || 0;
        this.modoEdicao = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
